import React from 'react';
import { styled } from '@mui/material/styles';
import { FormControlLabel, Typography, Grid } from '@mui/material';
import InfoToolTip from './InfoToolTip';
import { useTranslation } from 'react-i18next';

const PREFIX = 'ServiceFormControlLabel';

const classes = {
  outerBox: `${PREFIX}-outerBox`,
  checked: `${PREFIX}-checked`,
  addOnOuterBox: `${PREFIX}-addOnOuterBox`,
  addOnOuterBoxChecked: `${PREFIX}-addOnOuterBoxChecked`,
  subLabel: `${PREFIX}-subLabel`,
  addOnsubLabel: `${PREFIX}-addOnsubLabel`,
  control: `${PREFIX}-control`,
  addOnControl: `${PREFIX}-addOnControl`,
  iconPosition: `${PREFIX}-iconPosition`,
  checkedAndDisabled: `${PREFIX}-checkedAndDisabled`,
  text: `${PREFIX}-text`,
  disabled: `${PREFIX}-disabled`,
};

const StyledInfoToolTip = styled(InfoToolTip)(({ theme }) => ({
  [`& .${classes.outerBox}`]: {
    padding: theme.spacing(1, 3),
    minHeight: theme.spacing(5),
    textTransform: 'capitalize',
    fontWeight: '700',
    fontFamily: theme.typography.fontFamily,
    borderRadius: theme.shape.borderRadiusMedium,
    backgroundColor: theme.palette.grey[100],
    margin: theme.spacing(1, 1),
    border: '2px solid rgba(199, 199, 201, 1)', //so that it has same size as checked
  },

  [`& .${classes.checked}`]: {
    border: '2px solid',
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.background.dark,
  },

  [`& .${classes.addOnOuterBox}`]: {
    padding: theme.spacing(0.2, 3),
    height: theme.spacing(6),
  },

  [`& .${classes.addOnOuterBoxChecked}`]: {
    height: '200px',
    padding: theme.spacing(1, 3),

    [theme.breakpoints.only('md')]: {
      height: theme.spacing(30),
    },
    [theme.breakpoints.down('md')]: {
      height: theme.spacing(42),
    },
  },

  [`& .${classes.subLabel}`]: {
    alignSelf: 'flex-end',
    margin: 'auto',
    marginRight: '8px',
  },

  [`& .${classes.addOnsubLabel}`]: {
    alignSelf: 'flex-end',
    margin: 'auto',
    marginTop: theme.spacing(1.3),
    marginRight: '8px',
  },

  [`& .${classes.control}`]: {
    position: 'relative',
    zIndex: 1,
    padding: '0 200px 0 0',
    margin: '0px -7em 0 -1em',
  },

  [`& .${classes.addOnControl}`]: {
    position: 'relative',
    zIndex: 1,
    padding: '0 150px 0 0',
    margin: '0px -7em 0 -1em',
  },

  [`& .${classes.iconPosition}`]: {
    padding: theme.spacing(1.2, 0),
  },

  [`& .${classes.checkedAndDisabled}`]: {
    background: '#ECF1FE35',
    borderColor: '#3956CC35',
  },

  [`& .${classes.text}`]: {
    color: '#ABABAB',
    fontSize: '12pt',
  },

  [`& .${classes.disabled}`]: {
    padding: theme.spacing(1, 3),
    minHeight: theme.spacing(5),
    textTransform: 'capitalize',
    fontWeight: '700',
    fontFamily: theme.typography.fontFamily,
    borderRadius: theme.shape.borderRadiusMedium,
    backgroundColor: theme.palette.grey[100],
    margin: theme.spacing(1, 1),
    border: '2px solid rgba(199, 199, 201, 1)', //so that it has same size as checked
    opacity: 0.35,
  },
}));

const ServiceFormControlLabel = ({
  logo,
  checked,
  control,
  addOn,
  disabled,
  label,
  subLabel,
  toolTipTitle,
  toolTipContent,
  toolTipPlacement = 'top',
}) => {
  const { t } = useTranslation();

  const isChecked = () => {
    // if (checked && !addOn && disabled)
    // return classes.checked
    if (checked && !addOn && !disabled) return classes.checked;
    else if (checked && addOn && !disabled) return classes.addOnOuterBoxChecked + ' ' + classes.checked;
    else if (checked && !addOn && disabled) return classes.checked + ' ' + classes.checkedAndDisabled;
    else if (checked && addOn && disabled)
      return classes.addOnOuterBoxChecked + ' ' + classes.checked + ' ' + classes.checkedAndDisabled;
  };

  return (
    <StyledInfoToolTip title={toolTipTitle} content={toolTipContent} placement={toolTipPlacement}>
      <Grid
        container
        className={disabled ? classes.disabled + ' ' + isChecked() : classes.outerBox + ' ' + isChecked()}
        direction="column"
        alignItems="center"
      >
        <Grid item container xs={12} direction="row" alignItems="center">
          <Grid item>
            <FormControlLabel
              checked={checked}
              control={control}
              disabled={disabled}
              label={label}
              className={addOn ? classes.addOnControl : classes.control}
            ></FormControlLabel>
          </Grid>
          <Grid item className={addOn ? classes.addOnsubLabel : classes.subLabel}>
            {subLabel ? <Typography variant="caption">{subLabel} </Typography> : ''}
          </Grid>
        </Grid>
        <hr
          style={{
            border: '1px solid rgba(199, 199, 201, 1)',
            color: 'rgba(199, 199, 201, 1)',
            backgroundColor: 'rgba(199, 199, 201, 1)',
            height: '2px',
            width: '100%',
          }}
        />
        <Grid container direction="row" justifyContent="flex-end">
          <Typography className={classes.text}>{t('Powered_By')}:</Typography>
          {logo}
        </Grid>
      </Grid>
    </StyledInfoToolTip>
  );
};

export default ServiceFormControlLabel;
