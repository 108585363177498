import { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { styled } from '@mui/material/styles';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  RadioGroup,
  Radio,
  MenuItem,
  Select,
  TextField,
  Grid,
  Box,
  Typography,
  IconButton,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ArrowDropDown } from '@mui/icons-material';
import PropTypes from 'prop-types';

import InfoToolTip from '../../../../components/layout/atoms/InfoToolTip';
import SmallButton from '../../../../components/layout/atoms/SmallButton';
import IconHeading from '../../../../components/layout/molecules/IconHeading';
import NationalIDField from '../../../management/PatientSignUp/components/NationalIDField';
import EmailOrPhoneField from '../../../management/PatientSignUp/components/EmailOrPhoneField';

import { EditIcon, FileTextIcon, UserIcon, UserXIcon, XIcon } from '../../../../resources/icons';

import { EMAIL, PHONE_NUMBER } from '../../../../constants/validations';
import {
  ETHNIC_CODE_ENUMS,
  ETHNIC_CODE_ENUMS_STR,
  GENDER_STR_ENUMS,
  PATIENT,
  GERMANY,
  AutoGenerateEmail,
} from '../../../../constants/constants';

import { setSnackbar } from '../../../../actions/controls';
import {
  getMyDetails,
  getPatientDetails,
  getPatientRiskServices,
  updatePatientDetails,
} from '../../../../actions/patients';
import {
  getDeployRegion,
  getRegionSpecificEthnicity,
  getRegionSpecificSignUpFormFieldConfigs,
} from '../../../../utils/configurationHelpers';
import { stringCapitalize, stripString, testDOB, transferValueGetKey } from '../../../../utils/helpers';
import { datePickerLangText } from '../../../../i18n';
import { fontStyle } from 'styled-system';

const PREFIX = 'ProfileDetails';

const classes = {
  form: `${PREFIX}-form`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.form}`]: {
    margin: theme.spacing(1, 2),
  },
}));

const StyledIconButton = styled(IconButton)(({ theme, autoGenerateEmail = false }) => ({
  backgroundColor: autoGenerateEmail ? theme.palette.background.dark : theme.palette.background.grey,
  border: autoGenerateEmail ? `2px solid ${theme.palette.primary.light}` : `1px solid ${theme.palette.border.dark}`,
  width: '44px',
  height: '40px',
  borderRadius: '4px',
  alignContent: 'center',
  alignItems: 'center',
  color: autoGenerateEmail ? theme.palette.primary.main : theme.palette.text.main,
  '&:hover': {
    color: theme.palette.primary.light,
    backgroundColor: theme.palette.background.default,
    border: '2px solid',
  },
}));

const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  color: theme.palette.grey.dark,
}));

const ProfileDetails = (props) => {
  const initialState = {
    accountNumber:
      (props.user?.role === PATIENT ? props.user?.account_number : props?.patientDetails.user?.account_number) || '',
    email: props.patientDetails.user?.email || '',
    first_name: props.patientDetails.user?.user_contact.first_name || '',
    last_name: props.patientDetails.user?.user_contact.last_name || '',
    postcode: props.patientDetails.user?.user_contact.address.postcode || '',
    phone_number: props.patientDetails?.user?.phone_number || '',
    gender: props.patientDetails?.demographics?.gender || '',
    ethnicity: props.patientDetails?.demographics?.ethnicity || '',
    date_of_birth: props.patientDetails?.demographics?.date_of_birth || '',
    national_id: props.patientDetails?.demographics?.national_id || '',
    metadata: props.patientDetails?.metadata,
  };
  const SignUpFormFieldConfigs = getRegionSpecificSignUpFormFieldConfigs();

  const formatProfileValues = (values, editMode) => {
    // Display '-' as placeholder for empty value for patient profile
    const temp = values;
    for (const key in temp) {
      if ((temp[key] === null || temp[key] === '' || temp[key] === '-') && key !== 'date_of_birth')
        temp[key] = editMode ? '' : '-';

      if (key === 'metadata') {
        for (const prop in temp[key]) {
          if (temp[key][prop] === null || temp[key][prop] === '' || temp[key][prop] === '-') {
            temp[key][prop] = editMode ? '' : '-';
          }
        }
      }
    }
    return temp;
  };

  const staticWords = useSelector((state) => state.handlingTranslation.words);
  const { t, i18n } = useTranslation();
  const [editMode, setEditMode] = useState(false);
  const [autoGenerateEmail, setAutoGenerateEmail] = useState(initialState?.email === '-' ? true : false);
  const [isSaving, setIsSaving] = useState(false);
  const [values, setValues] = useState(formatProfileValues(initialState, editMode));
  const [errors, setErrors] = useState({
    email: false,
    phone_number: false,
    national_id: false,
  });

  const [dateError, setDateError] = useState('');

  const signUpFormFieldConfigs = getRegionSpecificSignUpFormFieldConfigs();
  const ethnicityElements = getRegionSpecificEthnicity();
  const handlingDOBErrors = (err) => {
    if (err && ['invalidDate', 'disableFuture'].includes(err)) {
      setDateError('Invalid_Input'); // Show error if date is invalid
    } else {
      setDateError('');
    }
  };

  const handleEdit = () => {
    setValues(formatProfileValues(values, true));
    setEditMode(true);
  };

  const handleSetError = (fieldName, error) => {
    setErrors({ ...errors, [fieldName]: error });
  };

  const handleReset = () => {
    setEditMode(false);
    setValues(formatProfileValues(initialState));
    setAutoGenerateEmail(initialState?.email === '-' ? true : false);
  };
  const handleProfileChange = (field, event) => {
    let newValue = event.target.value;
    if (field === 'postcode') {
      let output = stripString(
        event.target.value,
        SignUpFormFieldConfigs.postcode.excludedChars,
        SignUpFormFieldConfigs.postcode.maxLength
      );

      newValue = output;
    }
    setValues({ ...values, [field]: newValue });
  };

  const handleMetadataChange = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      metadata: {
        ...values.metadata,
        [name]: value,
      },
    });
  };

  const formatDate = (date) => {
    if (isNaN(date.getDate())) return null;
    let month = date.getMonth() + 1 + '';
    let day = date.getDate() + '';
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    let formatted_date = date.getFullYear() + '-' + month + '-' + day;
    return formatted_date;
  };

  const handleDateChange = (date, target) => {
    setValues({ ...values, [target]: date });
  };

  const filterInputsByFields = (inputs, fields) => {
    let res = {};
    for (const key in inputs) {
      if (fields.includes(key) && (inputs[key] !== null || inputs[key] !== '-') && inputs[key] !== initialState[key]) {
        res[key] = inputs[key];
      }
    }
    return res;
  };

  const filterMetadata = (currentMetadata, updatedMetadata) => {
    if (!Object.keys(currentMetadata)?.length && Object.keys(updatedMetadata)?.length > 0) {
      return { metadata: updatedMetadata };
    }
    const res = Object.keys(currentMetadata).reduce((acc, key) => {
      if (updatedMetadata?.[key] && updatedMetadata[key] !== currentMetadata[key]) {
        acc[key] = updatedMetadata[key];
      }
      return acc;
    }, {});

    return { metadata: res };
  };

  const renderConditionalToolTip = (innerField, title, content) => {
    if (editMode) {
      return (
        <Root>
          <InfoToolTip placement="bottom-start" title={title} content={content}>
            {innerField}
          </InfoToolTip>
        </Root>
      );
    } else {
      return innerField;
    }
  };

  const handleAutoGenerateEmailChange = () => {
    if (!autoGenerateEmail) {
      setValues({ ...values, email: 'null' });
    } else {
      setValues({ ...values, email: props.patientDetails.user?.email || '' });
    }
    setAutoGenerateEmail(!autoGenerateEmail);
  };

  const validateFields = (fields = []) => {
    return !fields.find((field) => errors[field]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateFields(Object.keys(errors))) {
      props.setSnackbar(staticWords.Please_correct_the_errors_above, 'error'); // Show snackbar error
      return;
    }
    const clone = { ...values };
    clone.first_name = stringCapitalize(clone.first_name);
    clone.last_name = stringCapitalize(clone.last_name);
    // If postcode is not empty and is not valid pattern, display error snackbar
    if (
      clone?.postcode &&
      clone.postcode?.length > 0 &&
      !SignUpFormFieldConfigs.postcode.pattern.test(clone.postcode)
    ) {
      return props.setSnackbar(staticWords.Please_enter_a_valid_postcode_, 'error');
    }
    if (getDeployRegion() === GERMANY && !props.hidePatientDetails) {
      if (clone?.national_id) {
        clone.national_id = !!clone.national_id ? clone.national_id.toUpperCase() : clone.national_id;
      }
      if (!clone?.metadata?.insurance_type) {
        props.setSnackbar(staticWords.Please_Select_Patient_Type, 'error');
        return;
      }
    }

    setIsSaving(true);
    let patientDetails = clone;
    //Only format the date if date is being changed
    if (patientDetails.date_of_birth !== initialState?.date_of_birth) {
      patientDetails.date_of_birth = formatDate(patientDetails?.date_of_birth);
    }
    if (patientDetails.gender === null || patientDetails.gender === '-') {
      patientDetails.gender = 0;
    }
    if (patientDetails.ethnicity === null || patientDetails.ethnicity === '-') {
      patientDetails.ethnicity = 0;
    }
    if (patientDetails.phone_number === null || patientDetails.phone_number === '-') {
      patientDetails.phone_number = '';
    }
    if (!testDOB(patientDetails.date_of_birth)) {
      setIsSaving(false);
      setDateError('Invalid_Input'); // Show error if date is invalid
      return props.setSnackbar(staticWords.Please_correct_the_errors_above, 'error');
    }

    try {
      await props.updatePatientDetails(
        props.patientDetails.user.user_contact.id,
        props.patientDetails.id,
        filterInputsByFields(patientDetails, ['first_name', 'last_name', 'postcode']),
        filterInputsByFields(patientDetails, ['gender', 'ethnicity', 'date_of_birth', 'national_id']),
        filterInputsByFields(patientDetails, ['email', 'phone_number']),
        filterMetadata(initialState?.metadata, patientDetails?.metadata),
        props.selfUpdated
      );
      setEditMode(false);
      setIsSaving(false);
      setValues(formatProfileValues(clone, false));

      //Retrieve the newly updated fields
      if (props.user.role === PATIENT) {
        props.getMyDetails(props.user.role);
      } else {
        props.getPatientDetails(initialState.accountNumber);
      }
    } catch (e) {
      setIsSaving(false);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Box>
      <form autoComplete="off">
        <div className={classes.form}>
          <Grid container alignItems="center" justifyContent="space-between" mb={2}>
            <Grid item>
              <IconHeading title={props.title} icon={<UserIcon />} />
            </Grid>
            <Grid>
              {props.disallowEdit ? null : editMode ? (
                <Grid container item display="flex" gap={1}>
                  <Grid item>
                    <SmallButton startIcon={<FileTextIcon />} showLoading={isSaving} onClick={handleSubmit}>
                      {staticWords.Save}
                    </SmallButton>
                  </Grid>
                  <Grid item>
                    <SmallButton startIcon={<XIcon />} disabled={isSaving} onClick={handleReset}>
                      {staticWords.Cancel}
                    </SmallButton>
                  </Grid>
                </Grid>
              ) : (
                <Grid item>
                  <SmallButton startIcon={<EditIcon />} onClick={handleEdit} showLoading={isSaving}>
                    {staticWords.Edit_Data}
                  </SmallButton>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={props.disallowEdit ? 2 : 3} justifyContent="center">
            <Grid item xs={getDeployRegion() === GERMANY && !props.hidePatientDetails ? 12 : 6}>
              {renderConditionalToolTip(
                <TextField
                  type="accountNumber"
                  label={staticWords.Account_Number}
                  id="patient-account-number"
                  name="accountNumber"
                  value={values.accountNumber}
                  disabled
                  fullWidth
                  InputProps={{ disableUnderline: props.disallowEdit && true }}
                />,
                staticWords.Not_available,
                staticWords.Patient_s_account_number_is_unique_and_not_available_for_modification_
              )}
            </Grid>
            {getDeployRegion() === GERMANY && !props.hidePatientDetails && (
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <StyledInputLabel id="select-insurance-type-label">{staticWords.Type}</StyledInputLabel>
                  <Select
                    defaultValue=""
                    labelId="select-insurance-type-label"
                    name="insurance_type"
                    id="insurance-type"
                    value={values?.metadata?.insurance_type ? values?.metadata?.insurance_type : editMode ? '-' : ''}
                    disabled={!editMode || isSaving}
                    disableUnderline={props.disallowEdit}
                    IconComponent={() => (props.disallowEdit ? null : <ArrowDropDown />)}
                    renderValue={(value) => {
                      if (value === '-') {
                        return '-';
                      }

                      return staticWords?.[value];
                    }}
                    onChange={(e) => handleMetadataChange(e)}
                  >
                    <MenuItem value="-" disabled style={{ display: 'None' }} />
                    <MenuItem key="public" value="public">
                      {staticWords.Public}
                    </MenuItem>
                    <MenuItem key="private" value="private">
                      {staticWords.Private}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            )}
            {!props.hidePatientDetails && (
              <Grid item mb={props.disallowEdit && -2} xs={6}>
                <NationalIDField
                  label={staticWords[transferValueGetKey(signUpFormFieldConfigs.nationalID.label)]}
                  name="national_id"
                  country={props.patientDetails?.user?.country}
                  initialValue={values.national_id}
                  pattern={signUpFormFieldConfigs.nationalID.pattern}
                  disabled={!editMode || isSaving || props.selfUpdated || signUpFormFieldConfigs.nationalID.allowEdit}
                  InputProps={{ disableUnderline: !!props.disallowEdit }}
                  fullWidth
                  helperText={editMode ? signUpFormFieldConfigs.nationalID.helperText : props.disallowEdit ? null : ' '}
                  maxLength={signUpFormFieldConfigs.nationalID.maxLength}
                  excludedChars={signUpFormFieldConfigs.nationalID.excludedChars}
                  onError={(error) => handleSetError('national_id', error)}
                  onChange={(event) => handleProfileChange('national_id', event)}
                  resetValues={editMode}
                />
              </Grid>
            )}
            {!props.hidePatientDetails && (
              <Grid item xs={6}>
                <TextField
                  label={staticWords.First_Name}
                  name="first_name"
                  id="patient-first-name"
                  value={values.first_name}
                  disabled={!editMode || isSaving}
                  InputProps={{ disableUnderline: props.disallowEdit && true }}
                  fullWidth
                  onChange={(event) => handleProfileChange('first_name', event)}
                />
              </Grid>
            )}
            {!props.hidePatientDetails && (
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label={staticWords.Last_Name}
                  name="last_name"
                  id="patient-last-name"
                  value={values.last_name}
                  disabled={!editMode || isSaving}
                  InputProps={{ disableUnderline: props.disallowEdit && true }}
                  onChange={(event) => handleProfileChange('last_name', event)}
                />
              </Grid>
            )}
            {!props.hidePatientDetails &&
              (AutoGenerateEmail ? (
                <Grid item xs={6}>
                  {!editMode ? (
                    <EmailOrPhoneField
                      type={EMAIL}
                      name="email"
                      initialValue={
                        !autoGenerateEmail
                          ? values.email === '-'
                            ? ''
                            : values.email ?? '-'
                          : t('LB_no_email_provided')
                      }
                      showValidationError
                      fullWidth
                      disabled
                      sx={autoGenerateEmail ? { fontStyle: 'italic' } : {}}
                      autoComplete="off"
                      InputProps={{ disableUnderline: !!props.disallowEdit }}
                      onError={(e) => handleSetError('email', e)}
                      onChange={(e) => handleProfileChange('email', e)}
                    />
                  ) : (
                    <Grid container alignItems="flex-end" spacing={1} xs={12}>
                      <Grid item xs={10}>
                        <EmailOrPhoneField
                          type={EMAIL}
                          name="email"
                          initialValue={
                            !autoGenerateEmail
                              ? values.email === '-'
                                ? ''
                                : values.email ?? '-'
                              : t('LB_no_email_provided')
                          }
                          onChange={(e) => handleProfileChange('email', e)}
                          onError={(error) => handleSetError('email', error)}
                          sx={autoGenerateEmail ? { fontStyle: 'italic' } : {}}
                          showValidationError
                          fullWidth
                          autoComplete="off"
                          disabled={autoGenerateEmail || isSaving}
                          InputProps={{ disableUnderline: !!props.disallowEdit }}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <StyledIconButton
                          autoGenerateEmail={autoGenerateEmail}
                          aria-label="autoGenerateEmail"
                          onClick={() => handleAutoGenerateEmailChange()}
                        >
                          <UserXIcon width={18} height={18} />
                        </StyledIconButton>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              ) : (
                <Grid item xs={6}>
                  <EmailOrPhoneField
                    type={EMAIL}
                    name="email"
                    initialValue={values.email}
                    showValidationError
                    fullWidth
                    autoComplete="off"
                    disabled={!editMode || isSaving || props.selfUpdated}
                    InputProps={{ disableUnderline: !!props.disallowEdit }}
                    onError={(e) => handleSetError('email', e)}
                    onChange={(e) => handleProfileChange('email', e)}
                  />
                </Grid>
              ))}
            {!props.hidePatientDetails && (
              <Grid item xs={6}>
                <EmailOrPhoneField
                  defaultCountry={props.user.country}
                  type={PHONE_NUMBER}
                  name="phone_number"
                  initialValue={values.phone_number}
                  showValidationError
                  fullWidth
                  autoComplete="off"
                  disabled={!editMode || isSaving || props.selfUpdated}
                  InputProps={{ disableUnderline: !!props.disallowEdit }}
                  onError={(e) => handleSetError('phone_number', e)}
                  onChange={(e) => handleProfileChange('phone_number', e)}
                />
              </Grid>
            )}
            {/* date_of_birth */}
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={datePickerLangText(i18n.language)}>
                <DesktopDatePicker
                  renderInput={(textFieldProps) => {
                    textFieldProps.inputProps.value = props.disallowEdit
                      ? textFieldProps.inputProps.value || '-'
                      : textFieldProps.inputProps.value;

                    return (
                      <TextField
                        {...textFieldProps}
                        sx={{ width: '100%' }}
                        error={!!dateError}
                        helperText={t(dateError) || ''}
                      />
                    );
                  }}
                  OpenPickerButtonProps={{
                    style: { display: props.disallowEdit && 'none' },
                  }}
                  label={staticWords.Date_of_Birth}
                  name="date_of_birth"
                  id="patient-date-of-birth"
                  value={values.date_of_birth}
                  disableFuture
                  onChange={(date) => {
                    if (!date || isNaN(date.getTime()) || date > new Date()) {
                      setDateError('Invalid_Input'); // Show error if date is invalid
                    } else {
                      setDateError(''); // Clear error when valid
                    }
                    handleDateChange(date, 'date_of_birth');
                  }}
                  onError={(error) => handlingDOBErrors(error)}
                  disabled={!editMode || isSaving}
                  InputProps={{ disableUnderline: props.disallowEdit && true }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  inputFormat="dd-MM-yyyy"
                  autoOk={true}
                  fullWidth
                />
              </LocalizationProvider>
            </Grid>
            {!props.hidePatientDetails && (
              <Grid item xs={6}>
                <TextField
                  // sx={{
                  //   '& .MuiFormHelperText-root': { color: handlingPostCodeErr(values.postcode)?.helperTextColor }, // Change helper text color
                  // }}
                  type="text"
                  label={staticWords.Post_Code}
                  name="postcode"
                  id="patient-postcode"
                  value={values.postcode}
                  onChange={(event) => handleProfileChange('postcode', event)}
                  disabled={!editMode || isSaving}
                  InputProps={{ disableUnderline: props.disallowEdit && true }}
                  inputProps={{
                    maxLength: signUpFormFieldConfigs.postcode.maxLength,
                    style: { textTransform: 'uppercase' },
                  }}
                  helperText={editMode ? signUpFormFieldConfigs.postcode.helperText : props.disallowEdit ? null : ' '}
                  fullWidth
                />
              </Grid>
            )}
            <Grid item mt={!props.disallowEdit && -1} xs={6}>
              <FormControl fullWidth>
                <StyledInputLabel id="select-gender-label">{staticWords.Biological_Sex}</StyledInputLabel>
                <Select
                  defaultValue=""
                  labelId="select-gender-label"
                  name="gender"
                  id="patient-gender"
                  value={values.gender}
                  disabled={!editMode || isSaving || props.disallowEdit}
                  disableUnderline={props.disallowEdit}
                  IconComponent={() => (props.disallowEdit ? null : <ArrowDropDown />)}
                  renderValue={(value) => {
                    return staticWords[transferValueGetKey(GENDER_STR_ENUMS[value])] || '-';
                  }}
                  onChange={(e) => handleProfileChange('gender', e)}
                >
                  <MenuItem value={null}>-</MenuItem>
                  {Object.entries(GENDER_STR_ENUMS).map(([key, gender]) => (
                    <MenuItem key={key} value={key}>
                      {staticWords[transferValueGetKey(gender)]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* ethnicity */}
            <Grid item mt={!props.disallowEdit && -1} xs={6}>
              <FormControl fullWidth>
                <StyledInputLabel id="select-ethnicity-label">{staticWords.Ethnicity}</StyledInputLabel>
                <Select
                  defaultValue=""
                  labelId="select-ethnicity-label"
                  name="ethnicity"
                  id="patient-ethnicity"
                  value={values.ethnicity}
                  onChange={(event) => handleProfileChange('ethnicity', event)}
                  disabled={!editMode || isSaving || props.disallowEdit}
                  disableUnderline={props.disallowEdit}
                  IconComponent={() => (props.disallowEdit ? null : <ArrowDropDown />)}
                  renderValue={(value) => {
                    return staticWords[transferValueGetKey(ethnicityElements[ETHNIC_CODE_ENUMS_STR[value]])] || '-';
                  }}
                >
                  <MenuItem value={null}>-</MenuItem>
                  {Object.keys(ethnicityElements || {}).map((ethnicity) => {
                    return (
                      <MenuItem value={ETHNIC_CODE_ENUMS[ethnicity]} key={ethnicity}>
                        {staticWords[transferValueGetKey(ethnicityElements[ethnicity])]}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </div>
      </form>
    </Box>
  );
};

ProfileDetails.propTypes = {
  updatePatientDetails: PropTypes.func.isRequired,
  setSnackbar: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  patientDetails: state.patients.patientDetails,
});

export default connect(mapStateToProps, {
  updatePatientDetails,
  getPatientDetails,
  getPatientRiskServices,
  getMyDetails,
  setSnackbar,
})(ProfileDetails);
