export const dynamicWords = {
  en: {
    // ----------------------------( 21-01-2025 ) New Words ( component: HealthDataTable.js )-------------------------------------//

    Diet: 'Diet',
    Insulin: 'Insulin',
    Medication: 'Medication',
    Diet_Insulin_Medication: 'Diet, Insulin, Medication',
    No_light_perception__NLP_: 'No light perception (NLP)',
    Light_perception__LP_: 'Light perception (LP)',
    Hand_movements__HM_: 'Hand movements (HM)',
    Counting_fingers_at_1_meters__CF_: 'Counting fingers at 1 meters (CF)',
    None: 'None',
    account_message:
      "Below is your <1>account number</1>. Treat it as your identification number when going for any eye screening! You don't need to remember it, as it is also sent to your email.",
    patient_account_message:
      "This is the patient's <1>account number</1>. It will be their identification number when going for any eye screening. You don't need to remember it, as they will be listed in your organisation's patients list.",
  },
  de: {
    // ----------------------------( 21-01-2025 ) New Words ( component: HealthDataTable.js )-------------------------------------//
    Diet: 'Diät',
    Insulin: 'Insulin',
    Medication: 'Medikation',
    Diet_Insulin_Medication: 'Diät, Insulin, Medikation',
    No_light_perception__NLP_: 'Keine Lichtwahrnehmung (NLP)',
    Light_perception__LP_: 'Lichtwahrnehmung (LP)',
    Hand_movements__HM_: 'Handbewegungen (HM)',
    Counting_fingers_at_1_meters__CF_: 'Zählen der Finger auf 1 Meter (CF)',
    None: 'Keiner',
    account_message:
      'Nachstehend finden Sie Ihre <1>Kontonummer</1>. Betrachten Sie sie als Ihre Identifikationsnummer, wenn Sie zu einer Augenuntersuchung gehen! Sie brauchen sie sich nicht zu merken, denn sie wird auch an Ihre E-Mail geschickt.',
    patient_account_message:
      'Dies ist die <1>Kontonummer</1> des Patienten. Sie ist ihre Identifikationsnummer, wenn sie zu einer Augenuntersuchung gehen. Sie brauchen sich diese Nummer nicht zu merken, da sie in der Patientenliste Ihres Optikers aufgeführt wird.',
  },
};
