import React, { useEffect, useState } from 'react';
import { FormControl, InputAdornment, MenuItem, Select, TextField } from '@mui/material';
import { EMAIL, PHONE_NUMBER, PHONE_REGEX_BY_COUNTRY } from '../../../../constants/validations';
import { styled } from '@mui/system';
import { isEmailOrPhoneNumber, stripString, transferValueGetKey } from '../../../../utils/helpers';
import PropTypes from 'prop-types';
import { getCountryByDialCode, getDialCodeByCountry } from '../../../../utils/configurationHelpers';
import { COUNTRY_CODE_STR_ENUMS } from '../../../../constants/constants';
import { DIAL_CODE_BY_COUNTRY } from '../../../../constants/regionSpecificConfigs';
import logger from '../../../../utils/debugHelpers';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const splitDialCode = (value, availableCountries = [], dialCodeToSplit) => {
  let dialCode = '';
  let number = value;
  if (value.startsWith('+')) {
    if (dialCodeToSplit) {
      number = value.replace(dialCodeToSplit, '');
      if (value.startsWith(dialCodeToSplit)) {
        dialCode = dialCodeToSplit;
      }
    } else {
      Object.entries(DIAL_CODE_BY_COUNTRY).find(([country, code]) => {
        if (availableCountries.includes(country) && value.startsWith(code)) {
          dialCode = code;
          number = value.replace(dialCode, '');
          return true;
        }
      });
    }
  }
  return { dialCode, number };
};

const StyledTextField = styled(TextField, {
  shouldForwardProp(propName) {
    return !['plainDisplay'].includes(propName);
  },
})(({ theme, plainDisplay }) => {
  return {
    '& .MuiInput-underline::before': {
      display: plainDisplay ? 'none' : 'block',
    },
    '& input:-webkit-autofill': {
      WebkitBoxShadow: `0 0 0 1000px ${
        plainDisplay ? theme.palette.background.default : theme.palette.common.white
      } inset`,
    },
  };
});

const EmailOrPhoneField = ({
  defaultCountry = '',
  name = 'phone_or_email',
  initialValue = '',
  onChange,
  onError,
  showValidationError,
  plainDisplay,
  disabled,
  label = '',
  type = 'mixed',
  availableCountries = [defaultCountry],
  InputProps = {},
  ...muiProps
}) => {
  const staticWords = useSelector((state) => state.handlingTranslation.words);
  const [currentCountryCode, setCurrentCountryCode] = useState(defaultCountry);
  const [currentDialCode, setCurrentDialCode] = useState(getDialCodeByCountry(defaultCountry));
  const [value, setValue] = useState('');
  const [error, setError] = useState(false);
  const [showError, setShowError] = useState(false);
  const [helperText, setHelperText] = useState('');
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setCurrentDialCode(getDialCodeByCountry(defaultCountry));
  }, [defaultCountry]);

  // reset value
  useEffect(() => {
    if (value !== initialValue && ['mixed', EMAIL].includes(type) && !/^(\+)?\d+$/.test(initialValue)) {
      setValue(initialValue);
      handleSetError('');
    } else if (/^(\+)?\d+$/.test(initialValue)) {
      const { dialCode, number } = splitDialCode(initialValue, availableCountries);
      if (value !== number) {
        handleSetError('');
        setValue(number);
      }
    } else {
      if (value !== initialValue) {
        handleSetError('');
        setValue(initialValue);
      }
    }
  }, [initialValue]);

  const handleSetError = (errorMsg) => {
    if (!errorMsg) {
      setError(false);
      setHelperText('');
    } else {
      setError(true);
      setHelperText(errorMsg);
    }
  };

  const handleChange = (event) => {
    // strip out all spaces
    let inputValue = event.target.value.replace(/\s/g, '');
    if (type === PHONE_NUMBER) {
      inputValue = event.target.value.replace(/[^+\d]/g, '');
    }
    if (isEmailOrPhoneNumber(inputValue) === PHONE_NUMBER && ['mixed', PHONE_NUMBER].includes(type)) {
      inputValue = splitDialCode(stripString(inputValue, /[ -]/g), availableCountries, currentDialCode).number;
      let phoneNumberWithDialCode = inputValue;
      if (/^\d+$/g.test(inputValue)) {
        phoneNumberWithDialCode = currentDialCode + inputValue;
      }
      if (PHONE_REGEX_BY_COUNTRY[currentCountryCode]?.test(phoneNumberWithDialCode)) {
        handleSetError('');
      } else {
        handleSetError('Invalid Input');
      }
      onChange?.({ target: { name, value: phoneNumberWithDialCode } });
    } else if (isEmailOrPhoneNumber(inputValue) === EMAIL && ['mixed', EMAIL].includes(type)) {
      // If the input value matches the email format, treat it as an email
      setError(false);
      setHelperText('');
      onChange?.({ target: { name, value: inputValue } });
    } else {
      if (inputValue === '') {
        handleSetError('');
      } else {
        // Otherwise, it's an invalid input
        handleSetError('Invalid Input');
      }
      onChange?.({ target: { name, value: inputValue } });
    }
    setValue(inputValue);
  };

  const handleOnBlur = (event) => {
    if (error) {
      setShowError(true);
      onError?.(true);
    } else {
      setShowError(false);
      onError?.(false);
    }
  };

  const handleDialCodeChange = (event) => {
    const dialCode = event.target.value;
    setCurrentCountryCode(getCountryByDialCode(dialCode));
    setCurrentDialCode(dialCode);
    const phoneNumberWithDialCode = dialCode + value;
    onChange?.({ target: { name, value: phoneNumberWithDialCode } });
  };

  const getLabel = () => {
    if (label === '') label = staticWords.Email_or_Mobile_No_;
    if ((isEmailOrPhoneNumber(value) === PHONE_NUMBER && plainDisplay) || type === PHONE_NUMBER) {
      label = staticWords.Mobile_Number;
    } else if ((isEmailOrPhoneNumber(value) === EMAIL && plainDisplay) || type === EMAIL) {
      label = staticWords.Email;
    }
    return label;
  };

  const renderDialCodeSelection = () => {
    if (['mixed', PHONE_NUMBER].includes(type)) {
      const isDisabled = disabled || plainDisplay || availableCountries.length <= 1;
      return (
        <FormControl variant="standard" sx={{ maxWidth: 80 }}>
          <Select
            labelId="dial-code-select-label"
            id="dial-code-select"
            value={currentDialCode}
            onChange={handleDialCodeChange}
            label="dialCode"
            disabled={isDisabled}
            sx={{
              '& .MuiSelect-select:focus': {
                backgroundColor: 'transparent',
              },
              '& .MuiSelect-icon.Mui-disabled': {
                display: isDisabled ? 'none' : 'block',
              },
              '& .MuiSelect-select.MuiSelect-standard.Mui-disabled': {
                paddingRight: isDisabled ? 0 : 24,
              },
            }}
            renderValue={(value) => value}
          >
            {availableCountries.map((countryCode) => {
              const dialCode = getDialCodeByCountry(countryCode);
              return (
                <MenuItem key={countryCode} value={dialCode}>
                  {`${dialCode} ${COUNTRY_CODE_STR_ENUMS[countryCode]}`}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      );
    }
  };

  return (
    <StyledTextField
      label={getLabel()}
      name={name}
      value={value}
      plainDisplay={plainDisplay}
      disabled={plainDisplay || disabled}
      error={showError && showValidationError && error}
      helperText={showError && showValidationError && staticWords[transferValueGetKey(helperText)]}
      InputProps={{
        ...InputProps,
        startAdornment: [PHONE_NUMBER, 'mixed'].includes(type) && value.match(/^\d+$/) && (
          <InputAdornment position="start">{renderDialCodeSelection()}</InputAdornment>
        ),
      }}
      onBlur={handleOnBlur}
      onChange={handleChange}
      {...muiProps}
    />
  );
};

EmailOrPhoneField.propTypes = {
  defaultCountry: PropTypes.string,
  name: PropTypes.string,
  initialValue: PropTypes.string,
  onChange: PropTypes.func,
  onError: PropTypes.func,
  showValidationError: PropTypes.bool,
  plainDisplay: PropTypes.bool,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  type: PropTypes.oneOf([EMAIL, PHONE_NUMBER, 'mixed']),
  InputProps: PropTypes.object,
};

export default EmailOrPhoneField;
