import React from 'react';
import { useSelector } from 'react-redux';

import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

import Loading from '../organisms/Loading/Loading';

const getStyleByType = (theme, buttonType) => {
  switch (buttonType) {
    case 'primary':
      return {
        color: 'white',
      };
    case 'secondary':
      return {
        color: theme.palette.primary.light,
        backgroundColor: theme.palette.background.dark,

        '&:hover': {
          backgroundColor: theme.palette.button.light,
          boxShadow: theme.shadows[0],
        },
      };
    case 'text':
      return {
        color: 'white',

        '&:hover': {
          backgroundColor: 'hsla(120, 100%, 50%, 0)',
          color: theme.palette.primary.main,
          boxShadow: 'none',
        },
      };
    case 'transparent':
      return {
        color: theme.palette.primary.main,
        border: `2px solid ${theme.palette.primary.main}`,
        backgroundColor: 'transparent', // Force transparent background
        '&:hover': {
          backgroundColor: theme.palette.button.light,
          boxShadow: 'none',
        },
      };
    case 'blue':
      return {
        color: theme.palette.text.white,
        backgroundColor: theme.palette.background.blue,
        border: `2px solid ${theme.palette.background.blue}`,
        '&:hover': {
          backgroundColor: theme.palette.background.blue,
          boxShadow: 'none',
        },
      };
    case 'red':
      return {
        color: theme.palette.error.dark,
        backgroundColor: theme.palette.error.light,
        '&:hover': {
          backgroundColor: theme.palette.error.light,
          boxShadow: theme.shadows[0],
        },
      };
    case 'back':
      return {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.background.dark,
        '&:hover': {
          backgroundColor: theme.palette.button.light,
          boxShadow: theme.shadows[0],
        },
      };
    default:
      return {
        color: 'white',
      };
  }
};

const StyledButton = styled(Button, {
  shouldForwardProp(propName) {
    return !['customWidth', 'height', 'buttonType', 'showLoading', 'noMargin'].includes(propName);
  },
})(({ theme, customWidth, height, buttonType, showLoading, noMargin, fullWidth }) => ({
  width: fullWidth ? '100%' : 'auto',
  minWidth: customWidth,
  maxWidth: '100%',
  margin: noMargin ? 0 : theme.spacing(1),
  padding: showLoading ? 0 : theme.spacing(2),
  textTransform: 'capitalize',
  fontWeight: '700',
  boxShadow: theme.shadows[0],
  borderRadius: theme.shape.borderRadiusMedium,
  minHeight: '56.5px',
  maxHeight: height === 'short' ?? '48px',
  ...getStyleByType(theme, buttonType),
}));

const LargeButton = (props) => {
  const { styles } = useSelector((state) => state.handlingTranslation);
  const {
    fullWidth,
    customWidth = styles.component_LargeButton?.width,
    height,
    color,
    showLoading,
    buttonType,
    noMargin,
    style,
    onClick,
    ...otherProps
  } = props;

  return (
    <StyledButton
      {...otherProps}
      buttonType={buttonType}
      fullWidth={fullWidth}
      customWidth={customWidth}
      height={height}
      showLoading={showLoading}
      noMargin={noMargin}
      variant="contained"
      color={color === 'blue' ? 'primary' : 'secondary'}
      endIcon={showLoading ? null : otherProps.endIcon}
      startIcon={showLoading ? null : otherProps.startIcon}
      disabled={showLoading || otherProps.disabled}
      style={style}
      onClick={onClick}
    >
      {showLoading ? <Loading size="sm" /> : otherProps.children}
    </StyledButton>
  );
};

export default LargeButton;
