import { accountPreferences } from './accountPreferences';

export const deMissingTranslation = {
  de: {
    Patient_Type: 'Patiententyp',
    Admin: 'Verwaltung',
    GP: 'GP',
    MARKETING: 'MARKETING',
    Successfully_logged_in_: 'Erfolgreich angemeldet!',
    Processing: 'Verarbeitung',
    Canceled: 'Storniert',
    QA_not_assigned_to_image_: 'Bildzuweisung nicht erfolgt.',
    Thank_you_for_verifying_your: 'Vielen Dank für Ihre Verifizierung',
    Please_enter_your_e_mail_: 'Bitte geben Sie Ihre E-Mail-Adresse ein.',
    Please_enter_a_valid_postcode_: 'Bitte geben Sie eine gültige Postleitzahl ein.',
    Assigned_reviews: 'Zugewiesene Überprüfungen',
    resubmit: 'erneut einreichen',
    Cases_that_must_be_referred_to_a_reviewer_in_a_week_or_earlier__as_recommended_in_the_eye_screening_audit_:
      'Fälle, die innerhalb einer Woche oder früher an einen Prüfer verwiesen werden müssen, wie im Augenscreening-Audit empfohlen.',
    Ref_No_: 'Referenznummer.',
    medicare_reference_NO_: 'medicare-referenznummer.',
    Invalid_Phone_Number: 'Ungültige Telefonnummer',
    PENDING: 'AUSSTEHENDE',
    NOT_ADDED: 'NICHT HINZUGEFÜGT',
    Comment: 'Kommentar',
    Send_Report_Link: 'Link zum Bericht senden',
    Nikon: 'Nikon',
    Topcon: 'Topcon',
    DRS: 'DRS',
    TeleEye_Scan: 'TeleEye Scan',
    Optional: 'Optional',
    Upload_and_select_images_to_be_sent_for_grading__with_several:
      'Hochladen und Auswahl von Bildern, die zur Bewertung gesendet werden sollen, mit mehreren',
    Pending_for_image_upload: 'Ausstehend für Bildupload',
    Images_ready_to_download: 'Bilder zum Download bereit',
    Waiting_for_results_from_services: 'Warten auf Ergebnisse von Diensten',
    Pending_for_eye_professional_review: 'Ausstehend zur Überprüfung durch Augenfachpersonal',
    Report_ready_to_download: 'Bericht zum Download bereit',
    Service_hasn_t_been_selected: 'Der Dienst wurde nicht ausgewählt',
    _Add_on_screenings_will_be_valid_for_3_months__If_you_upgrade_your_subscriptions__your_add_on_screenings_will_be_removed_:
      '*Zusatzscreenings sind 3 Monate gültig. Wenn Sie Ihr Abonnement upgraden, werden Ihre Zusatzscreenings entfernt.',
    Add_free_credits_to_individual_accounts__users_that_are_not_a_part_of_any_organisations__below_:
      'Fügen Sie einzelnen Konten kostenlose Credits hinzu (Benutzer, die keiner Organisation angehören) unten.',
    Please_wait: 'Bitte warten',
    before_trying_again: 'bevor Sie es erneut versuchen',
    Email_last_sent_: 'Zuletzt gesendete E-Mail:',
    SMS_last_sent: 'Zuletzt gesendete SMS:',
    American_Academy_of_Ophthalmology__AAO__Standard: 'Standard der Amerikanischen Akademie für Ophthalmologie (AAO)',
    Findings_Observable_upon_Dilated_Ophthalmoscopy: 'Befunde, beobachtbar bei dilatierter Ophthalmoskopie',
    Severity_Level: 'Schweregrad',
    International_Clinical_Diabetic_Macular_Edema_Disease_Severity_Scale:
      'Internationale klinische Skala der Schwere von diabetischem Makulaödem',
    International_Clinical_Diabetic_Retinopathy_Disease_Severity_Scale:
      'Internationale klinische Skala der Schwere von diabetischer Retinopathie',
    No_abnormalities: 'Keine Auffälligkeiten',
    Microaneurysms_only: 'Nur Mikroaneurysmen',
    More_than_just_microaneurysms_but_less_than_Severe_NPDR:
      'Mehr als nur Mikroaneurysmen, aber weniger als schwere NPDR',
    Any_of_the_following: 'Eines der folgenden Befunde',
    More_than_20_intraretinal_hemorrhages_in_each_of_4_quadrants:
      'Mehr als 20 intraretinale Blutungen in jedem der 4 Quadranten',
    Definite_venous_beading_in_2__quadrants: 'Deutliches venöses Beading in 2+ Quadranten',
    Prominent_IRMA_in_1__quadrant: 'Auffällige IRMA in 1+ Quadrant',
    And_no_signs_of_proliferative_retinopathy: 'Und keine Anzeichen von proliferativer Retinopathie',
    One_or_more_of_the_following: 'Eines oder mehrere der folgenden Befunde',
    No_retinal_thickening_or_hard_exudates_in_posterior_pole:
      'Keine Netzhautverdickung oder harte Exsudate im hinteren Pol',
    Some_retinal_thickening_or_hard_exudates_in_posterior_pole_but_distant_from_the_center_of_the_macula:
      'Einige Netzhautverdickung oder harte Exsudate im hinteren Pol, aber entfernt vom Zentrum der Makula',
    Retinal_thickening_or_hard_exudates_approaching_the_center_of_the_macula_but_not_involving_the_center:
      'Netzhautverdickung oder harte Exsudate nähern sich dem Zentrum der Makula, ohne das Zentrum zu involvieren',
    Retinal_thickening_or_hard_exudates_involving_the_center_of_the_macula:
      'Netzhautverdickung oder harte Exsudate, die das Zentrum der Makula betreffen',
    If_your_account_is_created_by_your_organisation_s_admin__you_are_able_to_log_in_now_Otherwise__you_would_need_to_wait_for_our_verification_and_approval_before_you_can_log_in_An_email_will_be_sent_to_you_once_your_account_has_been_approved:
      'Wenn Ihr Konto von dem Admin Ihrer Organisation erstellt wurde, können Sie sich jetzt einloggen. Andernfalls müssen Sie auf unsere Verifizierung und Genehmigung warten, bevor Sie sich einloggen können. Eine E-Mail wird Ihnen gesendet, sobald Ihr Konto genehmigt wurde.',
    Create_Password: 'Passwort erstellen',
    Diseases_Summary: 'Krankheitszusammenfassung',
    check_OTP_usage_for_: 'Überprüfen Sie die OTP-Nutzung für:',
    You_will_be_joining: 'Sie werden teilnehmen',
    Next_billed_on: 'Nächste Rechnungsstellung am',
    Diabetic__Retinopathy: 'Diabetische Retinopathie',
    Type_or_paste_email_addresses_and_press: 'Geben Sie E-Mail-Adressen ein oder fügen Sie sie ein und drücken Sie',
    ENTER: 'EINGABETASTE',
    has_already_been_added_: 'wurde bereits hinzugefügt.',
    is_not_a_valid_email_address_: 'ist keine gültige E-Mail-Adresse.',
    Comments_for_practitioners_and_reviewers__This_will_not_appear_on_the_patient_s_exam_report:
      'Kommentare für Praktiker und Prüfer. Dies wird nicht im Untersuchungsbericht des Patienten erscheinen',
    Upload_up_to: 'Laden Sie bis zu',
    images: 'Bilder hoch',
    per_eye__for_grading__ensuring_they_meet_the: 'pro Auge) zur Bewertung, stellen Sie sicher, dass sie die',
    one: 'eins',
    two: 'zwei',
    four: 'vier',
    specified_requirements: 'spezifizierte Anforderungen',
    No_image_upload_limit_found_for_the_region: 'Kein Bildupload-Limit für die Region gefunden',
    Please_upload_images_to_continue: 'Bitte laden Sie Bilder hoch, um fortzufahren',
    image: 'Bild',
    per_eye_is_allowed_for_submission: 'pro Auge ist für die Einreichung erlaubt',
    Only_a_maximum_of: 'Nur ein Maximum von',
    You_can_only_upload_a_maximum_of: 'Sie können maximal',
    Manage_Subscriptions: 'Abonnements verwalten',
    You_have_successfully_subscribed_to: 'You have successfully subscribed to',
    You_can_manage_your_subscriptions_here_: 'You can manage your subscriptions here.',
    Only: 'Nur',
    Diabetic_Retinopathy__DR__Severity: 'Diabetische Retinopathie (DR) Schweregrad',
    Diabetic_Retinopathy__DR__: 'Diabetische Retinopathie (DR)',
    Site_Language: 'Sprache',
    By_default__Mild_NPDR_is_Non_Referable__Select_Referable_if_necessary_:
      'Standardmäßig ist „Mild NPDR“ nicht referenzierbar. Wählen Sie bei Bedarf „Referenzierbar“ aus.',
    Prac_: 'Ba',
    Patient: 'Patient',
    OPTOM: 'OPTOM',
    Rev_: 'Übr.',
    Org_Ad: 'Org Ad',
    TMC_Ad: 'TMC Ad',
    Review_Date: 'Überprüfungsdatum',
    Patient_Account_No_: 'Patientenkonto-Nr.',
    Successfully_added_new_data_of: 'Neue Daten von erfolgreich hinzugefügt',
    Successfully_updated_medical_history_: 'Krankengeschichte erfolgreich aktualisiert.',
    No_matching_patients_records_found_: 'Es wurden keine passenden Patientendatensätze gefunden.',
    Month: 'Monat',
    Year: 'Jahr',
    Invoice: 'Rechnung',
    Date: 'Datum',
    Total_Amount: 'Gesamtbetrag',
    Email_is_required: 'E-Mail ist erforderlich',
    Invalid_Email_Address: 'Ungültige E-Mail-Adresse',
    Required: 'Erforderlich',
    Cardholder_Name: 'Name des Karteninhabers',
    Credit: 'Guthaben',
    Bank: 'Bank',
    Try_again: 'Erneut versuchen',
    Qty: 'Menge',
    Exam_Plans: 'Prüfungspläne',
    Billing: 'Abrechnung',
    Glaucoma_and_AMD_indications_are_solely_based_on_independent_Healthcare_Provider_review_:
      'Glaukom- und AMD-Diagnosen beruhen ausschließlich auf der Überprüfung durch unabhängige Gesundheitsdienstleister.',
    Diabetic_Macular_Edema_and_Media_Opacity_indications_are_solely_based_on_independent_Healthcare_Provider_review_:
      'Die Angaben zum diabetischen Makulaödem und zur Medientrübung beruhen ausschließlich auf der Überprüfung durch unabhängige Gesundheitsdienstleister.',
    Clinical_investigational_use_only: 'Nur für klinische Untersuchungen',
    Disclaimer: 'Haftungsausschluss',
    The_clinician_must_review_the_AI_results_below__make_an_independent_clinical_assessment__and_adjust_the_results_accordingly_:
      'Der Arzt muss die unten aufgeführten AI-Ergebnisse überprüfen, eine unabhängige klinische Bewertung vornehmen und die Ergebnisse entsprechend anpassen.',
    The_final_clinical_assessment_was_completed_by_a_clinician_and_is_not_reliant_upon_any_AI_:
      'Die abschließende klinische Bewertung wurde von einem Arzt durchgeführt und beruht nicht auf einer künstlichen Intelligenz.',
    Neovascularization: 'Neovaskularisation',
    Vitreous_preretinal_hemorrhage: 'Glaskörper-/präretinale Blutung',

    ////// Payment gateway texts start /////
    Monthly_Subscription: 'Monatliches Abonnement',
    Prepaid: 'Vorausbezahlt',
    Payment_Success: 'Zahlung erfolgreich',
    Payment_Unsuccessful: 'Zahlung nicht erfolgreich',
    Diabetic_Retinopathy_detection_Nonreferable_Referable_result:
      'Erkennung der diabetischen Retinopathie (nicht überweisbares / überweisbares Ergebnis)',
    Exam_Report_provided_to_patient_by_Health_Care_Professional:
      'Der Untersuchungsbericht wird dem Patienten vom Gesundheitsdienstleister ausgehändigt',
    Diabetic_Retinopathy_Glaucoma_and_AMD_detection_Nonreferable_Referable_result:
      'Erkennung von diabetischer Retinopathie, Glaukom und AMD (nicht überweisbares / überweisbares Ergebnis)',
    Diabetic_Retinopathy_Severity_Grading: 'Einstufung des Schweregrads der diabetischen Retinopathie',
    Facility_for_Eye_Health_Practitioner_to_review_and_verify_results:
      'Möglichkeit für den Augenarzt, die Ergebnisse zu überprüfen und zu verifizieren',
    CHOOSE_AN_AI_PLAN: 'WÄHLEN SIE EINEN KI-PLAN',
    PURCHASE_EXAMS: 'PRÜFUNGEN KAUFEN',
    Onetime_payment_valid_6_months: 'Einmalige Zahlung, gültig 6 Monate',
    End_of_month_billing_cycle: 'Ende des monatlichen Abrechnungszyklus',
    Exams: 'Messungen',
    month: 'monat',
    Please_select_an_AI_Plan_above_before_continuing: 'Bitte wählen Sie oben einen KI-Plan aus, bevor Sie fortfahren.',
    Choose_your_preferred_prepaid_amount: 'Wählen Sie den gewünschten vorausbezahlten Betrag:',
    Choose_your_preferred_monthly_plan: 'Wählen Sie Ihren bevorzugten Monatstarif*:',
    _A_minimum_subscription_period_of_3_months_is_required_for_first_time_purchase:
      '* Für den Erstkauf ist eine Mindestabonnementdauer von 3 Monaten erforderlich.',
    PURCHASE: 'KAUFEN',
    Monthly_Subscription_2_Exams_150month_GST: 'Monatsabonnement* 2 Prüfungen - $150/Monat + GST',
    Monthly_Subscription_2_Exams: 'Monatsabonnement - 2 Prüfungen',
    GST: 'MwSt',
    Total_onetime_payment: 'Insgesamt (einmalige Zahlung)',
    CONTINUE_TO_PAYMENT: 'WEITER ZUR ZAHLUNG',
    UPDATE_SUBSCRIPTION: 'UPDATE-ABONNENMENT',
    OFF: 'AUS',
    Prepaid_2_Exams_4500_GST: 'Vorausbezahlte 2 Prüfungen - $4500 + Mehrwertsteuer',
    Prepaid_2_Exams: 'Vorausbezahlt - 2 Prüfungen',
    Total_per_month: 'Insgesamt (pro Monat)',
    Go_to: 'Gehe zu',
    Your_payment_was_not_successful_This_may_be_due_to_one_of_the_following_reasons:
      'Ihre Zahlung war nicht erfolgreich. Dies kann einen der folgenden Gründe haben:',
    Insufficient_funds: 'Unzureichende Mittel',
    Expired_card: 'Abgelaufene Karte',
    Payment_verification_failure: 'Zahlungsüberprüfung fehlgeschlagen',
    Network_Issues: 'Netzwerk-Probleme',

    TRY_AGAIN: 'WIEDERHOLEN',
    Payment_Successful: 'Zahlung erfolgreich',
    Prepaid_250_Exams_2375_GST: 'Vorausbezahlte 250 Prüfungen - $2375 + Mehrwertsteuer',
    OKAY: 'OKAY',
    UNPAID: 'UNBEZAHLT',
    PAID: 'BEZAHLT',
    OVERDUE: 'ÜBERFÄLLIG',
    CANCELLED: 'ABGEBROCHEN',
    CURRENT_EXAM_PLANS: 'AKTUELLE(R) PRÜFUNGSPLAN(E',
    Manage_Subscription: 'Abonnement verwalten',
    ACTIVE: 'AKTIV',
    Exam_Balance: 'Prüfungsbilanz',
    Purchase_Prepaid: 'Kauf im Voraus bezahlt',
    Total_Exam_Balance: 'Prüfungsbilanz insgesamt',
    No_active_monthly_subscriptions_plans_found: 'Keine aktiven Monatsabonnements gefunden.',
    No_prepaid_plans_found: 'Keine Prepaid-Pläne gefunden.',
    PAYMENT_FAILED: 'ZAHLUNG FEHLGESCHLAGEN',
    DEACTIVATED: 'DEAKTIVIERT',
    Incomplete: 'Unvollständig',
    Well_retry_payment_every_5_days_Please_update_your_billing_information_here:
      '(Wir werden die Zahlung alle 5 Tage wiederholen. Bitte aktualisieren Sie hier Ihre Rechnungsdaten).',
    Your_subscription_is_currently_deactivated_due_to_a_failed_payment:
      'Ihr Abonnement ist derzeit aufgrund einer fehlgeschlagenen Zahlung deaktiviert',
    FREE_TRIAL: 'KOSTENLOSE TESTVERSION',
    //// Payment still need to translate start ////
    Purchase_Summary: 'Zusammenfassung der Käufe',
    NO_PURCHASE_HISTORY: 'KEINE KAUFHISTORIEN',
    You_do_not_have_any_purchase_history_to_display_Once_you_complete_a_transaction_it_will_appear_here:
      'Sie haben keine Kaufhistorie, die Sie anzeigen können. Sobald Sie eine Transaktion abgeschlossen haben, wird sie hier angezeigt.',
    Purchase_Exam_Plans: 'Prüfungspläne kaufen',
    INCOMPLETE: 'UNVOLLSTÄNDIG',
    PAUSED: 'PAUSIERT',
    INCOMPLETE_EXPIRED: 'UNVOLLSTÄNDIG ABGELAUFEN',
    DRAFT: 'ENTWURF',
    This_link_will_remain_active_for_30_minutes__After_that__the_transaction_will_be_canceled__and_a_new_purchase_will_need_to_be_initiated_:
      'Dieser Link bleibt für 30 Minuten aktiv. Danach wird die Transaktion storniert und es muss ein neuer Kauf initiiert werden.',
    Billing_history_is_not_displayed_in_test_mode_: 'Die Abrechnungshistorie wird im Testmodus nicht angezeigt.',
    Download: 'Herunterladen',
    Pay_now: 'Jetzt bezahlen',

    YOUR_AI_PLAN: 'IHR KI-PLAN',
    mth: 'Monat',
    Your_last_transaction_failed__Please_review_and_complete_the_payment:
      'Ihre letzte Transaktion ist fehlgeschlagen. Bitte überprüfen Sie die Zahlung und schließen Sie sie ab',
    __For_help__contact: '. Für Hilfe kontaktieren Sie',
    Okay: 'Okay',
    we_will_retry_payment_every_5_days_: '(Wir werden die Zahlung alle 5 Tage wiederholen)',
    here: 'hier',

    Your_payment_for_Monthly_Subscription_has_been_successful__You_can_view_your_invoices_in:
      'Ihre Zahlung für das Monatsabonnement war erfolgreich. Sie können Ihre Rechnungen einsehen in',
    Plans_are_not_displayed_in_test_mode_: 'Im Testmodus werden die Pläne nicht angezeigt.',
    There_was_a_problem_of_exam_plans__Please_try_again___:
      'Es gab ein Problem mit den Prüfungsplänen. Bitte versuchen Sie es erneut ...',
    Your_subscription_has_been_canceled__please_contact_us_at:
      'Ihr Abonnement wurde gekündigt, bitte kontaktieren Sie uns unter',

    Expires: 'Läuft ab:',
    First_bill_: 'Erste Rechnung:',
    on: 'am ',
    Price_listed_is_in: 'Die Preise sind in ',
    _and_does_not_include_GST_Applicable_taxes_will_be_added:
      ' angegeben und enthalten keine Mehrwertsteuer. Anwendbare Steuern werden hinzugerechnet.',
    Purchased_on_: 'Gekauft am:',
    Next_bill_: 'Nächste Rechnung:',
    Your_current_monthly_subscription_plan_is_active_If_you_would_like_to_make_any_changes_to_your_subscription_please_contact_us_at:
      'Ihr derzeitiger monatlicher Abonnementplan ist aktiv. Wenn Sie Änderungen an Ihrem Abonnement vornehmen möchten, kontaktieren Sie uns bitte unter',
    Your_monthly_subscription_free_trial_is_active_If_you_would_like_to_make_any_changes_to_your_subscription_please_contact_us_at:
      'Ihr kostenloses monatliches Probeabonnement ist aktiv. Wenn Sie Änderungen an Ihrem Abonnement vornehmen möchten, kontaktieren Sie uns bitte unter',
    Trial_ends_: 'Probezeit endet',
    Invoice_No: 'Rechnung Nr.',
    Invoice_Date: 'Datum der Rechnung',
    Exam_Plan: 'Prüfungsplan',
    Amount: 'Betrag',
    Monthly: 'Monatlich',
    Your_subscription_will_be_deactivated_if_it_is_not_paid_before:
      'Ihr Abonnement wird deaktiviert, wenn es nicht vor dem',
    Please_try_again_If_the_problem_persists_contact_us_on:
      'Bitte versuchen Sie es erneut. Sollte das Problem weiterhin bestehen, wenden Sie sich bitte an',
    for_further_assistance: 'um weitere Unterstützung zu erhalten.',
    Your_payment_for_Prepaid_Exams_has_been_successful_You_can_view_your_invoices_in:
      'Ihre Zahlung für Prepaid-Prüfungen war erfolgreich. Sie können Ihre Rechnungen einsehen in',
    About: 'Über',
    DR_Grader_and__GCAM_Instructions_of_Use__IFU__Manuals_: 'DR Grader und +GCAM Gebrauchsanweisungen (IFU):',
    View_IFUs: 'IFUs anzeigen',
    Name_of_Devices: 'Name der Geräte',
    _GCAM: '+GCAM',
    Intended_Purpose: 'Verwendungszweck',
    DRS01: 'DRS01',
    GCS01: 'GCS01',
    DR_Grader_is_a_medical_device: 'DR Grader ist ein Medizinprodukt',
    _GCAM_is_a_medical_device: '+GCAM ist ein Medizinprodukt',
    _01_860007668609_8012__v1_0_1: '(01)860007668609(8012) v1.0.1',
    _01_860007668647_8012__v1_0_1: '(01)860007668647(8012) v1.0.1',
    IFU_is_translated_to_German: 'IFU wird ins Deutsche übersetzt',
    Manufacturer: 'Hersteller',
    Date_of_Manufacturer_: 'Datum des Herstellers:',
    Country_of_Manufacture_: 'Land der Herstellung:',
    The_DR_Grading_software_is_intended:
      'Die DR-Grading-Software ist für das Screening und die Einstufung der diabetischen Retinopathie (DR) bei Patienten/der allgemeinen Bevölkerung anhand von Farbfundus-Netzhautbildern vorgesehen. Der DR Grader ist für die Verwendung durch Gesundheitsdienstleister (HCP) zur automatischen Erkennung und Einstufung der diabetischen Retinopathie bei Patienten, bei denen Diabetes diagnostiziert wurde, und zur Untersuchung von Patienten, bei denen noch kein Diabetes diagnostiziert wurde, vorgesehen.',
    _GCAM_is_software_as_a_medical_device:
      'Bei GCAM handelt es sich um eine Software als Medizinprodukt, das als Entscheidungshilfe für Gesundheitsdienstleister bei der Untersuchung von Fundusbildern auf wahrscheinliches Glaukom und altersbedingte Makuladegeneration (AMD) dienen soll. Die Software gibt auf der Grundlage der erkannten Anzeichen eine Empfehlung für eine Überweisung oder Nicht-Überweisung. Das Screening-Ergebnis ist nicht als Bestätigungsdiagnose gedacht und hat keinen definitiven Charakter. Das Screening ermöglicht eine weitere Untersuchung durch den Gesundheitsdienstleister.',
    Sherwood_Road_Toowong_: 'Suite 5/31 Sherwood Road, Toowong,',
    Brisbane_Queensland_: 'Brisbane, Queensland',
    __Australia: '4066, Australien',
    Erdkampsweg_43_22335_Hamburg: 'Erdkampsweg 43;22335 Hamburg',
    Phone___61_416220239: 'Telefon: +61 416220239',
    Are_you_sure_you_want_to_submit_review__The_eye_exam_review_is_not_editable_after_submission_:
      'Sind Sie sicher, dass Sie eine Bewertung abgeben wollen? Die Bewertung der Augenuntersuchung kann nach dem Absenden nicht mehr bearbeitet werden.',
    Are_you_sure_you_want_to_submit_exam__The_eye_exam_is_not_editable_after_submission_:
      'Sind Sie sicher, dass Sie die Prüfung einreichen wollen? Die Augenuntersuchung kann nach dem Absenden nicht mehr bearbeitet werden.',
    Are_you_sure_you_want_to_store_these_images__You_can_still_send_them_for_AI_grading_and_review_later_if_required_:
      'Sind Sie sicher, dass Sie diese Bilder speichern möchten? Sie können sie bei Bedarf dennoch zur KI-Bewertung und späteren Überprüfung einsenden.',
    Your_total_completed_reviews: 'Ihre insgesamt abgeschlossenen Überprüfungen:',
    Your_Saved_Review: 'Ihre gespeicherte Überprüfungen',
    Delete: 'Löschen',
    DELETE: 'LÖSCHEN',
    CANCEL: 'ABBRECHEN',
    Continue: 'Fortfahren',
    No_completed_reviews: 'Keine abgeschlossenen Überprüfungen',
    Review_currently_unavailable: 'Überprüfung derzeit nicht verfügbar',
    Please_refresh_the_page_and_try_again_: 'Bitte aktualisieren Sie die Seite und versuchen Sie es erneut!',
    The_eye_exam_results_must_be_reviewed_by_an_eye_health_professional_:
      'Die Ergebnisse der Augenuntersuchung müssen von einem Augenarzt überprüft werden.',
    Please_review_the_exam_now_or_check_back_later_for_updates_:
      'Bitte sehen Sie sich die Prüfung jetzt an oder schauen Sie später noch einmal nach Aktualisierungen.',
    Save_Progress___Exit: 'Speichern & Beenden',
    Exit: 'Beenden',
    Save_your_Review_Progress_: 'Überprüfung speichern & Beenden?',
    Your_progress_will_be_saved_for_24_hours_: 'Ihr Fortschritt wird für 24 Stunden gespeichert.',
    If_the_review_is_not_completed_within_this_timeframe__your_progress_will_be_deleted__and_the_exam_will_be_returned_to_the_pool_of_pending_reviews_:
      'Wenn die Prüfung nicht innerhalb dieses Zeitrahmens abgeschlossen wird, wird Ihr Fortschritt gelöscht und die Prüfung wird wieder in den Pool der ausstehenden Prüfungen aufgenommen.',
    Back: 'Zurück',
    Save___Exit: 'Speichern & Beenden',
    Exit_without_Saving_Progress_: 'Beenden, ohne den Fortschritt zu speichern?',
    Your_review_progress_will_be_deleted__and_the_exam_will_be_returned_to_the_pool_of_pending_reviews_:
      'Ihr Befundungsfortschritt wird gelöscht, und die Prüfung wird wieder in den Pool der ausstehenden Prüfungen aufgenommen.',
    Delete_Progress: 'Fortschritt Löschen',
    Information: 'Informationen',
    Reviewer_Comment: 'Kommentar des Prüfers',
    Saved_Review_not_Completed: 'Gespeicherte Überprüfung nicht abgeschlossen',
    Please_complete_your_saved_review_exam_before_starting_another_exam_:
      'Bitte schließen Sie Ihre gespeicherte Wiederholungsprüfung ab, bevor Sie mit einer weiteren Prüfung beginnen.',
    Delete_Saved_Review_: 'Gespeicherte Überprüfung löschen?',
    Public: 'Öffentlich',
    Private: 'Privat',
    public: 'Öffentlich',
    private: 'Privat',
    Reviews: 'Bewertungen',
    Germany: 'Deutschlands',
    New_Zealand: 'Neuseeland',
    Singapore: 'Singapur',
    Ireland: 'Irland',
    United_Kingdom: 'Vereinigtes Königreich',
    Malaysia: 'Malaysia',
    India: 'Indien',
    Age_related_Macular_Degeneration_will_only_be_graded_for_users_who_have_age_older_than_45_:
      'Die altersbedingte Makuladegeneration wird nur bei Benutzern über 45 Jahren diagnostiziert.',
    CDR_image_unavailable: 'Kein PEV-Bild verfügbar',
    CDR_Image_removed: 'Entfernt PEV-Bild',
    Undo: 'Rückgängig machen',
    Remove_image: 'Bild entfernen',
    // ----------------------------( 08-01-2025 ) New Words ( component: AcceptInvitation.js )-------------------------------------//
    Hey: 'Hey',
    is_inviting_you_to_join_their_organisation: 'lädt Sie ein, ihrer Organisation beizutreten',
    What_will: 'Was wird',
    be_able_to_View_: 'anzeigen können?',
    Your_Profile_Details: 'Ihre Profildetails',
    You_will_be_using: 'Sie verwenden',
    s_subscription: "'s-Abonnement.",
    You_will_be_able_to_view_all_of: 'Sie können alle',
    s_patients: 'Patienten',
    You_will_lose_access_to: 'Sie verlieren den Zugriff auf',
    You_will_lose_access_to_all: 'Sie verlieren den Zugriff auf alle',
    // ----------------------------( 08-01-2025 ) New Words ( file: exam.js )-------------------------------------//
    Submitting_images___: 'Bilder werden gesendet …',
    Successfully_Sent_Report_to: 'Bericht erfolgreich gesendet an',
    There_was_a_problem_sending_report_to: 'Beim Senden des Berichts an',
    __Please_try_again_: '. Bitte versuchen Sie es erneut.',
    There_was_a_problem_retrieving_report_send_status__Please_try_again_:
      'Beim Abrufen des Sendestatus des Berichts ist ein Problem aufgetreten. Bitte versuchen Sie es erneut.',
    Successfully_Downloaded_Report: 'Bericht erfolgreich heruntergeladen',
    There_was_a_problem_downloading_report__Please_try_again_:
      'Beim Herunterladen des Berichts ist ein Problem aufgetreten. Bitte versuchen Sie es erneut.',
    // ----------------------------( 09-01-2025 ) New Words ( component: Header.js )-------------------------------------//
    We_Use_Cookies: 'Wir verwenden Cookies',
    Our_website_uses_cookies_to_ensure_essential_functionality_and_to_improve_your_experience__You_can_choose_to_allow_only_necessary_cookies_or_accept_all_cookies_for_an_enhanced_experience_:
      'Unsere Website verwendet Cookies, um wesentliche Funktionen zu gewährleisten und Ihr Erlebnis zu verbessern. Sie können wählen, ob Sie nur notwendige Cookies zulassen oder alle Cookies akzeptieren, um ein besseres Erlebnis zu haben.',
    ACCEPT_ALL_COOKIES: 'ALLE COOKIES AKZEPTIEREN',
    NECESSARY_COOKIES_ONLY: 'NUR NOTWENDIGE COOKIES',
    // ----------------------------( 16-01-2025 ) New Words ( component: HealthDataTable.js )-------------------------------------//
    On_Diet: 'Auf Diät',
    On_Medication: 'Auf Medikamenten',
    On_Insulin: 'Auf Insulin',
    // ----------------------------( 20-01-2025 ) New Words ( component:PatientRiskServices.js )-------------------------------------//
    has_a: 'hat eine',
    __chance_of_developing_Diabetic_Retinopathy: '% Wahrscheinlichkeit, eine diabetische Retinopathie zu entwickeln',
    __chance_of_developing_Sight_Threatening_Diabetic_Retinopathy:
      'Prozentuale Wahrscheinlichkeit für die Entwicklung einer das Sehvermögen bedrohenden diabetischen Retinopathie',
    // ----------------------------( 21-01-2025 ) New Words ( component:UserCreationForm.js )-------------------------------------//
    Please_Select_Patient_Type: 'Bitte Patiententyp auswählen',
    // ----------------------------( 22-01-2025 ) New Words ( component:UserCreationForm.js )-------------------------------------//
    Unable_to_set_Django_language: 'Django-Sprache kann nicht festgelegt werden',
    // ----------------------------( 28-01-2025 ) New Words ( file:subscription.js )-------------------------------------//
    There_was_a_problem_retrieving_your_billing_history__Please_refresh_the_page_to_try_again_:
      'Beim Abrufen Ihrer Rechnungshistorie ist ein Problem aufgetreten. Bitte aktualisieren Sie die Seite, um es erneut zu versuchen.',
    There_was_a_problem_retrieving_your_billing_details__Please_refresh_the_page_to_try_again_:
      'Beim Abrufen Ihrer Rechnungsdaten ist ein Problem aufgetreten. Bitte aktualisieren Sie die Seite, um es erneut zu versuchen.',
    There_was_a_problem_of_creating_checkout_session__Please_try_again___:
      'Es gab ein Problem beim Erstellen der Checkout-Sitzung. Bitte versuchen Sie es erneut...',
    There_was_a_problem_downloading_report__Please_try_again_later_:
      'Beim Herunterladen des Berichts ist ein Problem aufgetreten. Bitte versuchen Sie es später noch einmal.',
    STDR_Risk_Prediction_report_ready: 'STDR-Risikovorhersage-Bericht fertig',
    'There was a problem sending report to {{phone_number}}. Please try again.':
      'Beim Senden des Berichts an {{phone_number}} ist ein Problem aufgetreten. Bitte versuchen Sie es erneut.',
    'Successfully Sent Report to {{phone_number}}!': 'Erfolgreich Bericht an {{phone_number}} gesendet!',
    'There was a problem sending report to {{email}}. Please try again.':
      'Beim Senden des Berichts an {{email}} ist ein Problem aufgetreten. Bitte versuchen Sie es erneut.',
    'Successfully Sent Report to {{email}}!': 'Erfolgreich Bericht an {{email}} gesendet!',
    'OTP sent to {{emailOrMobileNumber}}': 'Einmal-Passwort gesendet an {{emailOrMobileNumber}}',
    'Invitation to join {{newGroup}} has been dismissed.': 'Einladung zum Beitritt zu {{newGroup}} wurde abgelehnt.',
    'Accepted Invitation. Welcome to {{newGroup}}!': 'Angenommene Einladung. Willkommen bei {{newGroup}}!',
    'There was a problem inviting {{emails}}. Please try again.':
      'Beim Einladen von {{emails}} ist ein Problem aufgetreten. Bitte versuchen Sie es erneut.',
    'There was a problem creating a new {{role}}. Please try again.':
      'Beim Erstellen einer neuen {{role}} ist ein Problem aufgetreten. Bitte versuchen Sie es erneut.',
    There_was_a_problem_sending_OTP__Please_try_later_:
      'Es gab ein Problem beim Senden des OTP. Bitte versuchen Sie es später.',
    There_was_a_problem_checking_OTP_usage__Please_try_later_:
      'Bei der Überprüfung der Einmal-Passwort-Nutzung ist ein Problem aufgetreten. Bitte versuchen Sie es später.',
    There_was_a_problem_verifying_your_email__Please_try_again_later_:
      'Bei der Bestätigung Ihrer E-Mail ist ein Problem aufgetreten. Bitte versuchen Sie es später noch einmal.',
    There_was_a_problem_verifying_your_mobile_number__Please_try_again_later_:
      'Bei der Bestätigung Ihrer Mobilnummer ist ein Problem aufgetreten. Bitte versuchen Sie es später noch einmal.',
    // ----------------------------( 29-01-2025 ) New Words ( file:auth.js )-------------------------------------//
    There_was_a_problem_retrieving_invitation_referrals__Please_try_again:
      'Beim Abrufen der Einladungsempfehlungen ist ein Problem aufgetreten. Bitte versuchen Sie es erneut.',
    Successfully_Invited_: 'Erfolgreich Eingeladen!',
    There_was_a_problem_getting_the_invite_link__Please_try_again_:
      'Es gab ein Problem beim Abrufen des Einladungslinks. Bitte versuchen Sie es erneut.',
    Something_wrong__Please_try_later_: 'Etwas stimmt nicht. Bitte versuchen Sie es später.',
    Sorry_we_couldn_t_log_you_out_at_the_moment__Please_try_again_later_:
      'Leider können wir Sie im Moment nicht abmelden. Bitte versuchen Sie es später noch einmal.',
    Unable_to_fetch_user_preferred_language_: 'Die vom Benutzer bevorzugte Sprache kann nicht abgerufen werden:',
    Unable_to_load_saved_reviews__please_try_again_by_refreshing_the_page_:
      'Gespeicherte Befunde können nicht geladen werden. Bitte versuchen Sie es erneut, indem Sie die Seite aktualisieren.',
    There_was_a_problem_saving_the_review__Please_try_again_later_:
      'Beim Speichern des Befundes ist ein Problem aufgetreten. Bitte versuchen Sie es später noch einmal.',
    There_was_a_problem_deleting_the_review__Please_try_again_later_:
      'Beim Löschen des Befundes ist ein Problem aufgetreten. Bitte versuchen Sie es später noch einmal.',
    Account_Number_Copied_: 'Kontonummer kopiert!',
    One_or_more_responses_are_empty: 'Eine oder mehrere Antworten sind leer',
    Error_fetching_data_: 'Fehler beim Abrufen von Daten:',
    // ----------------------------( 30-01-2025 ) New Words ( component:PageNotFound.js )-------------------------------------//

    Something_went_wrong: 'Etwas ist schief gelaufen',
    This_may_happen_due_to_poor_internet_connection_or_expired_session:
      'Dies kann aufgrund einer schlechten Internetverbindung oder einer abgelaufenen Sitzung passieren.',
    Refresh: 'Aktualisieren',
    Please_refresh_page_to_try_again_: 'Bitte aktualisieren Sie die Seite, um es erneut zu versuchen.',
    // ----------------------------( 05-02-2025 ) New Words ( component:PackageControlLabel.js )-------------------------------------//
    Services_include: 'Dienstleistungen umfassen',
    LITE_PACK: 'Lite Pack',
    FULL_PACK: 'Vollpack',
    // ----------------------------( 05-02-2025 ) New Words ( component:RiskResultTag.js )-------------------------------------//
    Risk: 'Risiko',
    // ----------------------------( 05-02-2025 ) New Words ( component:ServiceFromControlLabel.js )-------------------------------------//
    Powered_By: 'Angetrieben von',
    Please_correct_the_errors_above: 'Bitte korrigieren Sie die oben genannten Fehler',
    // ----------------------------( 10-02-2025 ) New Words ( component:SendReportModel.js )-------------------------------------//
    Send_report: 'Bericht senden',
    Send_the_report_to_the_patient_through_the_chosen_method:
      'Senden Sie den Bericht über die gewählte Methode an den Kunden',
    Mobile_app: 'Handy-App',
    Allow_patient_to_access_this_report_through_the_mobile_app:
      'Ermöglichen Sie dem Kunden den Zugriff auf diesen Bericht über die Handy-App',
    Grant_patient_access: 'Kunden Zugang gewähren',
    Access_granted_at: 'Zugang gewährt am',
    Remove_access: 'Zugang entfernen',
    There_was_a_problem_granting_patient_access__Please_try_again_:
      'Es gab ein Problem bei der Gewährung des Patientenzugangs. Bitte versuchen Sie es erneut.',

    // ----------------------------( 19-02-2025 ) New Words ( file:medicalRecords.js )-------------------------------------//
    Albuminuria__mg_mmol_: 'Albuminurie (mg/mmol)',
    Low_Density_Lipoprotein__mg_dL_: 'Lipoprotein niedriger Dichte (mg/dL)',
    Choose_a_record_type: 'Wählen Sie eine Datensatzart',
  },
};

export const deUiVisibleTexts = {
  de: {
    ...deMissingTranslation.de,
    ...accountPreferences.de,
    Lost_connection_to_server__please_refresh_the_page:
      'Verbindung zum Server verloren, bitte aktualisieren Sie die Seite',
    Unable_to_load_pooled_reviews__please_try_again_by_refreshing_the_page_:
      'Die gepoolten Bewertungen können nicht geladen werden. Bitte versuchen Sie es erneut, indem Sie die Seite aktualisieren.',
    There_was_a_problem_retrieving_the_audit__Please_contact_us_if_this_persists_:
      'Beim Abrufen der Prüfung ist ein Problem aufgetreten. Bitte kontaktieren Sie uns, wenn das Problem weiterhin besteht.',
    There_was_a_problem_submitting_the_audit__Please_contact_us_if_this_persists_:
      'Bei der Übermittlung der Prüfung ist ein Problem aufgetreten. Bitte kontaktieren Sie uns, wenn das Problem weiterhin besteht.',
    There_was_a_problem_assigning_the_review__Please_try_to_review_other_exams:
      'Es gab ein Problem beim Zuordnen der Prüfung. Bitte versuchen Sie, andere Prüfungen zu bewerten',
    There_was_a_problem_logging_in__Please_try_again_later_:
      'Bei der Anmeldung ist ein Problem aufgetreten. Bitte versuchen Sie es später noch einmal.',
    The_session_is_already_ended_: 'Die Sitzung ist bereits beendet.',
    You_have_successfully_signed_up_: 'Sie haben sich erfolgreich angemeldet.',
    There_was_a_problem_signing_up__Please_try_again_later_:
      'Bei der Anmeldung ist ein Problem aufgetreten. Bitte versuchen Sie es später noch einmal.',
    No_email_or_phone_number_provided: 'Keine E-Mail oder Telefonnummer angegeben',
    There_was_a_problem_checking_the_account__Please_try_again_later_:
      'Bei der Überprüfung des Kontos ist ein Problem aufgetreten. Bitte versuchen Sie es später noch einmal.',
    Successfully_created_: 'Erfolgreich erstellt!',
    There_was_a_problem_when_retrieving_Terms_and_Conditions_data__Please_refresh_to_try_again_:
      'Beim Abrufen der Daten zu den Allgemeinen Geschäftsbedingungen ist ein Problem aufgetreten. Bitte aktualisieren Sie, um es erneut zu versuchen.',
    Thank_you_for_accepting_the_updated_terms_of_use_:
      'Vielen Dank, dass Sie die aktualisierten Nutzungsbedingungen akzeptieren.',
    There_was_a_problem_when_accepting_Terms_and_Conditions__Please_contact_us_if_it_persists_:
      'Es gab ein Problem beim Akzeptieren der Allgemeinen Geschäftsbedingungen. Bitte kontaktieren Sie uns, wenn das Problem weiterhin besteht.',
    Invitation_Link_Copied__Valid_for_24_hours_: 'Einladungslink kopiert! Gültig für 24 Stunden.',
    There_was_a_problem_joining_this_organisation__please_contact_your_organisation_admin_:
      'Es gab ein Problem beim Beitritt zu dieser Organisation. Bitte wenden Sie sich an die Verwaltung Ihrer Organisation.',
    Email_Verified_: 'E-Mail verifiziert!',
    Email_or_Mobile_Number_is_required: 'E-Mail oder Handynummer ist erforderlich',
    There_was_a_problem_creating_an_exam__Please_try_again_later_:
      'Beim Erstellen einer Prüfung ist ein Problem aufgetreten. Bitte versuchen Sie es später noch einmal.',
    There_was_a_problem_retrieving_exams__Please_refresh_the_page_to_try_again_:
      'Beim Abrufen der Prüfungen ist ein Problem aufgetreten. Bitte aktualisieren Sie die Seite, um es erneut zu versuchen.',
    There_was_a_problem_retrieving_page_number_for_exams__please_try_again_:
      'Beim Abrufen der Seitenzahl für Prüfungen ist ein Problem aufgetreten. Bitte versuchen Sie es erneut.',
    Uploading_image: 'Bild hochladen',
    Successfully_uploaded: 'Erfolgreich hochgeladen',
    There_is_a_problem_with_uploading__Please_refresh_page_and_try_again_:
      'Beim Hochladen ist ein Problem aufgetreten. Bitte aktualisieren Sie die Seite und versuchen Sie es erneut.',
    There_was_a_problem_deleting_image__Please_try_again_later_:
      'Beim Löschen des Bildes ist ein Problem aufgetreten. Bitte versuchen Sie es später noch einmal.',
    disconnected_websocket_for_exam: 'Getrennter Websocket für Untersuchung',
    There_was_a_problem_retrieving_an_image_s_information__Please_try_again_later_:
      'Beim Abrufen der Bildinformationen ist ein Problem aufgetreten. Bitte versuchen Sie es später noch einmal.',
    There_was_a_problem_retrieving_the_order_details_: 'Beim Abrufen der Bestelldaten ist ein Problem aufgetreten.',
    There_was_a_problem_when_getting_exam_details__Please_try_to_refresh_the_page_:
      'Beim Abrufen der Untersuchungsdaten ist ein Problem aufgetreten. Bitte versuchen Sie, die Seite zu aktualisieren.',
    There_was_a_problem_retrieving_urgent_exams__Please_refresh_the_page_to_try_again_:
      'Es gab ein Problem beim Abrufen von dringenden Untersuchungen. Bitte aktualisieren Sie die Seite, um es erneut zu versuchen.',
    Please_select_at_least_one_image_to_submit_:
      'Bitte wählen Sie mindestens ein Bild aus, das Sie einreichen möchten.',
    Image_s__submitted_for_grading: 'Zur Benotung eingereichte(s) Bild(er)',
    Image_s__submitted: 'Bild(er) eingereicht',
    There_was_a_problem_when_submitting_images__Please_try_again_:
      'Beim Einreichen der Bilder ist ein Problem aufgetreten. Bitte versuchen Sie es erneut.',
    There_was_a_problem_assigning_the_laterality_for_the_image__please_try_again_:
      'Es gab ein Problem bei der Zuweisung der Lateralität für das Bild. Bitte versuchen Sie es erneut.',
    No_services_selected_: 'Keine Dienste ausgewählt.',
    Successfully_submitted_: 'Erfolgreich eingereicht! ',
    There_was_a_problem_submitting_for_services__Please_try_again_later_:
      'Bei der Anmeldung zu den Diensten ist ein Problem aufgetreten. Bitte versuchen Sie es später noch einmal.',
    Successfully_resubmitted_: 'Erfolgreiche Wiedervorlage!',
    There_was_a_problem_when_resubmitting__Please_try_again_:
      'Bei der Wiedervorlage ist ein Problem aufgetreten. Bitte versuchen Sie es erneut.',
    There_was_a_problem_when_submitting_comments__Please_try_again_:
      'Bei der Übermittlung der Kommentare ist ein Problem aufgetreten. Bitte versuchen Sie es erneut.',
    Loading_report___: 'Bericht laden...',
    Report_successfully_loaded_: 'Bericht erfolgreich geladen!',
    There_was_an_error_retrieving_auditors__Please_try_again_:
      'Beim Abrufen der Prüfer ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
    There_was_an_error_retrieving_health_data__Please_refresh_the_page_to_try_again_:
      'Beim Abrufen der Gesundheitsdaten ist ein Fehler aufgetreten. Bitte aktualisieren Sie die Seite, um es erneut zu versuchen.',
    There_is_a_problem_when_adding_new_health_data__Please_try_again_later_:
      'Beim Hinzufügen neuer Gesundheitsdaten ist ein Problem aufgetreten. Bitte versuchen Sie es später noch einmal.',
    There_is_a_problem_when_updating_medical_history__Please_try_again_later_:
      'Bei der Aktualisierung der Krankengeschichte ist ein Problem aufgetreten. Bitte versuchen Sie es später noch einmal.',
    There_was_a_problem_loading_users__Please_refresh_the_page_to_try_again_:
      'Beim Laden der Benutzer ist ein Problem aufgetreten. Bitte aktualisieren Sie die Seite, um es erneut zu versuchen.',
    There_was_a_problem_loading_organisations__Please_refresh_the_page_to_try_again_:
      'Beim Laden der Organisationen ist ein Problem aufgetreten. Bitte aktualisieren Sie die Seite, um es erneut zu versuchen.',
    There_was_a_problem_adding_this_organisation__Please_try_again_later_:
      'Beim Hinzufügen dieser Organisation ist ein Problem aufgetreten. Bitte versuchen Sie es später noch einmal.',
    Successfully_approved_: 'Erfolgreich genehmigt.',
    There_was_a_problem_approving_this_user__Please_try_again_later_:
      'Bei der Zulassung dieses Benutzers ist ein Problem aufgetreten. Bitte versuchen Sie es später noch einmal.',
    Successfully_added_free_credits_: 'Erfolgreich kostenlose Credits hinzugefügt.',
    There_was_a_problem_adding_free_credits__Please_try_again_later_:
      'Es gab ein Problem beim Hinzufügen von kostenlosen Credits. Bitte versuchen Sie es später noch einmal.',
    There_was_a_problem_retrieving_meeting_hosts__Please_try_again_:
      'Es gab ein Problem bei der Abfrage von Meeting-Hosts. Bitte versuchen Sie es erneut.',
    There_was_a_problem_retrieving_your_contact_information__Please_try_to_refresh_page_:
      'Beim Abrufen Ihrer Kontaktinformationen ist ein Problem aufgetreten. Bitte versuchen Sie, die Seite zu aktualisieren.',
    There_was_a_problem_updating_your_contact_information__Please_try_again_:
      'Bei der Aktualisierung Ihrer Kontaktinformationen ist ein Problem aufgetreten. Bitte versuchen Sie es erneut.',
    failed_to_update_contact_information: 'Kontaktinformationen nicht aktualisiert',
    There_was_a_problem_retrieving_meetings__Please_try_again_:
      'Beim Abrufen von Meetings ist ein Problem aufgetreten. Bitte versuchen Sie es erneut.',
    There_was_a_problem_retrieving_history_meetings__Please_try_again_:
      'Beim Abrufen von Verlaufsbesprechungen ist ein Problem aufgetreten. Bitte versuchen Sie es erneut.',
    There_was_a_problem_retrieving_available_slots__Please_try_again_:
      'Es gab ein Problem beim Abrufen der verfügbaren Slots. Bitte versuchen Sie es erneut.',
    There_was_a_problem_retrieving_provider_s_first_available_date__Please_try_again_:
      'Beim Abrufen des ersten verfügbaren Termins des Anbieters ist ein Problem aufgetreten. Bitte versuchen Sie es erneut.',
    There_was_a_problem_retrieving_provider_schedule__Please_try_again_:
      'Beim Abrufen des Providerplans ist ein Problem aufgetreten. Bitte versuchen Sie es erneut.',
    Meeting_successfully_canceled: 'Besprechung erfolgreich abgesagt',
    There_was_a_problem_when_connecting_to_server__Please_refresh_page_and_try_again:
      'Bei der Verbindung zum Server ist ein Problem aufgetreten. Bitte aktualisieren Sie die Seite und versuchen Sie es erneut',
    There_was_a_problem_when_canceling_meeting__Please_try_again_:
      'Bei der Absage des Meetings ist ein Problem aufgetreten. Bitte versuchen Sie es erneut.',
    The_time_is_not_available__please_choose_another_time:
      'Die Zeit ist nicht verfügbar, bitte wählen Sie eine andere Zeit',
    There_was_a_problem_when_fetch_new_meeting_details__Please_try_again_:
      'Beim Abrufen neuer Besprechungsdetails ist ein Problem aufgetreten. Bitte versuchen Sie es erneut.',
    There_was_a_problem_when_booking_meeting__Please_try_again_:
      'Bei der Buchung des Meetings ist ein Problem aufgetreten. Bitte versuchen Sie es erneut.',
    Successfully_rescheduled_meeting: 'Erfolgreich verschobenes Treffen',
    There_was_a_problem_when_rescheduling_meeting__Please_try_again_:
      'Bei der Verlegung des Meetings ist ein Problem aufgetreten. Bitte versuchen Sie es erneut.',
    Your_comment_has_been_successfully_submitted_: 'Ihr Kommentar wurde erfolgreich übermittelt.',
    There_something_wrong_when_submitting_comment__please_try_again_later:
      'Beim Absenden des Kommentars ist ein Fehler aufgetreten, bitte versuchen Sie es später noch einmal.',
    The_meeting_has_ended: 'Die Sitzung ist beendet',
    There_was_a_problem_when_enter_meeting__Please_try_again_:
      'Beim Betreten des Meetings ist ein Problem aufgetreten. Bitte versuchen Sie es erneut.',
    There_was_a_problem_when_get_meeting_details__Please_try_again_:
      'Beim Abrufen der Besprechungsdetails ist ein Problem aufgetreten. Bitte versuchen Sie es erneut.',
    There_was_a_problem_retrieving_patients__Please_try_again_:
      'Beim Abrufen von Patienten ist ein Problem aufgetreten. Bitte versuchen Sie es erneut.',
    No_patients_found_: 'Keine Patienten gefunden.',
    No_matching_patient_records_found_: 'Keine passenden Patienten gefunden.',
    There_was_a_problem_retrieving_patient_details__Please_refresh_the_page_to_try_again_:
      'Beim Abrufen der Patientendaten ist ein Problem aufgetreten. Bitte aktualisieren Sie die Seite, um es erneut zu versuchen.',
    Successfully_updated_your_details_: 'Ihre Daten wurden erfolgreich aktualisiert.',
    Successfully_updated_patient_details_: 'Die Patientendaten wurden erfolgreich aktualisiert.',
    There_is_a_problem_when_updating_patient_details__Please_try_again_later_:
      'Beim Aktualisieren der Patientendaten ist ein Problem aufgetreten. Bitte versuchen Sie es später noch einmal.',
    There_was_a_problem_when_retrieving_patient_s_exams__Please_refresh_the_page_to_try_again_:
      'Beim Abrufen der Patientenuntersuchungen ist ein Problem aufgetreten. Bitte aktualisieren Sie die Seite, um es erneut zu versuchen.',
    There_was_a_problem_getting_your_details__Please_try_again_later_:
      'Bei der Erfassung Ihrer Daten ist ein Problem aufgetreten. Bitte versuchen Sie es später noch einmal.',
    There_was_a_problem_creating_a_new_patient__Please_try_again_later_:
      'Beim Anlegen eines neuen Patienten ist ein Problem aufgetreten. Bitte versuchen Sie es später noch einmal.',
    There_was_a_problem_retrieving_price_groups__Please_try_again_later_:
      'Beim Abrufen der Preisgruppen ist ein Problem aufgetreten. Bitte versuchen Sie es später noch einmal.',
    There_was_a_problem_retrieving_your_credits_data__Please_refresh_the_page_to_try_again_:
      'Beim Abrufen Ihrer Kreditdaten ist ein Problem aufgetreten. Bitte aktualisieren Sie die Seite, um es erneut zu versuchen.',
    There_was_a_problem_retrieving_the_service_prices__Please_refresh_the_page_to_try_again_:
      'Bei der Abfrage der Servicepreise ist ein Problem aufgetreten. Bitte aktualisieren Sie die Seite, um es erneut zu versuchen.',
    There_was_a_problem_retrieving_your_purchase_history__Please_refresh_the_page_to_try_again_:
      'Beim Abrufen Ihrer Kaufhistorie ist ein Problem aufgetreten. Bitte aktualisieren Sie die Seite, um es erneut zu versuchen.',
    There_was_a_problem_retrieving_your_payment_history__Please_refresh_the_page_to_try_again_:
      'Beim Abrufen Ihres Zahlungsverhaltens ist ein Problem aufgetreten. Bitte aktualisieren Sie die Seite, um es erneut zu versuchen.',
    Successfully_added_STDR_Risk_Prediction: 'STDR-Risikovorhersage erfolgreich hinzugefügt',
    Successfully_added_DR_Risk_Prediction: 'Erfolgreich hinzugefügt: DR Risk Prediction',
    DR_Risk_Prediction_report_ready: 'Bericht zur DR-Risikovorhersage fertig',
    Successfully_cancelled_subscription__Please_wait_for_a_few_seconds_and_page_will_reload_automatically:
      'Das Abonnement wurde erfolgreich gekündigt. Bitte warten Sie ein paar Sekunden und die Seite wird automatisch neu geladen',
    Successfully_resumed_subscription__Please_wait_for_a_few_seconds_and_page_will_reload_automatically:
      'Das Abonnement wurde erfolgreich wiederaufgenommen. Bitte warten Sie ein paar Sekunden und die Seite wird automatisch neu geladen',
    Confirm: 'Bestätigen',
    We_ve_Updated_Our_Terms: 'Wir haben unsere Bedingungen aktualisiert',
    In_order_to_continue_using_TeleEye_MD__you_will_need_to_agree_with_our_latest_Terms_of_Use_:
      'Um TeleEye MD weiterhin nutzen zu können, müssen Sie unseren aktuellen zustimmen',
    Terms_of_Use: 'Nutzungsbedingungen',
    progressbar: 'Fortschrittsanzeige',
    Login: 'Anmeldung',
    There_was_a_problem_connecting_to_the_server__Please_check_your_internet_connection_and_try_again_:
      'Es gab ein Problem bei der Verbindung zum Server. Bitte überprüfen Sie Ihre Internetverbindung und versuchen Sie es erneut.',
    _scheduling_simply_book_auth_get_tokens: '/scheduling/simply_book/auth/get_tokens',
    Edit_Comment: 'Kommentar bearbeiten',
    Submit: 'Einreichen',
    script: 'Skript',
    Patient_s_eyes_bleeding__Image_blurry__Let_the_eye_reviewer_know_by_adding_a_comment_:
      'Bluten die Augen des Patienten? Das Bild ist unscharf? Teilen Sie es dem Augenarzt mit, indem Sie einen Kommentar hinzufügen!',
    No_comment_left: 'kein Kommentar vorhanden',
    Operator_s_Comments: 'Kommentare des Betreibers',
    Download_Report: 'Bericht herunterladen',
    QA: 'QS',
    No_Result: 'Kein Ergebnis',
    Which_Eye_: 'Welches Auge?',
    Loading___: 'Laden...',
    Left: 'Links',
    Right: 'Rechts',
    Upgrade_for: 'Upgrade für',
    for: 'für',
    Go_to_Subscriptions: 'Zu den Abonnements gehen',
    subtitle: 'Untertitel',
    Prediction_Occurrence_: 'Vorhersage Auftreten:',
    View_Report: 'Bericht ansehen',
    Submit_for_Grading: 'Zur Benotung einreichen',
    Order_Services: 'Dienstleistungen bestellen',
    Submission_not_allowed_: 'Einreichung nicht erlaubt.',
    Please_subscribe_to_a_plan_to_start_screening: 'Bitte abonnieren Sie einen Plan, um mit dem Screening zu beginnen',
    Images_uploaded_but_not_selected: 'Bilder hochgeladen, aber nicht ausgewählt',
    Please_select_images_to_submit_for_grading:
      'Bitte wählen Sie die Bilder aus, die Sie zur Benotung einreichen möchten',
    Insufficient_exam_balance: 'Es liegen nicht genügend Credits vor, um zur Benotung eingereicht zu werden',
    Free_Trial: 'Kostenlose Testversion',
    Free_Plan: 'Freier Plan',
    OPEN_MOBILE_APP: 'MOBILE APP ÖFFNEN',
    Log_Out: 'Abmelden',
    Patient_LookUp: 'Patientensuche',
    Available_Exams_: 'Verfügbare Prüfungen:',
    Account_No_: 'Konto-Nr.',
    Terms: 'AGB',
    Below_is_your: 'Nachstehend finden Sie Ihre',
    Treat_it_as_your_identification_number_when_going_for_any_eye_screening__You_don_t_need_to_remember_it__as_it_is_also_sent_to_your_email_:
      'Betrachten Sie sie als Ihre Identifikationsnummer, wenn Sie zu einem Augenscreening gehen! Sie brauchen sie sich nicht zu merken, da sie auch an Ihre E-Mail geschickt wird.',
    This_is_the_patient_s: 'Das ist der Patient',
    Sorry__This_page_does_not_exist_: 'Entschuldigung! Diese Seite existiert nicht.',
    Back_to_Home: 'Zurück zu Home ',
    Your_password_needs_to_contain_at_least_8_characters_: 'Ihr Passwort muss mindestens 8 Zeichen enthalten.',
    'Your_password_can’t_be_too_similar_to_your_other_personal_information_':
      'Ihr Passwort darf Ihren anderen persönlichen Daten nicht zu ähnlich sein.',
    'Your_password_can’t_be_a_commonly_used_password_': 'Ihr Passwort darf kein häufig verwendetes Passwort sein.',
    'Your_password_can’t_be_entirely_numeric_': 'Ihr Passwort darf nicht ausschließlich aus Zahlen bestehen.',
    Coming_soon: 'Demnächst verfügbar',
    This_feature_is_currently_still_under_production_: 'Diese Funktion befindet sich derzeit noch in der Produktion.',
    Please_look_forward_to_our_future_releases_: 'Freuen Sie sich auf unsere zukünftigen Veröffentlichungen!',
    Cancel: 'Abbrechen',
    Go_Back: 'Zurück',
    Unknown: 'Unbekannt',
    Visual_Acuity__Uncorrected_: 'Sehschärfe (unkorrigiert)',
    Visual_Acuity__Best_Corrected_: 'Sehschärfe (bestkorrigiert)',
    IOP__mmHg_: 'IOP (mmHg)',
    HbA1c____: 'HbA1c (%)',
    Blood_Pressure__mmHg_: 'Blutdruck (mmHg)',
    Diabetes_Therapy: 'Diabetes-Therapie',
    Postprandial_Blood_Glucose__mg_dL_: 'Postprandiale Blutzuckerwerte (mg/dL)',
    Triglyceride__mg_dL_: 'Triglyzerid (mg/dL)',
    Overview: 'Übersicht',
    All_Patients: 'Alle Patienten',
    Register_Patient: 'Patient anmelden',
    Subscription: 'Abonnement',
    Settings: 'Einstellungen',
    Pending_Reviews: 'Ausstehende Bewertungen',
    Completed_Reviews: 'Abgeschlossene Bewertungen',
    My_TeleConsults: 'Meine TeleConsults',
    My_Exams: 'Meine Prüfungen',
    My_Profile: 'Mein Profil',
    Register_User: 'Benutzer registrieren',
    Invite_User: 'Benutzer einladen',
    All_Exams: 'Alle Prüfungen',
    //newly added for Delete Exam (07-01-2025)
    // ---------------------------------------------------------------------------------------------
    All_Exams_Delete: 'Alle Prüfungen - Löschen',
    Delete_Exam: 'Diese Augenuntersuchung löschen?',
    Delete_Record: 'Diese Aufnahmen löschen?',
    LB_delete_exam_desc: 'Diese Augenuntersuchung wird demnächst endgültig gelöscht werden.',
    LB_delete_record_desc: 'Diese Aufnahmen wird demnächst endgültig gelöscht werden.',
    LB_yes_permently_delete_exam: 'Ja, die Augenuntersuchung dauerhaft löschen',
    LB_yes_permently_delete_record: 'Ja, die Aufnahmen dauerhaft löschen',
    LB_delete_exam: 'PRÜFUNG LÖSCHEN',
    LB_delete_record: 'AUFNAHMEN LÖSCHEN',
    // --------------------------------------------------------------------------------------------
    Approve_Users: 'Benutzer genehmigen',
    Organisations: 'Organisationen',
    According_to_Medicare: 'Nach Angaben von Medicare',
    Country_of_Service: 'Land der Dienstleistung',
    Medicare_No_: 'Medicare Nr.',
    Post_Code: 'Postleitzahl',
    Ethnicity: 'Ethnizität',
    NRIC__Last_4_Characters_: 'NRIC (Letzte 4 Zeichen)',
    According_to_ID_Passport: 'Laut ID/Pass',
    ID_CARD__Personalausweis_: 'Personalausweis',
    Individual_Health_Identifier__IHI__number: 'Individuelle Gesundheitsidentifikationsnummer (IHI)',
    NHS_Number: 'NHS-Nummer',
    Postcode: 'Postleitzahl',
    ID_Card_Number: 'Ausweisnummer',
    REFER_TO_REPORT: 'SIEHE BERICHT',
    AI_Grading___: 'AI Benotung...',
    Not_Applicable: 'Nicht anwendbar',
    // AMD not applicable hint text ref- https://telemedc-au.atlassian.net/browse/TEL-1460
    // ------------------------------------------------------------------------------------
    Not_Applicable_tooltip: 'Gilt nicht für Patienten unter 45 Jahren',
    // ------------------------------------------------------------------------------------
    Unable_to_Grade: 'Nicht bewertbar',
    Non_Referable: 'Regulär',
    Referable: 'Kontrollbedürftig',
    Not_Urgent: 'Nicht dringend',
    Urgent: 'Dringend',
    Resolved: 'Gelöst',
    Normal: 'Normal',
    Suspected: 'Verdacht',
    Mild: 'Mild',
    Moderate: 'Mäßig',
    Severe: 'Schwer',
    Non_Significant: 'Unwesentlich',
    Significant: 'Bedeutsam',
    Mild_NPDR: 'Milde NPDR',
    Moderate_NPDR: 'Mäßige NPDR',
    Severe_NPDR: 'Schwere NPDR',
    PDR: 'PDR',
    Suspect_DR_Disease: 'Verdacht auf DR-Krankheit',
    Suspect_AMD_Disease: 'Verdacht auf AMD-Krankheit',
    Suspect_AMD_disease__High_probability_AMD__some_AMD_signs_:
      'Verdacht auf AMD-Erkrankung (hohe Wahrscheinlichkeit für AMD, einige AMD-Anzeichen)',
    Suspect_DR_disease__High_probability__some_DR_Signs_:
      'Verdacht auf DR-Krankheit (hohe Wahrscheinlichkeit, einige DR-Zeichen)',
    Click_to_update: 'Zum Aktualisieren anklicken',
    RetinoScan: 'RetinoScan',
    RetinoScan_analyses_retinal_images_to_accurately_identify_patients__with_Diabetic_Retinopathy__Glaucoma_and_or_Age_Related_Macular_Degeneration_:
      'RetinoScan analysiert Netzhautbilder, um Patienten mit diabetischer Retinopathie, Glaukom und/oder altersbedingter Makuladegeneration genau zu identifizieren.',
    RetinoScan_Plus: 'RetinoScan+',
    For_patients_with_a_disease_diagnosis__RetinoScan_Advanced_determines_the_disease_severity_and_provide_lesion_monitoring_services_:
      'Bei Patienten mit einer Krankheitsdiagnose bestimmt RetinoScan Advanced den Schweregrad der Erkrankung und bietet Dienste zur Überwachung der Läsionen.',
    Suspected_Cases: 'Verdachtsfälle',
    Review_by_Eye_Professional: 'Bewertung durch Augenprofi',
    Comprehensive_Patient_Report: 'Umfassender Patientenbericht',
    Class_II_Medical_Device: 'Medizinprodukt der Klasse II',
    Clinically_Validated: 'Klinisch validiert',
    Cloud_Storage: 'Cloud-Speicher',
    Patient_Data_Retention: 'Aufbewahrung von Patientendaten',
    In_accordance_to_HIPPA_compliances__all_patients__data_will_be_stored_and_backed_up_for_15_years:
      'In Übereinstimmung mit den HIPPA-Vorschriften werden alle Patientendaten 15 Jahre lang gespeichert und gesichert.',
    '15_years': '15 Jahre',
    Referral_Pathway: 'Verweisungspfad',
    Coming_Soon: 'Demnächst verfügbar',
    Forgot_your_password_: 'Haben Sie Ihr Passwort vergessen?',
    We_can_send_you_an_email_with_the_link_to_reset_it_:
      'Wir können Ihnen eine E-Mail mit dem Link zum Zurücksetzen der Daten schicken.',
    E_mail: 'E-mail',
    SEND_EMAIL: 'EMAIL SENDEN',
    Check_your_email: 'Prüfen Sie Ihre E-Mail',
    If_your_email_exist_in_our_system_the_reset_link_will_be_sent_to_your_inbox_Please_do_also_check_if_it_s_in_your_spam_folder_:
      'Wenn Ihre E-Mail in unserem System vorhanden ist, wird der Link zum Zurücksetzen an Ihren Posteingang gesendet. Bitte überprüfen Sie auch, ob er sich in Ihrem Spam-Ordner befindet.',
    Your_password_can_t_be_entirely_numeric_: 'Ihr Passwort darf nicht ausschließlich aus Zahlen bestehen.',
    Invalid_password: 'Ungültiges Passwort',
    Create_new_password: 'Neues Passwort erstellen',
    Please_enter_a_new_password_to_reset_your_password_:
      'Bitte geben Sie ein neues Passwort ein, um Ihr Passwort zurückzusetzen.',
    New_Password: 'Neues Passwort',
    toggle_password_visibility: 'Passwort-Sichtbarkeit umschalten',
    Confirm_Password: 'Bestätigen Sie Ihr Passwort',
    Passwords_must_be_equal: 'Passwörter müssen gleich sein',
    RESET_PASSWORD: 'PASSWORT ZURÜCKSETZEN',
    Password_Changed: 'Passwort geändert',
    You_have_successfully_changed_your_password__Try_logging_in_now_:
      'Sie haben Ihr Passwort erfolgreich geändert! Versuchen Sie jetzt, sich anzumelden!',
    Password_Change_Unsuccessful: 'Passwortänderung erfolglos',
    Sorry__There_must_be_a_problem_on_our_side__Please_contact_customer_support_to_report_this_problem_:
      'Entschuldigung! Es muss ein Problem auf unserer Seite vorliegen. Bitte kontaktieren Sie den Kundendienst, um dieses Problem zu melden.',
    Wrong_digit_code__Try_Again: 'Falscher Zahlencode. Erneut versuchen',
    Enter_your_Email_or_Mobile_number: 'Geben Sie Ihre E-Mail oder Handynummer ein',
    NEXT: 'WEITER',
    next: 'weiter',
    Log_in: 'Anmelden',
    Login_with_your_Email_or_Mobile_No_: 'Melden Sie sich mit Ihrer E-Mail- oder Handynummer an.',
    Forgot_Password: 'Passwort vergessen',
    LOGIN: 'ANMELDUNG',
    Don_t_have_an_account_: 'Sie haben noch kein Konto?',
    Sign_Up: 'Abonnieren',
    You_may_now_log_into_your_account_with_your_email_:
      'Sie können sich jetzt mit Ihrer E-Mail-Adresse in Ihr Konto anmelden.',
    You_are_already_logged_in_: 'Sie sind bereits angemeldet.',
    If_you_have_already_logged_in__please_log_out_first_and_refresh_this_page_If_not__please_contact_our_customer_support_to_report_this_problem_:
      'Wenn Sie bereits angemeldet sind, melden Sie sich bitte ab und aktualisieren Sie diese Seite, andernfalls wenden Sie sich bitte an unseren Kundensupport, um das Problem zu melden.',
    LOG_OUT: 'ABMELDEN',
    success: 'Erfolg',
    Activation_Success_: 'Aktivierungserfolg!',
    Activation_Fail___: 'Aktivierung fehlgeschlagen...',
    Sorry__You_might_have_already_been_activated__so_try_to_log_in__If_not_please_contact_our_customer_service_to_report_this_problem_:
      'Entschuldigung! Möglicherweise wurden Sie bereits aktiviert. Versuchen Sie, sich anzumelden. Falls nicht, wenden Sie sich bitte an unseren Kundendienst, um dieses Problem zu melden.',
    Please_set_your_password_so_that_you_can_use_it_to_log_into_this_platform_:
      'Bitte legen Sie Ihr Passwort so fest, dass Sie sich damit auf dieser Plattform anmelden können.',
    SET_PASSWORD: 'PASSWORT SETZEN',
    Password_Successfully_Set_: 'Passwort erfolgreich gesetzt!',
    You_may_now_log_into_your_account_with_your_credentials_:
      'Sie können sich nun mit Ihren Anmeldedaten bei Ihrem Konto anmelden.',
    Password_Set_Unsuccessful___: 'Passwort setzen erfolglos...',
    OTP_Verification: 'OTP-Verifizierung',
    Enter_the_6_digit_code_sent_to_: 'Geben Sie den 6-stelligen Code ein, der an gesendet wurde:',
    Didn_t_receive_the_code_: 'Sie haben den Code nicht erhalten?',
    Resend: 'Erneut senden',
    I_am_a____: 'Ich bin ein ...',
    Already_have_an_account_: 'Sie haben bereits ein Konto?',
    Log_In: 'Anmelden',
    SIGN_UP: 'ABONNIEREN',
    EMAIL: 'E-MAIL',
    MOBILE_NUMBER: 'MOBILNUMMER',
    VERIFIED: 'VERIFIZIERT',
    email_address: 'Email-adresse',
    mobile_number: 'Mobilnummer',
    Your_TeleEye_MD_account_is_now_activated_: 'Ihr TeleEye MD-Konto ist nun aktiviert.',
    It_will_take_up_to_1_business_day_for_TeleMedC_admins_to_verify_your_details_and_activate_your_account_:
      'Es kann bis zu 1 Arbeitstag dauern, bis die TeleMedC-Administratoren Ihre Angaben überprüft und Ihr Konto aktiviert haben.',
    Save: 'Speichern',
    Edit_Data: 'Daten bearbeiten',
    Account_Number: 'Kontonummer',
    Not_available: 'Nicht verfügbar',
    Patient_s_account_number_is_unique_and_not_available_for_modification_:
      'Die Kontonummer des Patienten ist eindeutig und kann nicht geändert werden.',
    First_Name: 'Vorname',
    Last_Name: 'Nachname',
    Date_of_Birth: 'Geburtsdatum',
    Gender: 'Geschlecht',
    Biological_Sex: 'Biologisches Geschlecht',
    select_gender_label: 'select-gender-label',
    select_ethnicity_label: 'select-ethnicity-label',
    These_reviews_have_been_assigned_to_you__please_complete_them_as_soon_as_possible_:
      'Diese Übersichten wurden Ihnen zugewiesen, bitte erledigen Sie sie so bald wie möglich.',
    There_are_currently_no_exams_available_for_review_right_now_:
      'Zurzeit sind keine Untersuchungen zur Übersicht verfügbar.',
    Leave_this_Page_: 'Verlassen Sie diese Seite?',
    The_changes_you_made_will_not_be_saved_and_the_review_will_be_returned_to_the_list_of_pending_reviews_:
      'Die von Ihnen vorgenommenen Änderungen werden nicht gespeichert und die Bewertung wird wieder in die Liste der ausstehenden Bewertungen aufgenommen.',
    Reviewed_Exam: 'Überprüfte Untersuchung',
    Review_Exam: 'Überprüfung der Messung',
    This_Exam_Review_is_from_the_Pool__it_will_be_reassigned_if_this_page_is_closed_changed_:
      'Dieser Prüfungsbericht stammt aus dem Pool, er wird neu zugewiesen, wenn diese Seite geschlossen/geändert wird.',
    Please_fill_out_all_required_fields: 'Bitte füllen Sie alle erforderlichen Felder aus',
    View_completed_reviews_: 'Sehen Sie sich Ihre abgeschlossenen Bewertungen an',
    View: 'Ansicht',
    There_are_currently_no_completed_reviews_: 'Derzeit gibt es keine abgeschlossenen Überprüfungen.',
    There_are_currently_no_saved_reviews_: 'Zurzeit gibt es keine gespeicherten Überprüfungen.',
    NON_URGENT: 'NICHT DRINGEND',
    URGENT: 'DRINGEND',
    Eye_exam_determined_as______: 'Augenuntersuchung bestimmt als...',
    If_this_case_is_URGENT__we_recommend_you_to_contact_the_patient_immediately_:
      'Wenn es sich um einen DRINGENDEN Fall handelt, empfehlen wir Ihnen, den Patienten sofort zu kontaktieren.',
    Requires_referral_to_ophthalmologist_additional_actions_:
      'Erfordert Überweisung an einen Augenarzt/zusätzliche Maßnahmen:',
    follow_up_screening_label: 'follow-up-screening-label',
    Select: 'Auswählen',
    Can_be_viewed_only_by_the_patient_s: 'Kann nur von den',
    Write_comment_here___: 'Hier Kommentar schreiben...',
    Can_be_viewed_by_both_the_patient_and_practitioners_within_TeleEye_MD_and_the_report:
      'Der Bericht kann sowohl vom Patienten als auch vom behandelnden Arzt in TeleEye MD eingesehen werden.',
    Can_be_viewed_within_TeleEye_MD_and_the_exam_report:
      'Kann in TeleEye MD und im Untersuchungsbericht angezeigt werden',
    Patient_s_Profile: 'Profil des Patienten',
    All_exam_reviews_are_taken__please_refresh_to_see_more_reviews_:
      'Alle Untersuchungskritiken sind aufgenommen, bitte aktualisieren Sie, um weitere Kritiken zu sehen.',
    // https://telemedc-au.atlassian.net/browse/TEL-1438 Change "Filter by Group" to "Filter by Organisation"
    // ------------------------------------------------------------------------------------------------------
    Filter_By_Group: 'Nach Organisation filtern',
    // ------------------------------------------------------------------------------------------------------
    Total_reviews_pending: 'Anhängige Überprüfungen insgesamt',
    Review__for_suspected_cases_only_: 'Überprüfung (nur bei Verdachtsfällen)',
    Upload_Images: 'Bilder hochladen',
    Order_Summary: 'Zusammenfassung der Bestellung',
    Order_Eye_Exam: 'Augenuntersuchung bestellen',
    Order_exam_without_eye_images: 'Untersuchung ohne Augenbilder bestellen',
    Eye_Exam: 'Augenuntersuchung',
    Reviewed_by: 'Überprüft von',
    The_next_available_eye_reviewer: 'Der nächste verfügbare Augenarzt',
    Current_Balance: 'Aktueller Saldo',
    Balance_after_Order: 'Saldo nach Bestellung',
    Eye_reviewer_s_Name: 'Name des Augenprüfers',
    Eye_Reviewer_s_Name: 'Name des Augenprüfers',
    Only_suspected_cases_are_reviewed_: 'Es werden nur Verdachtsfälle überprüft.',
    Any_Eye_Reviewer: 'Jeder Augenarzt',
    This_exam_will_be_submitted_to_the_pool_for_eye_professionals_review_as_soon_as_possible_:
      'Diese Untersuchung wird so bald wie möglich an den Pool zur Überprüfung durch die Augenärzte weitergeleitet.',
    Bad_Quality_Images_may_not_be_properly_graded_by_DR_Lite__Please_select_DR_Grader_if_you_wish_to_continue_:
      'Bilder von schlechter Qualität werden von DR Lite möglicherweise nicht richtig bewertet. Bitte wählen Sie DR Grader, wenn Sie fortfahren möchten.',
    Left_Right_not_assigned_to_image_: 'Links/Rechts nicht dem Bild zugeordnet.',
    Only_a_maximum_of_2_images_of_left_eye_is_allowed_for_submission_:
      'Es dürfen nur maximal 2 Bilder des linken Auges eingereicht werden.',
    Only_a_maximum_of_2_images_of_right_eye_is_allowed_for_submission_:
      'Es dürfen nur maximal 2 Bilder des rechten Auges eingereicht werden.',
    Please_select_your_desired_services_for_the_eye_images_:
      'Bitte wählen Sie die gewünschten Dienstleistungen für die Augenbilder aus.',
    Possible_reasons_for_bad_quality_image_: 'Mögliche Gründe für eine schlechte Bildqualität:',
    '1__Blurred_image': '1. Unscharfes Bild',
    '2__Low_illumination': '2. Geringe Beleuchtungsstärke',
    '3__Optic_lens_reflection': '3. Reflexion der optischen Linse',
    Could_be_affected_by_: 'Könnte betroffen sein von:',
    '1__Optic_lens_opacity_issue': '1. Problem der Linsentrübung',
    '2__Improper_operation': '2. Unsachgemäßer Betrieb',
    '3__Dirty_camera_lens': '3. Verschmutztes Kameraobjektiv',
    '3__Small_pupil': '3. Kleine Schülerin',
    Create_Exam: 'Untersuchung erstellen',
    ADDED_SERVICES: 'ZUSÄTZLICHE DIENSTLEISTUNGEN ',
    ADDED_PLAN: 'PLAN HINZUGEFÜGT',
    SELECT_REVIEWER: 'GUTACHTER AUSWÄHLEN',
    requirements: 'Anforderungen',
    ADD_A_COMMENT__OPTIONAL_: 'EINEN KOMMENTAR HINZUFÜGEN (OPTIONAL)',
    Color_fundus_images: 'Farbige Fundusbilder',
    The_angle_of_retina_field_of_view__FOV__from_the_image_is_between_35_and_50_degrees:
      'Der Winkel des Sichtfelds (FOV) der Netzhaut zum Bild liegt zwischen 35 und 50 Grad.',
    Minimum_size_of_the_retina_FOV_to_be_1000_x_1000_pixels: 'Mindestgröße des FOV der Netzhaut: 1000 x 1000 Pixel',
    Macula_centred_images: 'Makula-zentrierte Bilder',
    PNG_or_JPG_format: 'PNG- oder JPG-Format',
    Auto_Select: 'Automatische Auswahl',
    Deselect_All: 'Alle abwählen',
    You_can_only_upload_a_maximum_of_4_images_: 'Sie können nur maximal 4 Bilder hochladen.',
    Click_or_drop_an_image_here_to_upload: 'Klicken Sie zum Hochladen auf ein Bild oder legen Sie es hier ab',
    Failed_to_add_services_or_show_exam_details__Refresh_or_try_again_:
      'Das Hinzufügen von Leistungen oder das Anzeigen von Untersuchungsdetails ist fehlgeschlagen. Aktualisieren Sie oder versuchen Sie es erneut.',
    Add_More_Services: 'Weitere Dienste hinzufügen',
    You_may_choose_to_select_more_services_to_add_to_your_exam_:
      'Sie haben die Möglichkeit, weitere Dienste zu wählen, um Ihre Untersuchung zu ergänzen.',
    AI_grading_is_taking_longer_than_expected______: 'Die AI-Einstufung dauert länger als erwartet...',
    You_might_want_to_check_your_network_and_resubmit_it_again_:
      'Überprüfen Sie Ihr Netzwerk und senden Sie es erneut ab.',
    Please_contact_our_support_for_help: 'Bitte kontaktieren Sie unseren Support für Hilfe',
    PENDING_REVIEW: 'AUSSTEHENDE ÜBERPRÜFUNG',
    The_eye_exam_results_has_been_sent_to_an_eye_health_professional_to_review_:
      'Die Ergebnisse der Augenuntersuchung wurden zur Überprüfung an einen Augenarzt geschickt.',
    Please_check_back_after_a_while_: 'Bitte schauen Sie nach einer Weile wieder vorbei.',
    Email_Sent: 'E-Mail gesendet',
    SMS_Sent: 'SMS gesendet',
    Send_a_report_link_to_the_patient_through_the_chosen_method_:
      'Senden Sie dem Patienten über die gewählte Methode einen Berichtslink.',
    Email: 'E-mail',
    Invalid_email: 'Ungültige E-Mail',
    Send_Email: 'EMAIL SENDEN',
    SMS: 'SMS',
    _Coming_Soon_: '(Bald verfügbar)',
    Send_SMS: 'SMS versenden',
    Reviewed_on: 'Überprüft am',
    yyyy_MM_dd_hh_mmaaa: 'jjjj-MM-tt hh:mmaaa',
    Recommended_next_eye_exam_in: 'Empfohlene nächste Augenuntersuchung in',
    Upgrade_to_proactively_participate_in_your_own_well_being_and_care_:
      'Upgrade zur proaktiven Beteiligung an Ihrem eigenen Wohlbefinden und Ihrer Pflege.',
    Detect_the_severity_of_Diabetic_Retinopathy___Glaucoma__with_Cup_to_disc_Ratio_:
      'Erkennung des Schweregrads der diabetischen Retinopathie und des Glaukoms (mit Cup-to-Disc-Ratio)',
    Download_Mobile_App_to_Purchase: 'Mobile App zum Kauf herunterladen',
    Participate_in_telehealth_consultations_with_healthcare_providers_for_guidance_and_feedback_on_your_most_recent_eye_exam:
      'Nehmen Sie an telemedizinischen Konsultationen mit Gesundheitsdienstleistern teil, um Beratung und Feedback zu Ihrer letzten Augenuntersuchung zu erhalten.',
    Go_to_My_TeleConsult: 'Zu Mein TeleConsult gehen',
    Eye_Exam_Results: 'Ergebnisse der Augenuntersuchung',
    Report_is_not_ready: 'Der Bericht ist nicht fertig',
    The_report_s__will_be_ready_for_download_after_all_exam_are_graded_and_reviewed__suspected_cases_only_:
      'Der/die Bericht(e) kann/können heruntergeladen werden, nachdem alle Untersuchungen bewertet und überprüft wurden (nur bei Verdachtsfällen)',
    Download_Final_Reports: 'Download Abschlussberichte',
    Right_Eye: 'Rechtes Auge',
    Left_Eye: 'Linkes Auge',
    No_Eyes_Captured: 'Keine Augen gefangen',
    No_image_to_show_: 'Kein Bild zu zeigen.',
    right_eye: 'rechtes Auge',
    left_eye: 'linkes Auge',
    Enter_Cup_to_Disc_Ratio: 'Verhältnis von Becher zu Scheibe eingeben',
    Leave_field_blank_if_it_s_unable_to_grade: 'Lassen Sie das Feld leer, wenn es nicht bewertet werden kann.',
    Status: 'Status',
    DR_Lite: 'DR-Lite',
    DR_Grader: 'DR-Grader',
    C_D_Ratio: 'C/D Ratio',
    Your_organisation_has_yet_to_create_any_exams_: 'Ihre Organisation hat noch keine Prüfungen abgelegt.',
    You_may_select_to_view_any_of_your_past_exams_in_detail_:
      'Sie können jede Ihrer vergangenen Prüfungen im Detail ansehen.',
    You_have_not_completed_any_exams_: 'Sie haben keine Untersuchungen abgelegt.',
    Confirm_Submission: 'Einreichung bestätigen',
    Submitting_images_for_grading_: 'Einreichen von Bildern zur Bewertung.',
    Confirm_and_Grade: 'Bestätigen und benoten',
    Confirm_and_Order: 'Bestätigen und Bestellen',
    Total_Image_s_: 'Gesamte(s) Bild(er)',
    Ordered_Services: 'Bestellte Dienstleistungen',
    Exam_will_be_audited_by: 'Die Untersuchung wird abgenommen von',
    The_next_available_auditor: 'Der nächste verfügbare Prüfer',
    Please_select_images_to_continue: 'Bitte wählen Sie Bilder aus, um fortzufahren',
    Ordered_Exam: 'Bestellte Untersuchung',
    EYE_REVIEWER: 'AUGENARZT',
    Any_eye_reviewer: 'Jeder Augenarzt',
    Next_Steps: 'Nächste Schritte',
    UPLOAD_IMAGES_OR_WAIT_FOR_PATIENT_TO_VISIT_OPTICAL_STORE:
      'BILDER HOCHLADEN ODER WARTEN, BIS DER PATIENT EINEN OPTIKER AUFSUCHT',
    'Upload_and_select_images_to_be_sent_for_grading__with_several_nbsp;':
      'Hochladen und Auswählen von Bildern, die zur Bewertung gesendet werden sollen, mit mehreren&nbsp;',
    This_Report_is_Protected: 'Dieser Bericht ist geschützt',
    To_unlock_and_view_report__enter_your_last_name_and_DOB:
      'Um den Bericht zu entsperren und anzuzeigen, geben Sie Ihren Nachnamen und Ihr Geburtsdatum ein.',
    Unlock_Report: 'Bericht freischalten',
    Having_issues_unlocking_report__Contact_your_eye_screening_location_:
      'Haben Sie Probleme beim Entsperren des Berichts? Wenden Sie sich an Ihre Augenuntersuchungsstelle.',
    There_are_no_urgent_cases_to_show_: 'Es gibt keine dringenden Fälle zu zeigen.',
    Please_enter_a_number_between_0_to_300_inclusive: 'Bitte geben Sie eine Zahl zwischen 0 und 300 ein.',
    Please_enter_a_number_between_0_to_700_inclusive: 'Bitte geben Sie eine Zahl zwischen 0 und 700 ein.',
    Please_enter_a_number_between_0_to_400_inclusive: 'Bitte geben Sie eine Zahl zwischen 0 und 400 ein.',
    Please_enter_a_number_between_0_to_50_inclusive: 'Bitte geben Sie eine Zahl zwischen 0 und 50 ein.',
    Please_enter_a_number_between_0_to_250_inclusive: 'Bitte geben Sie eine Zahl zwischen 0 und 250 ein.',
    Please_enter_a_number_between_0_to_20_inclusive: 'Bitte geben Sie eine Zahl zwischen 0 und 20 ein.',
    Systolic_Blood_Pressure: 'Systolischer Blutdruck',
    Diastolic_Blood_Pressure: 'Diastolischer Blutdruck',
    Health_Data_Type: 'Gesundheit Datentyp',
    select_diabetes_therapy_label: 'auswahl-diabetes-therapielabel',
    Please_select_any_health_data_to_add_: 'Bitte wählen Sie alle hinzuzufügenden Gesundheitsdaten aus.',
    Recorded_Date_and_Time: 'Datum und Uhrzeit der Aufzeichnung',
    Now: 'Jetzt',
    New_Data: 'Neue Daten',
    Recorded_Date: 'Aufnahmedatum',
    Data: 'Daten',
    Medical_History: 'Anamnese',
    Diabetes_Type: 'Diabetes-Typ',
    select___: 'auswählen...',
    No_diabetes: 'Kein Diabetes',
    Type_1_Diabetes: 'Typ-1-Diabetes',
    Type_2_Diabetes: 'Typ-2-Diabetes',
    Gestational_Diabetes: 'Schwangerschaftsdiabetes',
    Diabetes_Onset: 'Ausbruch von Diabetes',
    When_were_you_first_diagnosed_with_diabetes_: 'Wann wurde bei Ihnen erstmals Diabetes diagnostiziert?',
    Eye_Surgery_History: 'Geschichte der Augenoperation',
    Had_eye_surgery: 'Hatte eine Augenoperation',
    Never_had_eye_surgery: 'Hatte noch keine Augenoperation',
    Heart_Disease: 'Herzkrankheit',
    Has_heart_disease: 'Hat eine Herzkrankheit',
    No_heart_disease: 'Keine Herzkrankheit',
    High_Blood_Pressure: 'Hoher Blutdruck',
    Yes: 'Ja',
    No: 'Nein',
    High_Cholesterol: 'Hoher Cholesterinspiegel',
    No_Disease: 'Keine Krankheit',
    Mild_Retinopathy: 'Leichte Retinopathie',
    Moderate_Retinopathy: 'Mäßige Retinopathie',
    Severe_Retinopathy: 'Schwere Retinopathie',
    Proliferative_Retinopathy: 'Proliferative Retinopathie',
    Macular_Edema_Presence: 'Vorhandensein eines Makulaödems',
    select_macular_edema_presence_label: 'selektiere-makuläres-Ödem-vorhanden-Etikett',
    Absent: 'Abwesend',
    Present: 'Gegenwart',
    Enter_account_number_to_retrieve_patient_: 'Geben Sie die Kontonummer ein, um den Patienten abzurufen.',
    Dashboard: 'Dashboard',
    Disclaimer__statistics_are_non_realtime_and_are_updated_midnightly_:
      'Haftungsausschluss: Die Statistiken sind nicht echtzeitfähig und werden um Mitternacht aktualisiert.',
    Welcome_back_: 'Willkommen zurück',
    Have_a_nice_day_at_work: 'Einen schönen Tag bei der Arbeit',
    Total_Patients: 'Patienten insgesamt',
    Urgent_Cases: 'Dringende Fälle',
    Total_Eye_Exams: 'Augenuntersuchungen insgesamt',
    Completed: 'Abgeschlossen',
    DISEASE_DETECTION_SUMMARY: 'ZUSAMMENFASSUNG DER KRANKHEITSERKENNUNG',
    __Diseases_are_those_marked_as_moderate_or_greater__AMD_and_DR___or_suspect__DME__Glaucoma_and_Media_Opacity_:
      '* Krankheiten, die als mittelschwer oder schwerer (AMD und DR) oder als verdächtig (DME, Glaukom und Media opacity) gekennzeichnet sind.',
    Total_number_of_images_graded_by_TeleEye_MD: 'Gesamtzahl der von TeleEye MD bewerteten Bilder',
    URGENT_CASES: 'DRINGENDE FÄLLE',
    Select_Organisation: 'Organisation auswählen',
    Approve_User: 'Benutzer genehmigen',
    Create_User: 'Benutzer erstellen',
    Registration_Success_: 'Anmeldung erfolgreich!',
    Create_another_user: 'Einen weiteren Benutzer anlegen',
    Male: 'Männlich',
    Female: 'Weiblich',
    Other: 'Andere',
    Start_typing_for_suggestions: 'Tippen Sie für Vorschläge',
    Type_of_Camera_used_: 'Art der verwendeten Kamera:',
    Organisation_Name: 'Name der Organisation',
    Which_hospital__clinic_or_establishment_do_you_belong_to_:
      'Zu welchem Krankenhaus, welcher Klinik oder Einrichtung gehören Sie?',
    Role: 'Rolle',
    Where_do_you_intend_to_practice_: 'Wo beabsichtigen Sie zu praktizieren?',
    Where_do_you_intend_to_get_screened_: 'Wo wollen Sie sich untersuchen lassen?',
    Password: 'Passwort',
    I_agree_to_the: 'Ich akzeptiere die',
    Via_Invite_Link: 'Über den Einladungslink',
    Copy_Link: 'Link kopieren',
    OR: 'ODER',
    Email_Address: 'Email-adresse',
    Type_a_valid_email_and_hit_enter_or_copy_and_paste_multiple_emails:
      'Geben Sie eine gültige E-Mail ein und drücken Sie die Eingabetaste oder kopieren Sie mehrere E-Mails und fügen Sie sie ein.',
    Invite_Users: 'Benutzer einladen',
    Invitation_Success_: 'Die Einladung war erfolgreich!',
    Almost_there__Please_ask_the_user_to_check_their_email_in_their_inbox_or_spam_folder_and_click_on_the_link_to_complete_their_registration_process_:
      'Fast fertig! Bitten Sie den Benutzer, seine E-Mail in seinem Posteingang oder Spam-Ordner zu überprüfen und auf den Link zu klicken, um die Registrierung abzuschließen.',
    Add_New_Organisation: 'Neue Organisation hinzufügen',
    Email_or_Mobile_No_: 'E-Mail oder Handy-Nr.',
    Mobile_Number: 'Mobilnummer',
    Invalid_Input: 'Ungültige Eingabe',
    Invalid_medicare_number: 'Ungültige Medicare-Nummer',
    Invalid_reference_number: 'Ungültige Referenznummer',
    Select_Country: 'Land auswählen',
    Country: 'Land',
    Passwords_do_not_match: 'Passwörter stimmen nicht überein',
    Password_must_be_at_least_8_characters: 'Das Passwort muss mindestens 8 Zeichen lang sein.',
    Password_must_contain_at_least_one_letter: 'Das Passwort muss mindestens einen Buchstaben enthalten',
    Password_must_contain_at_least_one_special_character: 'Das Passwort muss mindestens ein Sonderzeichen enthalten',
    password_confirm: 'password_confirm',
    Please_fill_up_all_required_fields_and_check_for_any_errors:
      'Bitte füllen Sie alle erforderlichen Felder aus und überprüfen Sie diese auf eventuelle Fehler.',
    Please_agree_to_all_terms_and_conditions: 'Bitte stimmen Sie allen Allgemeinen Geschäftsbedingungen zu',
    edit: 'bearbeiten',
    I_declare_that_the_above_information_is_true_and_correct_:
      'Ich erkläre, dass die vorstehenden Angaben wahrheitsgemäß und richtig sind.',
    Enter_your_details: 'Ihre Details eingeben',
    Booking_Successful: 'Buchung erfolgreich',
    BACK_TO_MEETINGS: 'ZURÜCK ZU DEN TERMINEN',
    There_is_no_meeting_available_today__please_try_another_day_:
      'Es gibt keine freien Termine. Bitte versuchen Sie mit einem anderen Datum.',
    First_name: 'Vorname',
    Last_name: 'Nachname',
    invalid_email_address: 'Ungültige E-Mail Adresse',
    Phone: 'Telefon',
    Teleconsultation: 'Telekonsultation',
    Contact_Details: 'Kontaktangaben',
    Teleconsultation_Details: 'Teleconsultation Details',
    Today__HH_mm: "Heute' HH:mm",
    Tomorrow__HH_mm: "Morgen' HH:mm",
    no_available_time: 'keine verfügbaren Termine',
    loading___: 'laden...',
    Details: 'Einzelheiten',
    SimplyBook_me: 'SimplyBook.me',
    Add_a_Note__optional_: 'Eine Notiz hinzufügen (optional)',
    Please_describe_your_symptoms_or_leave_your_questions:
      'Bitte beschreiben Sie Ihre Symptome oder hinterlassen Sie Ihre Fragen',
    Confirm_Booking: 'Bestätigen Sie die Buchung',
    Next: 'Weiter',
    Available_Meeting_Start_Times: 'Verfügbare Startzeiten für Sitzungen',
    JOIN: 'BEITRETEN',
    VIEW_REPORT: 'BERICHT ANSEHEN',
    VIEW_NOTE: 'ANMERKUNG ANSEHEN',
    Select_a_status_to_display_: 'Wählen Sie einen Status aus, der angezeigt werden soll.',
    The_patient_did_not_leave_a_note: 'Der Patient hat keine Nachricht hinterlassen',
    The_eye_specialist_has_not_left_a_note_yet: 'Der Augenarzt hat noch keine Nachricht hinterlassen',
    Enter_any_notes_from_the_meeting_here______: 'Geben Sie hier eventuelle Notizen aus der Besprechung ein. . .',
    Eye_Specialist: 'Augenarzt',
    Patient_Name: 'Name des Patienten',
    Notes: 'Anmerkungen',
    Eye_Exam_Reports: 'Berichte über Augenuntersuchungen',
    View_Report_s_: 'Bericht(e) ansehen',
    The_patient_doesn_t_have_any_history_meetings: 'Der Patient hat keine Anamnesegespräche',
    You_don_t_have_any_history_meetings_: 'Sie haben keine Treffen zur Geschichte.',
    Enable_TeleConsult: 'TeleConsult einschalten',
    TeleConsults_History: 'TeleConsults Geschichte',
    TeleConsults: 'TeleConsults',
    Feature_unavailable: 'Funktion nicht verfügbar',
    The_feature_is_currently_unavailable: 'Die Funktion ist derzeit nicht verfügbar',
    You_need_to_finish_an_eye_exam_to_book_a_TeleConsult:
      'Sie müssen eine Augenuntersuchung abgeschlossen haben, um einen TeleConsult zu buchen',
    BOOK: 'BUCHEN',
    Meeting_room_will_open_5_min_before_the_meeting_begins: 'Der Sitzungssaal wird 5 Minuten vor dem Termin geöffnet.',
    You_don_t_have_any_upcoming_meetings_: 'Sie haben keine nächsten Termine.',
    contact_us_: 'kontaktieren Sie uns.',
    New_Patient_Creation: 'Neue Patienten anlegen',
    This_patient_does_not_have_any_existing_exams_: 'Bei diesem Patienten liegen keine Untersuchungen vor.',
    Add_New_Exam: 'Neue Untersuchung hinzufügen',
    Unable_to_add_new_exam: 'Eine neue Untersuchung kann nicht hinzugefügt werden',
    Unable_to_add_new_record: 'Neuer Aufnahmen konnte nicht hinzugefügt werden',
    Each_patient_can_only_have_a_maximum_of_50_exams_: 'Jeder Patient kann nur maximal 50 Untersuchungen erhalten.',
    Each_patient_can_only_have_a_maximum_of_50_records_: 'Jeder Patient kann nur maximal 50 Untersuchungen erhalten.',
    Please_complete_and_submit_the_most_recent_exam_to_add_new_exam_:
      'Bitte füllen Sie die letzte Prüfung aus und reichen Sie diese ein, um eine neue Untersuchung hinzuzufügen.',
    Please_complete_and_submit_the_most_recent_record_to_add_new_record_:
      'Bitte füllen Sie die letzte Prüfung aus und reichen Sie diese ein, um eine neue Untersuchung hinzuzufügen.',
    Risk_Analysis: 'Risikoanalyse',
    Risk_Analysis_Unavailable: 'Risikoanalyse nicht verfügbar',
    Please_add_at_least_one_Risk_Prediction_to_view_Risk_Analaysis:
      'Bitte fügen Sie mindestens eine Risikovorhersage hinzu, um die Risikoanalyse anzuzeigen.',
    This_feature_will_be_coming_soon__stay_tuned_: 'Diese Funktion wird bald verfügbar sein, bleiben Sie dran!',
    Add_Risk_Prediction: 'Risikovorhersage hinzufügen',
    Risk_Prediction_Services_Coming_Soon___: 'Risikovorhersagedienste demnächst...',
    Enter_name_s__and_or_date_of_birth_to_retrieve_patient_:
      'Geben Sie den/die Namen und/oder das Geburtsdatum ein, um den Patienten zu finden.',
    Please_enter_some_patient_information_to_search: 'Bitte geben Sie einige Patienteninformationen zur Suche ein',
    Use_Account_Number: 'Kontonummer verwenden',
    No_Account_Number: 'Keine Kontonummer',
    Your_organisation_has_yet_to_register_any_patients_: 'Ihre Organisation hat noch keine Patienten registriert.',
    UPCOMING_APPOINTMENTS: 'ANSTEHENDE TERMINE',
    Credits: 'Credits',
    Add_Credits: 'Credits hinzufügen',
    Add_free_credits_to_specific_organisation_below_:
      'Fügen Sie einer bestimmten Organisation unten kostenlose Credits hinzu.',
    Completed_By: 'Abgeschlossen von',
    You_have_yet_to_make_any_purchases_: 'Sie haben noch keine Käufe getätigt.',
    Payment_Transactions: 'Zahlungsverkehr',
    First_Time_offer_: 'Erstmaliges Angebot!',
    You_pay: 'Sie zahlen',
    Your_payment_has_been_cancelled__If_there_was_a_problem_with_the_transaction__please_contact_our_customer_support_or_try_again_:
      'Ihre Zahlung wurde storniert. Wenn es ein Problem mit der Transaktion gab, kontaktieren Sie bitte unseren Kundendienst oder versuchen Sie es erneut.',
    Total_Credits: 'Credits insgesamt',
    CREDITS: 'CREDITS',
    Credit_Transactions: 'Credit-Transaktionen',
    Subscription_success_: 'Erfolgreiches Abonnement!',
    Unit_Price: 'Preis pro Einheit',
    Max__Exams: 'Max. Prüfungen',
    Paid_By: 'Bezahlt von',
    You_have_yet_to_make_any_payment_: 'Sie haben noch keine Zahlung geleistet.',
    What_will_be_different_: 'Was wird anders sein?',
    Join: 'Beitreten',
    Please_select_a_service_to_continue_: 'Bitte wählen Sie einen Dienst aus, um fortzufahren.',
    You_may_select_more_services_to_add_to_calculate_different_risks_:
      'Sie können weitere Dienste auswählen, um verschiedene Risiken zu berechnen.',
    Medical_History_and_Health_Data_required_to_run_each_service_:
      'Anamnese und Gesundheitsdaten, die für die Ausführung der einzelnen Dienste erforderlich sind:',
    Risk_Predictions: 'Risiko-Prognosen',
    Resume_Subscription: 'Lebenslauf Abonnement',
    Cancel_Subscription: 'Abonnement kündigen',
    Current_Plan: 'Aktueller Plan',
    Cancelled_subscription_ends_on: 'Das gekündigte Abonnement endet am',
    __Trial_ends_on: '#NAME?',
    Next_bill_of: 'Nächste Rechnung von',
    to_be_issued_on: 'auszustellen am',
    Plan_: 'Plan:',
    Add_On: 'Add-on',
    Start_your_free_trial_today: 'Starten Sie noch heute Ihren kostenlosen Test',
    By_choosing_a_subscription_package_that_suits_you_: 'Indem Sie ein Abonnementpaket wählen, das zu Ihnen passt.',
    Unable_to_view_Billing_History: 'Abrechnungshistorie kann nicht angezeigt werden',
    Billing_history_is_currently_unavailable_but_will_be_coming_soon_:
      'Die Abrechnungshistorie ist derzeit nicht verfügbar, wird aber in Kürze verfügbar sein.',
    Billing_History: 'Abrechnungshistorie',
    We_are_sad_to_see_you_go__If_you_choose_to_cancel_now_:
      'Es ist schade, dass Sie Ihr Abonnement jetzt beenden möchten. Wenn Sie sich entscheiden, jetzt zu kündigen,',
    Cancellation_will_be_effective_at_the_end_of_your_current_billing_cycle_on:
      'Die Kündigung wird zum Ende des laufenden Abrechnungszeitraums am',
    You_can_still_use_the_service_until_then_: 'Bis dahin können Sie den Dienst weiterhin nutzen.',
    We_are_here_to_welcome_you_back_at_anytime__If_you_choose_to_resume_now_:
      'Wir sind jederzeit bereit, Sie wieder aufzunehmen. Wenn Sie jetzt weitermachen wollen,',
    Your_next_payment_will_be_processed_on: 'Ihre nächste Zahlung wird bearbeitet am',
    Your_subscription_will_continue_as_before_: 'Ihr Abonnement wird wie bisher fortgesetzt.',
    Select_No__of_Exams_: 'Wählen Sie Anzahl der Prüfungen:',
    Add_On_Purchase_Not_Available: 'Add-On Kauf nicht verfügbar',
    Finish_using_current_available_Add_On_exams_before_purchasing_more:
      'Beenden Sie die Nutzung der aktuell verfügbaren Add-On-Untersuchungen, bevor Sie weitere erwerben.',
    One_time_Purchase: 'Einmaliger Kauf',
    Subscriptions: 'Abonnements',
    Add_On_Unavailable: 'Add-On nicht verfügbar',
    Add_On_is_only_available_once_you_purchase_a_subscription_plan:
      'Das Add-On ist nur verfügbar, wenn Sie einen Abonnementplan erwerben.',
    Purchase: 'Kaufen',
    Switch_Plans_Coming_Soon: 'Switch-Pläne demnächst verfügbar',
    _For_enterprise_pricings__please: '*Für Unternehmenspreise, bitte',
    Error_during_service_worker_registration_: 'Fehler bei der Registrierung des Service Workers:',
    content_type: 'Inhaltstyp',
    Upgrade_your_subscription_if_you_wish_to_select_this_service:
      'Upgraden Sie Ihr Abonnement, wenn Sie diesen Service wählen möchten',
    Basic_Plan_required_: 'Basic Plan erforderlich!',
    Standard_Plan_required_: 'Standard Plan erforderlich!',
    Premium_Plan_required_: 'Premium Plan erforderlich!',
    AMD: 'AMD',
    DME: 'DME',
    DR: 'DR',
    GLAUCOMA: 'GLAUKOM',
    MEDIA_OPACITY: 'MEDIA OPACITY',
    Disclaimer__RetinoScan_Glaucoma_and_AMD_is_for: 'Haftungsausschluss: RetinoScan Glaucoma und AMD ist nur für',
    research_use: 'Forschungszwecke',
    only_and_should_not_be_used_for_clinical_decision_making_:
      'gedacht und sollte nicht für klinische Entscheidungen verwendet werden.',
    Result: 'Ergebnis',
    Upload_and_select_up_to_4_images_to_be_sent_for_grading__with_several:
      'Laden Sie bis zu 4 Bilder hoch und wählen Sie sie aus, um sie zur Bewertung zu senden, mit mehreren',
    Create: 'Erstellen',
    User: 'Benutzer',
    Health_Condition____Right_Eye____Left_Eye: "Gesundheitszustand', 'Rechtes Auge', 'Linkes Auge",
    Health_Condition: 'Gesundheitszustand',
    health_practitioners: 'Arztpraxen',
    within_TeleEye_MD: 'des Patienten innerhalb von TeleEye MD eingesehen werden',
    You_can_navigate_away_from_this_page_while_waiting_for_results__start_another_exam__and_return_at_any_time_:
      'Sie können diese Seite verlassen, während Sie auf Ergebnisse warten, eine weitere Untersuchung starten und jederzeit zurückkehren.',
    Create_Patient: 'Patient anlegen',
    Total_Patients_Screened: 'Gesamtzahl gescreenter Patienten',
    //text changed for short text from Untersuchungsdatum to Datum
    Exam_Date: 'Datum',
    PENDING_ACTION: 'AUSSTEHENDE AKTION',
    Filter: 'Filter',
    AWAITING_RESULT: 'ERGEBNIS AUSSTEHEND',
    Chinese: 'Chinesisch',
    Indian: 'Indien',
    Malay: 'Malaysia',
    Caucasian: 'Kaukasich',
    Patient_Details: 'Patientendetails',
    Latest_Health_Data: 'Neueste Gesundheitsdaten',
    Severity: 'Schweregrad',
    'Diabetic\n_Retinopathy': 'Diabetische Retinopathie',
    Add_Comment: 'Kommentar hinzufügen',
    Eye_Images: 'Augenbilder',
    DR_Presence: 'Vorhandensein von DR',
    By_logging_in__you_agree_to_our: 'Mit dem Login stimmen Sie unseren',
    Reviewer: 'Überprüfer',
    Reviews_within: 'Überprüfungen innerhalb',
    Oldest_First: 'Älteste zuerst',
    Newest_First: 'Neueste zuerst',
    Default__All_: 'Standard (Alle)',
    Phone_Number: 'Telefonnummer',
    Organisation_Admin: 'Admin',
    If_you_have_the_user_s_email_address_that_is_within_your_organisation__you_can_invite_them_by_entering_their_email_addres_below__Instructions_will_be_sent_to_their_email_and_once_completed__they_will_be_added_to_the_organisation_:
      'Wenn Sie die E-Mail-Adresse des Benutzers haben, die zu Ihrer Organisation gehört, können Sie ihn einladen, indem Sie seine E-Mail-Adresse unten eingeben. Anweisungen werden an seine E-Mail gesendet und nach Abschluss wird er zur Organisation hinzugefügt.',
    Invite_another_user: 'Einen weiteren Benutzer einladen',
    //text change from Gesundheitsdienstleister to Praktiker for small text
    Practitioner: 'Augenoptiker',
    Patient: 'Patient',
    Optometrist: 'Optometrist',
    Sign_Up_with_your_Email: 'Registrieren Sie sich mit Ihrer E-Mail',
    Branch: 'Filiale',
    Location: 'Standort',
    Branch_Location: 'Filiale/Standort',
    Your_practice_branch_name_or_location: 'Name oder Standort Ihrer Praxisfiliale',
    Age_Related_Macular_Degeneration: 'Altersbedingte Makuladegeneration',
    for_age: 'für Alter',
    Glaucoma: 'Glaukom',
    No_Image: 'Kein Bild',
    DR_Severity: 'Schweregrad der DR',
    DR_Severity__DR_: 'Schweregrad der DR (DR)',
    Exam_Details: 'Untersuchungsdetails',
    Cup_to_Disc_Ratio: 'Papillen-Exkavations-Verhältnis',
    Disc_Asymmetry: 'Scheibenasymmetrie',
    Risk_of_Glaucoma_if: 'Risiko für Glaukom, falls',
    Send_Report: 'Bericht senden',
    REPORT_READY: 'BERICHT FERTIG',
    Additional_Information: 'Zusätzliche Informationen',
    Information: 'Informationen',
    Comment_to_Health_Practitioners_Only: 'Kommentar nur für Gesundheitsdienstleister',
    Comment_to_Patient: 'Kommentar zum Patienten',
    Accident_and_Emergency: 'Unfall und Notfall',
    '2_weeks': '2 Wochen',
    '1_month': '1 Monat',
    '3_months': '3 Monate',
    '6_months': '6 Monate',
    Normal_screening_results___continue_annual_screening:
      'Normale Screening-Ergebnisse – jährliches Screening fortsetzen',
    No_referable_conditions_in_screening_results___Continue_annual_screening:
      'Keine überweisungspflichtigen Bedingungen in Screening-Ergebnissen – Jährliches Screening fortsetzen',
    Extra_Parameters: 'Zusätzliche Parameter',
    Parameters: 'Parameter',
    Diabetic_Macular_Edema: 'Diabetisches Makulaödem',
    Media_Opacity: 'Medientrübung',
    Review_Summary: 'Überprüfungszusammenfassung',
    Reviewed_at: 'Überprüft am',
    please_login_instead: 'bitte stattdessen einloggen',
    or: 'oder',
    To_enhance_your_account_s_security_: 'Um die Sicherheit Ihres Kontos zu erhöhen:',
    Street_Address: 'Straßenadresse',
    Street: 'Straße',
    Address_Line_2__Floor_and_or_unit_number: 'Adresszeile 2: Etage und/oder Einheitsnummer',
    Suburb_City: 'Vorort/Stadt',
    City: 'Stadt',
    Town_City: 'Stadt',
    State: 'Land',
    Type: 'Typ',
    An_account_already_exists_for_this: 'Hierfür existiert bereits ein Konto',
    Neighbourhood: 'Nachbarschaft',
    organisation_admin: 'admin',
    Detected_Diseases: 'Erkannte Krankheiten',
    UNLIMITED: 'UNBEGRENZT',
    unlimited: 'unbegrenzt',
    Image_Requirements: 'Bildanforderungen',
    Start_New_Exam: 'Neue Untersuchung beginnen',
    Review: 'Bewertung',
    Leave: 'Verlassen',
    Stay: 'Bleiben',
    Health_Data: 'Gesundheit Daten',
    Eye_Exams: 'Augenuntersuchungen',
    Add_Health_Data: 'Gesundheitsdaten hinzufügen',
    Right_Uncorrected: 'Rechts unkorrigiert',
    Left_Uncorrected: 'Links unkorrigiert',
    Right_Best_Corrected: 'Rechts bestkorrigiert',
    Left_Best_Corrected: 'Links bestkorrigiert',
    Comments__optional_: 'Kommentar (optional)',
    Comments: 'Kommentar',
    e_g__Images_too_blurry__eyes_bleeding___: 'z.B. Bilder zu unscharf, Augen bluten...',
    Submit_Exam_: 'Prüfung einreichen?',
    Store_Images_: 'Bilder speichern?',
    Submit_Review_: 'Prüfung einreichen?',
    Provider_Number: 'Anbieternummer',
    Aboriginal: 'Aborigines',
    Torres_Strait_Islander: 'Torres-Strait-Insulaner',
    Aboriginal_and_Torres_Strait_Islander: 'Aborigines und Torres-Strait-Inseln',
    //-----------------------------newly added for autogenerate email (20-01-2025)-------------------------
    LB_no_email_provided: 'Keine E-Mail angegeben',
    LB_email_with_report_ready_cannot_delete: 'Prüfungen, die BERICHT BEREIT sind, können nicht gelöscht werden',
    //-----------------------------------------------------------------------------------------------------
    Organisation_Profile: 'Organisationsprofil',
    Contact_Details__optional_: 'Kontaktdaten (optional)',
    Contact_No_: 'Telefonnummer',
    Website_Link: 'Website Link',
    Your_link_must_begin_with__https__or__http__: "Ihr Link muss mit 'https://' oder 'http://' beginnen",
    e_g_: 'z. B.',
    Address: 'Adresse',
    Logo__optional_: 'Logo (optional)',
    Update_your_organisation_details_and_logo_here: 'Aktualisieren Sie hier Ihre Organisationsdetails und Ihr Logo',
    Branding__optional_: 'Branding (optional)',
    Organisation_Logo: 'Organisationslogo',
    No_logo_uploaded: 'Kein Logo hochgeladen',
    Click_to_upload_or_drag_and_drop: 'Klicken Sie zum Hochladen oder ziehen Sie es per Drag & Drop',
    SVG__PNG_or_JPG__max_file_size_3MB_: 'PNG oder JPG (maximale Dateigröße 3 MB)',
    Choose_what_to_display_on_exam_reports: 'Auswählen, was auf den Untersuchungsberichten angezeigt werden soll',
    Organisation_Name_is_displayed_if_Logo_is_not_selected:
      'Der Name der Organisation wird angezeigt, wenn das Logo nicht ausgewählt ist',
    View_Examples: 'Beispiele ansehen',
    //-----------------------------Storage Image Exam Details------------------------------------------------------
    Send_images_for_AI_Grading_and_Review: 'Bilder zur KI-Auswertung und Überprüfung eines Augenarztes einsenden',
    Send_Image_Export: 'Bildexport senden',
    Download_Image_Export: 'Bildexport herunterladen',
    Send_Images_for_AI_Grading_and_Review: 'Bilder zur KI-Auswertung und Überprüfung eines Augenarztes einsenden',
    Are_you_sure_you_want_to_send_images_for_AI_grading_and_review_:
      'Sind Sie sicher, dass Sie Bilder zur KI-Auswertung und Überprüfung einsenden möchten?',
    Image_requirements_for_AI_grading: 'Bildanforderungen für die KI-Auswertung',
    AI_Grading__Review: 'KI-Auswertung + Überprüfung',
    Store_images_only: 'Nur Bilder speichern',
    Store_images: 'Bilder speichern',
    IMAGES_STORED: 'Gespeicherte Bilder',
    All_Records: 'Alle Aufnahmen',
    Eye_Records: 'Augenaufnahmen',
    Eye_Records_Delete: 'Augenaufnahmen - Löschen',
    Start_New_Record: 'Neue Aufnahme starten',
    New_Eye_Record: 'Neue Aufnahme',
    Accepted_formats: 'Akzeptierte Formate',
    To_meet_quality_assurance__QA__standards_for_AI_grading_and_review_images_should_:
      'Um die Qualitätssicherungsstandards (QA) für die KI-Bewertung und -Überprüfung zu erfüllen, sollten Bilder:',
    Be_macula_centered__color_fundus_images: 'Seien Sie Makula-zentrierte, farbige Fundusbilder',
    Have_a_retina_field_of_view__FOV__angle_between_35__and_50_:
      'Sie haben einen Sichtwinkel der Netzhaut (FOV) zwischen 35° und 50°',
    Have_a_minimum_retina_FOV_size_of_1000___1000_pixels:
      'Sie müssen eine minimale Netzhaut-FOV-Größe von 1000 × 1000 Pixel haben',
  },
};
