export const accountPreferences = {
  en: {}, // empty object as English is the default language
  de: {
    'Email Preferences': 'E-Mail-Einstellungen',
    'Preferences for {{email}}': 'Einstellungen für {{email}}',
    'Pending Exam Notification': 'Benachrichtigung über anstehende Prüfungen',
    'Receive email notifications at 9:00AM and 6:00PM whenever there are pending exams awaiting for review.':
      'Sie erhalten um 9.00 Uhr und 18.00 Uhr eine E-Mail-Benachrichtigung, wenn Prüfungen anstehen.',
    'Completed Review Notification': 'Benachrichtigung über eine abgeschlossene Überprüfung',
    'Receive an email notification once all exams have been reviewed.':
      'Sie erhalten eine E-Mail-Benachrichtigung, sobald alle Prüfungen bewertet wurden.',
    'Unsubmitted Exam Notification': 'Benachrichtigung über nicht eingereichte Prüfungen',
    'Receive an email notification if a saved exam remains unsubmitted for over 12 hours.':
      'Sie erhalten eine E-Mail-Benachrichtigung, wenn eine gespeicherte Prüfung mehr als 12 Stunden lang nicht abgegeben wurde.',
    'SAVE PREFERENCES': 'EINSTELLUNGEN SPEICHERN',
    'Completed Exam Notification': 'Benachrichtigung über abgeschlossene Prüfungen',
    'Receive a daily email notification at 9:00AM with the number of completed exam reviews.':
      'Sie erhalten täglich um 9:00 Uhr morgens eine E-Mail-Benachrichtigung mit der Anzahl der abgeschlossenen Prüfungen.',
    'Email Preferences saved successfully': 'E-Mail-Einstellungen erfolgreich gespeichert',
    'Something went wrong, please try again': 'Ein Fehler ist aufgetreten, bitte versuchen Sie es erneut',
    'Link Expired': 'Link Abgelaufen',
    'If you no longer wish to receive these emails, you can unsubscribe here':
      'Wenn Sie diese E-Mails nicht mehr erhalten möchten, können Sie diese hier abbestellen',
  },
};
