import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Modal from '../../components/layout/templates/Modal';
import { addNewHealthData, getMostRecentHealthData } from '../../actions/healthData';
import { PlusIcon } from '../../resources/icons';
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Input,
  ListItemText,
  Checkbox,
  Switch,
  FormControlLabel,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { connect, useSelector } from 'react-redux';
import {
  HBA1C,
  BLOOD_PRESSURE,
  ALBUMINURIA,
  DIABETES_THERAPY,
  POSTPRANDIAL_BLOOD_GLUCOSE,
  TRIGLYCERIDE,
  LOW_DENSITY_LIPOPROTEIN,
  VISUAL_ACUITY,
  IOP,
  DIET,
  INSULIN,
  MEDICATION,
  VA_NONE,
  VISUAL_ACUITY_OPTIONS,
  VISUAL_ACUITY_OPTIONS_STR,
  HEALTH_DATA_TYPE_STR,
  CORRECTED_VISUAL_ACUITY,
  UNCORRECTED_VISUAL_ACUITY,
} from '../../constants/medicalRecords';
import { mapDiabetesTherapyTypeNumToStr, mapHealthDataTypeNumToStr } from '../../utils/conversions';
import { MultipleSelectMenuProps } from '../../constants/layouts';
import { getValuesGenerateKeys, transferValueGetKey } from '../../utils/helpers';
import { datePickerLangText } from '../../i18n';
import { useTranslation } from 'react-i18next';

const PREFIX = 'AddHealthData';

const classes = {
  container: `${PREFIX}-container`,
  form: `${PREFIX}-form`,
  formFields: `${PREFIX}-formFields`,
  submit: `${PREFIX}-submit`,
  datePicker: `${PREFIX}-datePicker`,
  switch: `${PREFIX}-switch`,
};

const StyledModal = styled(Modal)(({ theme }) => ({
  [`& .${classes.container}`]: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },

  [`& .${classes.form}`]: {
    margin: theme.spacing(2),
  },

  [`& .${classes.formFields}`]: {
    padding: theme.spacing(2),
  },

  [`& .${classes.submit}`]: {
    width: '30%',
  },

  [`& .${classes.datePicker}`]: {
    margin: '0',
  },

  [`& .${classes.switch}`]: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const AddHealthData = (props) => {
  const { t, i18n } = useTranslation();
  const staticWords = useSelector((state) => state.handlingTranslation.words);
  const [values, setValues] = useState({
    healthDataType: '',
    value: '',
    systolic_blood_pressure: '',
    diastolic_blood_pressure: '',
    diabetes_therapy_type: [],
    left_intraocular_pressure_value: '',
    right_intraocular_pressure_value: '',
    left_uncorrected_visual_acuity_value: '',
    right_uncorrected_visual_acuity_value: '',
    left_best_corrected_visual_acuity_value: '',
    right_best_corrected_visual_acuity_value: '',
    recorded_date: new Date(),
  });

  const [nowSelected, setNowSelected] = useState(true);
  const [hasError, setHasError ]= useState(false);
  // If Now is toggled on, update the recorded date to current date in 60000 milliseconds
  useEffect(() => {
    if (nowSelected) {
      const updateDate = () => {
        setValues((prevValues) => ({
          ...prevValues,
          recorded_date: new Date(),
        }));
      };

      // Set an interval to update the date every minute (60000 milliseconds)
      const intervalId = setInterval(updateDate, 6000);

      // Update the date immediately when the component mounts
      updateDate();

      // Clear the interval when the component unmounts
      return () => clearInterval(intervalId);
    } else {
      setValues({ ...values });
    }
    // Function to update the date
  }, [nowSelected]);

  // for not allow to click submit if error  ref - https://telemedc-au.atlassian.net/browse/TEL-1414
  useEffect(() => {
    let newHealthData = { ...values };
    if (!newHealthData.recorded_date) {
      newHealthData.recorded_date = new Date();
    }
    newHealthData.recorded_date = formatDate(newHealthData.recorded_date);
    newHealthData = filteredInputs(newHealthData);
    if(newHealthData !=null){
      // new function created for checking the not to submit empty fields ref TEL-1507
      checkSubmitValue(newHealthData)
      // if(newHealthData.healthDataType === BLOOD_PRESSURE){
      //     setHasError(errorCondition(newHealthData.healthDataType,newHealthData.systolic_blood_pressure) || errorCondition(newHealthData.healthDataType,newHealthData.diastolic_blood_pressure) )
      // }
      // else if(newHealthData.healthDataType === IOP ){
      //     setHasError(errorCondition(newHealthData.healthDataType,newHealthData.left_intraocular_pressure_value) || errorCondition(newHealthData.healthDataType,newHealthData.right_intraocular_pressure_value) )
      //   }
      // else{
      //     setHasError(errorCondition(newHealthData.healthDataType,newHealthData.value))
      //   }
    }else{
      setHasError(true)
    }
  },[values])
 
  // new function created for checking the not to submit empty fields ref TEL-1507
  const checkSubmitValue = (inputs) => {
    switch (inputs.healthDataType) {
      case HBA1C:
      case ALBUMINURIA:
      case POSTPRANDIAL_BLOOD_GLUCOSE:
      case TRIGLYCERIDE:
      case LOW_DENSITY_LIPOPROTEIN:
        setHasError(inputs.value === "" || inputs.value === "0" || errorCondition(inputs.healthDataType,inputs.value))
        break;
      case BLOOD_PRESSURE:
        if(inputs.systolic_blood_pressure === "0" || inputs.diastolic_blood_pressure === "0"){
          setHasError(true)
        }else{
          setHasError(errorCondition(inputs.healthDataType,inputs.systolic_blood_pressure) || errorCondition(inputs.healthDataType,inputs.diastolic_blood_pressure) )
        }
        break;
      case DIABETES_THERAPY:  
          setHasError(inputs.diabetes_therapy_type.toString() === "0" || inputs.diabetes_therapy_type === [0] )
        break;
      case IOP:
        if(inputs.left_intraocular_pressure_value === "0" && inputs.right_intraocular_pressure_value === "0") {
          setHasError(true)
        }else{
          setHasError(errorCondition(inputs.healthDataType,inputs.left_intraocular_pressure_value) || errorCondition(inputs.healthDataType,inputs.right_intraocular_pressure_value) )
        }
        break;
      case VISUAL_ACUITY:
        setHasError(inputs.left_uncorrected_visual_acuity_value === "0" && 
          inputs.right_uncorrected_visual_acuity_value === "0" &&
          inputs.left_best_corrected_visual_acuity_value=== "0"&&
          inputs.right_best_corrected_visual_acuity_value=== "0")
        break;
      case CORRECTED_VISUAL_ACUITY:
        setHasError( inputs.left_best_corrected_visual_acuity_value=== "0"&&
          inputs.right_best_corrected_visual_acuity_value=== "0")
        break;
      case UNCORRECTED_VISUAL_ACUITY:
        setHasError(inputs.left_uncorrected_visual_acuity_value === "0" &&
          inputs.right_uncorrected_visual_acuity_value === "0")
        break;
      default:
        return null;
  }
}

// new function created for checking the not to submit empty fields ref TEL-1507
  const handleHealthDataTypeChange = (field, event) => {
    let newValue = event.target.value;
    setValues({ ...values, [field]: newValue });
  };
  const handleChange = (field, event) => {
    let newValue = event.target.value;
    setValues({ ...values, [field]: newValue });
  };

  const handleDateChange = (date) => {
    setValues({ ...values, recorded_date: date });
  };

  const handleNowSwitchChange = (event) => {
    setNowSelected(event.target.checked);
    if (event.target.checked) {
      setValues({ ...values, recorded_date: new Date() });
    }
  };

  const formatDate = (date) => {
    if (isNaN(date.getDate())) return null;
    const formatted_date = date.toJSON();
    return formatted_date;
  };

  const filteredInputs = (inputs) => {
    let filteredInputs = {
      recorded_date: inputs.recorded_date,
      healthDataType: inputs.healthDataType,
    };
    switch (inputs.healthDataType) {
      case HBA1C:
      case ALBUMINURIA:
      case POSTPRANDIAL_BLOOD_GLUCOSE:
      case TRIGLYCERIDE:
      case LOW_DENSITY_LIPOPROTEIN:
        filteredInputs = { ...filteredInputs, value: inputs.value=== "" ? "0" : inputs.value  };
        break;
      case BLOOD_PRESSURE:
        filteredInputs = {
          ...filteredInputs,
          systolic_blood_pressure: inputs.systolic_blood_pressure === "" ? "0" :inputs.systolic_blood_pressure,
          diastolic_blood_pressure: inputs.diastolic_blood_pressure === "" ? "0":inputs.diastolic_blood_pressure,
        };
        break;
      case DIABETES_THERAPY:
        filteredInputs = {
          ...filteredInputs,
          diabetes_therapy_type: inputs.diabetes_therapy_type.toString() === "" ? [0] : inputs.diabetes_therapy_type,
        };
        break;
      case IOP:
        filteredInputs = {
          ...filteredInputs,
          left_intraocular_pressure_value: inputs.left_intraocular_pressure_value=== "" ? "0" :inputs.left_intraocular_pressure_value,
          right_intraocular_pressure_value: inputs.right_intraocular_pressure_value=== "" ? "0" :inputs.right_intraocular_pressure_value,
        };
        break;
      case VISUAL_ACUITY:
        filteredInputs = {
          ...filteredInputs,
          left_uncorrected_visual_acuity_value: inputs.left_uncorrected_visual_acuity_value === "" ? "0" :inputs.left_uncorrected_visual_acuity_value,
          right_uncorrected_visual_acuity_value: inputs.right_uncorrected_visual_acuity_value === "" ? "0" :inputs.right_uncorrected_visual_acuity_value,
          left_best_corrected_visual_acuity_value: inputs.left_best_corrected_visual_acuity_value=== "" ? "0" :inputs.left_best_corrected_visual_acuity_value,
          right_best_corrected_visual_acuity_value: inputs.right_best_corrected_visual_acuity_value=== "" ? "0" :inputs.right_best_corrected_visual_acuity_value,
        };
        break;
      case CORRECTED_VISUAL_ACUITY:
        filteredInputs = {
          ...filteredInputs,
          left_best_corrected_visual_acuity_value: inputs.left_best_corrected_visual_acuity_value=== "" ? "0" :inputs.left_best_corrected_visual_acuity_value,
          right_best_corrected_visual_acuity_value: inputs.right_best_corrected_visual_acuity_value=== "" ? "0" :inputs.right_best_corrected_visual_acuity_value,
        };
        break;
      case UNCORRECTED_VISUAL_ACUITY:
        filteredInputs = {
          ...filteredInputs,
          left_uncorrected_visual_acuity_value: inputs.left_uncorrected_visual_acuity_value=== "" ? "0" :inputs.left_uncorrected_visual_acuity_value,
          right_uncorrected_visual_acuity_value: inputs.right_uncorrected_visual_acuity_value=== "" ? "0" :inputs.right_uncorrected_visual_acuity_value,
        };
        break;
      default:
        return null;
    }
    for (const field in filteredInputs) {
      if (
        filteredInputs[field] === '' ||
        filteredInputs[field] === null ||
        (Array.isArray(filteredInputs[field]) && !filteredInputs[field].length)
      ) {
        delete filteredInputs[field];
      }
    }
    return filteredInputs;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let newHealthData = { ...values };
    if (!newHealthData.recorded_date) {
      newHealthData.recorded_date = new Date();
    }
    newHealthData.recorded_date = formatDate(newHealthData.recorded_date);
    newHealthData = filteredInputs(newHealthData);
    props.addNewHealthData(props.patientDetails.id, newHealthData);
  };

  const renderErrorText = (type, value) => {
    if (errorCondition(type, value))
      switch (type) {
        case POSTPRANDIAL_BLOOD_GLUCOSE:
          return staticWords.Please_enter_a_number_between_0_to_300_inclusive;
        case TRIGLYCERIDE:
          return staticWords.Please_enter_a_number_between_0_to_700_inclusive;
        case LOW_DENSITY_LIPOPROTEIN:
          return staticWords.Please_enter_a_number_between_0_to_400_inclusive;
        case ALBUMINURIA:
          return staticWords.Please_enter_a_number_between_0_to_50_inclusive;
        case IOP:
          return staticWords.Please_enter_a_number_between_0_to_50_inclusive;
        case BLOOD_PRESSURE:
          return staticWords.Please_enter_a_number_between_0_to_250_inclusive;
        case HBA1C:
          return staticWords.Please_enter_a_number_between_0_to_20_inclusive;
        default:
          return true;
      }
    else {
      return null;
    }
  };

  const errorCondition = (type, value) => {
    switch (type) {
      case POSTPRANDIAL_BLOOD_GLUCOSE:
        return value < 0 || value > 300 || isNaN(value);
      case TRIGLYCERIDE:
        return value < 0 || value > 700 || isNaN(value);
      case LOW_DENSITY_LIPOPROTEIN:
        return value < 0 || value > 400 || isNaN(value);
      case ALBUMINURIA:
      case IOP:
        return value < 0 || value > 50 || isNaN(value);
      case BLOOD_PRESSURE:
        return value < 0 || value > 250 || isNaN(value);
      case HBA1C:
        return value < 0 || value > 20 || isNaN(value);
      default:
        return false;
    }
  };

  const renderValuesField = () => {
    switch (values.healthDataType) {
      case HBA1C:
      case ALBUMINURIA:
      case POSTPRANDIAL_BLOOD_GLUCOSE:
      case TRIGLYCERIDE:
      case LOW_DENSITY_LIPOPROTEIN:
        return (
          <Grid item xs={12}>
            <TextField
              // label={`${mapHealthDataTypeNumToStr(
              //   values.healthDataType
              // )} Value`}
              label={staticWords[getValuesGenerateKeys(mapHealthDataTypeNumToStr(values.healthDataType))].concat(
                ' Value'
              )}
              name="value"
              value={values.value}
              onChange={(event) => handleChange('value', event)}
              error={errorCondition(values.healthDataType, values.value)}
              helperText={renderErrorText(values.healthDataType, values.value)}
              required
              fullWidth
            />
          </Grid>
        );
      case BLOOD_PRESSURE:
        return (
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  label={staticWords.Systolic_Blood_Pressure}
                  name="systolic_blood_pressure"
                  value={values.systolic_blood_pressure}
                  onChange={(event) => handleChange('systolic_blood_pressure', event)}
                  error={errorCondition(values.healthDataType, values.systolic_blood_pressure)}
                  helperText={renderErrorText(values.healthDataType, values.systolic_blood_pressure)}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label={staticWords.Diastolic_Blood_Pressure}
                  name="diastolic_blood_pressure"
                  value={values.diastolic_blood_pressure}
                  onChange={(event) => handleChange('diastolic_blood_pressure', event)}
                  error={errorCondition(values.healthDataType, values.diastolic_blood_pressure)}
                  helperText={renderErrorText(values.healthDataType, values.diastolic_blood_pressure)}
                  required
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
        );
      case DIABETES_THERAPY:
        return (
          <Grid item xs={12}>
            <FormControl className={classes.formControl} fullWidth required>
              <InputLabel id="select-diabetes-therapy-label">{staticWords.Health_Data_Type}</InputLabel>
              <Select
                defaultValue=""
                labelId="select-diabetes-therapy-label"
                id="select-diabetes-therapy"
                multiple
                value={values.diabetes_therapy_type}
                onChange={(event) => handleChange('diabetes_therapy_type', event)}
                input={<Input />}
                renderValue={(selected) =>
                  selected.map((num) => t(transferValueGetKey(mapDiabetesTherapyTypeNumToStr(num)))).join(', ')
                }
                MenuProps={MultipleSelectMenuProps}
              >
                {[DIET, INSULIN, MEDICATION].map((typeNum) => (
                  <MenuItem key={typeNum} value={typeNum}>
                    <Checkbox color="primary" checked={values.diabetes_therapy_type.includes(typeNum)} />

                    <ListItemText primary={t(transferValueGetKey(mapDiabetesTherapyTypeNumToStr(typeNum)))} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        );
      case IOP:
        return (
          <>
            <Grid item xs={6}>
              <TextField
                label={staticWords.Right_Eye}
                name="right_intraocular_pressure_value"
                value={values.right_intraocular_pressure_value}
                onChange={(event) => handleChange('right_intraocular_pressure_value', event)}
                error={errorCondition(values.healthDataType, values.right_intraocular_pressure_value)}
                helperText={renderErrorText(values.healthDataType, values.right_intraocular_pressure_value)}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={staticWords.Left_Eye}
                name="left_intraocular_pressure_value"
                value={values.left_intraocular_pressure_value}
                onChange={(event) => handleChange('left_intraocular_pressure_value', event)}
                error={errorCondition(values.healthDataType, values.left_intraocular_pressure_value)}
                helperText={renderErrorText(values.healthDataType, values.left_intraocular_pressure_value)}
                required
                fullWidth
              />
            </Grid>
          </>
        );
      case VISUAL_ACUITY: // legacy
        return (
          <>
            {visualAcuityField('right_best_corrected_visual_acuity_value', staticWords.Right_Best_Corrected)}
            {visualAcuityField('left_best_corrected_visual_acuity_value', staticWords.Left_Best_Corrected)}
            {visualAcuityField('right_uncorrected_visual_acuity_value', staticWords.Right_Uncorrected)}
            {visualAcuityField('left_uncorrected_visual_acuity_value', staticWords.Left_Uncorrected)}
          </>
        );
      case CORRECTED_VISUAL_ACUITY:
        return (
          <>
            {visualAcuityField('right_best_corrected_visual_acuity_value', staticWords.Right_Best_Corrected)}
            {visualAcuityField('left_best_corrected_visual_acuity_value', staticWords.Left_Best_Corrected)}
          </>
        );
      case UNCORRECTED_VISUAL_ACUITY:
        return (
          <>
            {visualAcuityField('right_uncorrected_visual_acuity_value', staticWords.Right_Uncorrected)}
            {visualAcuityField('left_uncorrected_visual_acuity_value', staticWords.Left_Uncorrected)}
          </>
        );
      default:
        return null;
    }
  };

  const visualAcuityField = (varName, labelName) => {
    return (
      <Grid item xs={6}>
        <FormControl className={classes.formControl} fullWidth required>
          <InputLabel id={`select-${varName}-label`}>{labelName}</InputLabel>
          <Select
            defaultValue=""
            labelId={`select-${varName}-label`}
            name={varName}
            value={values[varName]}
            onChange={(event) => handleChange(varName, event)}
          >
            <MenuItem value={VA_NONE}>
              <em>{t(VISUAL_ACUITY_OPTIONS_STR[VA_NONE])}</em>
            </MenuItem>
            {VISUAL_ACUITY_OPTIONS.map((option) => (
              <MenuItem value={option}>
                {/* render dropdown options without translation if they are numbers */}
                {t(VISUAL_ACUITY_OPTIONS_STR[option]) || VISUAL_ACUITY_OPTIONS_STR[option]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    );
  };
  return (
    <StyledModal
      title={'Add Health Data'}
      icon={<PlusIcon />}
      modalName="AddHealthData"
      description={staticWords.Please_select_any_health_data_to_add_}
      primaryButtonText={props.btnName}
      // for not allow to click submit if error  ref - https://telemedc-au.atlassian.net/browse/TEL-1414
      disablePrimaryClick={hasError}
      onPrimaryClick={handleSubmit}
    >
      <Grid container spacing={3} pt={2}>
        <Grid item xs={12}>
          <FormControl className={classes.formControl} fullWidth required>
            <InputLabel id="select-health-data-type-label">{staticWords.Health_Data_Type}</InputLabel>

            <Select
              defaultValue=""
              labelId="select-health-data-type-label"
              name="healthDataType"
              value={values.healthDataType}
              onChange={(event) => handleHealthDataTypeChange('healthDataType', event)} // new function created for checking the not to submit empty fields ref TEL-1507
            >
              {Object.entries(HEALTH_DATA_TYPE_STR)
                .reverse()
                .map(([key, value]) => {
                  return (
                    <MenuItem key={key} value={parseInt(key)}>
                      {t(value)}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Grid>
        {renderValuesField()}
        <Grid item xs={8}>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={datePickerLangText(i18n.language)}>
            <DesktopDatePicker
              renderInput={(props) => <TextField {...props} />}
              margin="none"
              variant="inline"
              className={classes.datePicker}
              disableFuture
              inputFormat="dd/MM/yyyy hh:mm a"
              label={staticWords.Recorded_Date_and_Time}
              disabled={nowSelected}
              value={values.recorded_date}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                'aria-label': 'recorded date and time',
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={4} className={classes.switch}>
          <FormControlLabel
            control={<Switch checked={nowSelected} onChange={handleNowSwitchChange} name="now" color="primary" />}
            label={staticWords.Now}
          />
        </Grid>
      </Grid>
    </StyledModal>
  );
};

AddHealthData.propTypes = {
  patientDetails: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  patientDetails: state.patients.patientDetails,
  addNewHealthData: PropTypes.func.isRequired,
});

export default connect(mapStateToProps, { addNewHealthData, getMostRecentHealthData })(AddHealthData);
