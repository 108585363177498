// MULTIPLE SELECT INPUT
const SELECT_ITEM_HEIGHT = 48;
const SELECT_ITEM_PADDING_TOP = 8;

export const MultipleSelectMenuProps = {
  PaperProps: {
    style: {
      maxHeight: SELECT_ITEM_HEIGHT * 4.5 + SELECT_ITEM_PADDING_TOP,
      width: 256,
    },
  },
};

export const DRAWER_WIDTH_LEFT_COLLAPSED = 80;

export const DRAWER_WIDTH_LEFT = 256;
export const DRAWER_WIDTH_RIGHT = 428;

export const MOBILE_VIEW_BREAKPOINT = 'xs';
export const MOBILE_NAVIGATION_BREAKPOINT = 'md';
