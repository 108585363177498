import {
  SET_CREDENTIALS,
  USER_LOADED,
  USER_LOADING,
  AUTH_ERROR,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  SIGNUP_SUCCESS,
  TERMS_RECEIVED,
  UNAGREED_TERMS_RECEIVED,
  ACCEPTED_TERMS,
  INVITATION_CHECK,
  INVITATION_RESPONSE,
  INVITATION_SEND,
  CHECK_OTP_USAGE,
} from '../actions/types';
import { OPHTHALMOLOGIST } from '../constants/constants';

const initialState = {
  credentials: {},
  isAuthenticated: false,
  isLoading: true,
  user: null,
  message: null,
  termsOfUse: [],
  invitationSend: null,
  invitationReferral: null,
  invitationResponse: null,
  otpUsage: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CREDENTIALS:
      return {
        ...state,
        credentials: action.payload,
      };
    case USER_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        user: action.payload,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        user: action.payload,
        logoutSuccess: false,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
        isLoading: false,
        user: null,
        credentials: {},
        logoutSuccess: true,
      };
    case AUTH_ERROR:
    case LOGIN_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case SIGNUP_SUCCESS:
      return {
        ...state,
        invitationReferral: {},
      };

    case TERMS_RECEIVED:
      let ophTnC = [];
      action.payload = action.payload.filter((term) => {
        if (parseInt(term.slug) === OPHTHALMOLOGIST) {
          ophTnC.push(term);
        } else {
          return term;
        }
      });

      return {
        ...state,
        termsOfUse: action.payload,
        ophTermsOfUse: ophTnC,
      };
    case UNAGREED_TERMS_RECEIVED:
      return {
        ...state,
        unagreedTermsOfUse: action.payload,
      };
    case ACCEPTED_TERMS: //do nothing, just log to console
      return {
        ...state,
      };
    case INVITATION_SEND:
      return {
        ...state,
        invitationSend: action.payload,
      };
    case INVITATION_CHECK:
      return {
        ...state,
        invitationReferral: action.payload,
      };
    case INVITATION_RESPONSE:
      let groups = null;
      let type = action.payload.response.type;
      if (type == 'ACCEPT') {
        groups = [{ name: action.payload.newGroup }];
      } else if (type == 'DECLINE') {
        groups = state.user?.groups;
      } else {
        groups = state.user?.groups;
      }
      return {
        ...state,
        user: { ...state.user, groups: groups },
        invitationReferral: {},
      };
    case CHECK_OTP_USAGE:
      return {
        ...state,
        otpUsage: action.payload,
      };
    default:
      return state;
  }
}
