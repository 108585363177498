import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import PropTypes from 'prop-types';

import { AUSTRALIA, IRELAND } from '../../../../constants/constants';
import { stripString } from '../../../../utils/helpers';
import { useTranslation } from 'react-i18next';
import { getDeployRegion } from '../../../../utils/configurationHelpers';
import theme from '../../../../styles/theme';

const NationalIDField = (props) => {
  const {
    country,
    label,
    required,
    helperText,
    disabled,
    onChange,
    onError,
    pattern,
    excludedChars,
    maxLength,
    name,
    initialValue = '',
    InputProps = {},
    resetValues,
  } = props;
  const { t } = useTranslation();
  const staticWords = useSelector((state) => state.handlingTranslation.words);

  const parseValue = (value) => {
    switch (country) {
      case AUSTRALIA:
        const medicareNumber = value.slice(0, 10);
        const medicareRefNo = value.slice(10, 11);
        return {
          medicare_number: medicareNumber,
          medicare_ref_no: medicareRefNo,
        };
      default:
        return { national_id: initialValue };
    }
  };
  const getInitialError = () => {
    switch (country) {
      case AUSTRALIA:
        return {
          medicare_number: {
            error: '',
            show: false,
          },
          medicare_ref_no: {
            error: '',
            show: false,
          },
        };
      default:
        return {
          national_id: {
            error: '',
            show: false,
          },
        };
    }
  };
  const [values, setValues] = useState(parseValue(initialValue));
  const [errors, setErrors] = useState(getInitialError());
  const [output, setOutput] = useState(initialValue);

  useEffect(() => {
    if (initialValue === '-') {
      setValues(parseValue('-'));
    } else if (!initialValue) {
      setValues(parseValue(''));
    } else if (initialValue !== output) {
      setValues(parseValue(initialValue));
      setErrors(getInitialError());
    }
  }, [initialValue]);
  useEffect(() => {
    if (!resetValues) setErrors(getInitialError());
  }, [resetValues]);
  const handleOnChange = (event) => {
    let output = stripString(event.target.value, excludedChars, maxLength);
    const newValues = { ...values, [event.target.name]: output };
    if (getDeployRegion() === IRELAND) {
      let value = event.target.value.replace(/\D/g, ''); // Remove non-numeric characters
      // Format the input as 999-999-9999
      if (value.length > 3 && value.length <= 6) {
        value = `${value.slice(0, 3)}-${value.slice(3)}`;
      } else if (value.length > 6) {
        value = `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(6, 13)}`;
      }
      newValues.national_id = value;
    }
    const isValid = validateField(event.target.name, newValues);
    onError?.(!isValid);

    setValues(newValues);
    if (onChange) {
      if (event.target.name === 'medicare_number') {
        if (values.medicare_ref_no === undefined) {
          output = output + '';
        } else {
          output = output + values.medicare_ref_no;
        }
      } else if (event.target.name === 'medicare_ref_no') {
        if (output === '0') {
          output = values.medicare_number + '';
        } else {
          output = values.medicare_number + output;
        }
      }
      onChange({ target: { name: name, value: output } });
    }
    setOutput(output);
  };
  const validateField = (fieldName, values) => {
    // validate Medicare fields
    if (['medicare_number', 'medicare_ref_no'].includes(fieldName)) {
      return validateMedicareField(values);
    }
    // if field is not required, check if it is empty
    if (values[fieldName].length > 0) {
      // if pattern is provided, validate against it

      if (pattern) {
        const regex = new RegExp(pattern);
        if (
          getDeployRegion() === IRELAND &&
          fieldName === 'national_id' &&
          values['national_id'].length > 0 &&
          values['national_id'].length > 12
        ) {
          setErrors({
            ...errors,
            [fieldName]: {
              ...errors[fieldName],
              error: '',
            },
          });
          return true;
        } else {
          if (!regex.test(values[fieldName])) {
            setErrors({
              ...errors,
              [fieldName]: {
                ...errors[fieldName],
                error: 'Invalid_Input',
              },
            });
            return false;
          }
        }
      }
    }
    setErrors({
      ...errors,
      [fieldName]: {
        ...errors[fieldName],
        error: '',
      },
    });
    return true;
  };

  const validateMedicareField = (values) => {
    const { medicare_number, medicare_ref_no } = values;
    let isValid = true;
    let newErrors = { ...errors };
    // Checks if medicare number is valid is not empty
    if ((medicare_number?.length > 0 || medicare_ref_no?.length > 0) && pattern.test(medicare_number) === false) {
      newErrors = {
        ...newErrors,
        medicare_number: {
          ...errors['medicare_number'],
          error: 'Invalid_medicare_number',
        },
      };
      isValid = false;
    } else {
      newErrors = {
        ...newErrors,
        medicare_number: {
          ...errors['medicare_number'],
          error: '',
        },
      };
    }
    if (medicare_number !== '' && /^[1-9]/.test(medicare_ref_no) === false) {
      newErrors = {
        ...newErrors,
        medicare_ref_no: {
          ...errors['medicare_ref_no'],
          error: 'Invalid_reference_number',
        },
      };
      isValid = false;
    } else {
      newErrors = {
        ...newErrors,
        medicare_ref_no: {
          ...errors['medicare_ref_no'],
          error: '',
        },
      };
    }
    setErrors(newErrors);
    return isValid;
  };

  const handleOnBlur = () => {
    const newErrors = Object.fromEntries(
      Object.entries(errors).map(([key, value]) => {
        return [key, { ...value, show: !!value.error }];
      })
    );
    setErrors(newErrors);
  };

  const renderMedicareField = () => {
    const existedValues = { existedMedNumber: '', existedMedicare_ref_no: '' };
    if (values?.national_id?.length) {
      const lastCharacter = values.national_id.slice(-1); // Gets the last character
      const pendingCharacters = values.national_id.slice(0, -1); // Gets all except the last character
      existedValues.existedMedNumber = pendingCharacters;
      existedValues.existedMedicare_ref_no = lastCharacter;
    }

    return (
      <Grid container spacing={1}>
        <Grid item xs={8}>
          <TextField
            label={label}
            name="medicare_number"
            value={values.medicare_number || existedValues.existedMedNumber}
            error={errors.medicare_number?.show && !!errors?.medicare_number?.error}
            fullWidth
            required={required}
            helperText={
              errors.medicare_number?.show && errors.medicare_number?.error
                ? t(errors?.medicare_number?.error)
                : helperText
            }
            inputProps={{
              style: { textTransform: 'uppercase' },
            }}
            InputProps={{ ...InputProps }}
            disabled={disabled}
            autoComplete="off"
            onBlur={handleOnBlur}
            onChange={handleOnChange}
          />
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth required={required}>
            <InputLabel
              id="medicare-reference-number"
              sx={{ color: errors.medicare_ref_no?.show && theme.palette.error.main }}
            >
              {staticWords.Ref_No_}
            </InputLabel>
            <Select
              name="medicare_ref_no"
              labelId="medicare-reference-number"
              id="medicare-reference-No."
              value={values.medicare_ref_no || existedValues.existedMedicare_ref_no}
              error={errors.medicare_ref_no?.show && !!t(errors.medicare_ref_no?.error)}
              label={staticWords.medicare_reference_NO_}
              fullWidth
              disabled={disabled}
              onBlur={handleOnBlur}
              onChange={handleOnChange}
            >
              {Array.from(Array(10).keys()).map((i) => (
                <MenuItem value={i === 0 ? '0' : i.toString()} key={i}>
                  {i === 0 ? t('Select') : i}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    );
  };

  const renderDefaultField = () => {
    return (
      <Grid container>
        <TextField
          label={label}
          name="national_id"
          value={values.national_id}
          fullWidth
          required={required}
          error={errors.national_id.show && !!errors.national_id.error}
          helperText={
            errors.national_id.show && errors.national_id.error
              ? t(errors.national_id.error)
              : disabled
                ? ' '
                : helperText
          }
          inputProps={{
            style: { textTransform: 'uppercase' },
          }}
          disabled={disabled}
          autoComplete="off"
          InputProps={{ ...InputProps }}
          onBlur={handleOnBlur}
          onChange={handleOnChange}
        />
      </Grid>
    );
  };

  switch (country) {
    case AUSTRALIA:
      return renderMedicareField();
    default:
      return renderDefaultField();
  }
};

NationalIDField.propTypes = {
  country: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onError: PropTypes.func,
  pattern: PropTypes.instanceOf(RegExp),
  excludedChars: PropTypes.instanceOf(RegExp),
  maxLength: PropTypes.number,
  name: PropTypes.string,
  initialValue: PropTypes.string,
  InputProps: PropTypes.object,
};

export default NationalIDField;
