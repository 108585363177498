import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { de, enAU as en } from 'date-fns/locale';
import Cookies from 'js-cookie';
import { SUPPORTED_LANGUAGES } from './constants/constants';
import { enUiVisibleTexts } from './translation/en';
import { deUiVisibleTexts } from './translation/de';
import { dynamicWords } from './translation/dynamicWords';
const resources = {
  en: {
    translation: { ...enUiVisibleTexts.en, ...dynamicWords.en },
  },
  de: {
    translation: { ...deUiVisibleTexts.de, ...dynamicWords.de },
  },
};

function getSupportedLanguage() {
  let cookieLang = Cookies.get('django_language');

  const browserLang = window.navigator.language;
  const localStorageLang = window.localStorage.i18nextLng;

  const language = cookieLang || localStorageLang || browserLang || 'en';

  const baseLanguage = language.split('-')[0];

  return SUPPORTED_LANGUAGES.includes(baseLanguage) ? baseLanguage : 'en';
}

const initialLang = getSupportedLanguage();
const availableLanguages = ['en', 'de'];

const i18nConfig = {
  fallbackLng: 'en',
  lng: initialLang,
  resources,
  detection: {
    // Define detection order and methods
    order: ['cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
    caches: ['localStorage'],
    lookupCookie: 'django_language', // follow django language
    excludeCacheFor: ['cimode'],
    cookieMinutes: 10,
    cookieDomain: 'myDomain',
    checkWhitelist: true,
  },
  whitelist: availableLanguages,
  react: {
    useSuspense: false,
  },
  interpolation: {
    escapeValue: false,
  },
};

i18n.use(initReactI18next).use(LanguageDetector).init(i18nConfig);

export const datePickerLangText = (argLang) => {
  switch (argLang) {
    case 'en': {
      return en;
    }
    case 'de': {
      return de;
    }
    default: {
      return en;
    }
  }
};
