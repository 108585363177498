import { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Divider, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import Card from '../../../components/layout/atoms/Card';
import Modal from '../../../components/layout/templates/Modal';
import { CheckSquareIcon, UploadCloudIcon } from '../../../resources/icons';
import { transferValueGetKey } from '../../../utils/helpers';
import {
  ASSIGNED,
  AUDIT_SVC,
  EXAM_ORDERED,
  POOLED,
  RETINO_SCAN_PLUS_STR,
  RETINO_SCAN_PACKAGES,
  RETINO_SCAN_STR,
  REVIEW_SUSPECTED_CASES,
} from '../../../constants/constants';

const ServiceDetails = (props) => {
  const { title, description } = props;
  return (
    <Grid item container>
      <Grid item xs={5}>
        <Typography component="span" variant="subtitle1">
          {title}
        </Typography>
      </Grid>
      <Grid item xs={7} justifyContent="space-between">
        <Typography variant="body1" color={description === 0 ? 'warning.dark' : 'textPrimary'} align="right">
          {description}
        </Typography>
      </Grid>
    </Grid>
  );
};

const ConfirmExamModal = (props) => {
  const { imagesCount, onSubmit, currentPlan, billing, modalName = 'ConfirmOrderSummary', examStatus } = props;

  const staticWords = useSelector((state) => state.handlingTranslation.words);
  const { t, i8n } = useTranslation();
  const providedServices =
    currentPlan === 'retinoscan_plus' ? [RETINO_SCAN_PLUS_STR, REVIEW_SUSPECTED_CASES] : [RETINO_SCAN_STR];

  const balance =
    billing?.total_exam_balance === 'unlimited'
      ? staticWords[transferValueGetKey(billing.total_exam_balance)]
      : billing?.total_exam_balance || 0;

  return (
    <Modal
      title={'Order Summary'}
      icon={<CheckSquareIcon />}
      modalName={modalName}
      onPrimaryClick={(e) => onSubmit(e)}
      primaryButtonText={staticWords.Submit_for_Grading}
    >
      <Card
        sx={{
          width: 420,
          backgroundColor: (theme) => theme.palette.background.default,
          boxShadow: 'none',
          padding: (theme) => theme.spacing(3),
        }}
      >
        <Grid container gap={1}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" color="textPrimary">
              1 x {staticWords.Eye_Exam}
            </Typography>
          </Grid>
          {providedServices.map((label, index) => (
            <Grid key={index} item xs={12}>
              <Typography variant="subtitle1">
                <span>1 x </span>
                {staticWords[transferValueGetKey(label)]}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Card>
      <Grid container mt={2}>
        <ServiceDetails title={staticWords.Eye_Images} description={imagesCount} />
        <Grid item xs={12}>
          <Divider sx={{ marginY: (theme) => theme.spacing(2) }} />
        </Grid>
        <Grid item xs={12}>
          <ServiceDetails title={staticWords.Current_Balance} description={balance} />
          <ServiceDetails
            title={staticWords.Balance_after_Order}
            description={typeof balance === 'number' ? balance - 1 : balance}
          />
        </Grid>
      </Grid>
    </Modal>
  );
};

ConfirmExamModal.propTypes = {
  servicePrices: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  imagesCount: PropTypes.number,
  modalName: PropTypes.oneOf(['ConfirmPurchase', 'ConfirmSubmission']),
};

const mapStateToProps = (state) => ({
  examStatus: state?.exams?.exam_details?.process?.status,
  billing: state.subscriptions.billing,
});

export default connect(mapStateToProps)(ConfirmExamModal);
