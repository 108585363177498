import axiosInstance from '../axios';
import { t } from 'i18next';
import { transferValueGetKey } from '../utils/helpers';
import { SET_SNACKBAR_ERROR, SET_SNACKBAR_SUCCESS } from './types';
export const postLanguageCode = (lang) => async (dispatch) => {
  return await axiosInstance
    .post('locale/set_language', {
      language_code: lang,
    })
    .then((result) => {
      dispatch({
        type: 'langCode',
        payload: result.data,
      });
      dispatch({
        type: SET_SNACKBAR_SUCCESS,
        payload: result.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: SET_SNACKBAR_ERROR,
        payload: t('Unable_to_set_Django_language'),
      });
    });
};

export const getUserPreferredLanguage = async () => {
  return await axiosInstance
    .get('locale/set_language')
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
};
