import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { TableContainer, TableHead, Table, TableRow, TableCell, TableBody, Paper } from '@mui/material';
import { mapHealthDataTypeNumToStr } from '../../../utils/conversions';
import {
  DIABETES_THERAPY_TYPE_STR,
  IOP,
  VISUAL_ACUITY,
  VISUAL_ACUITY_OPTIONS_STR,
  VISUAL_ACUITY_TRANSLATION_STR,
} from '../../../constants/medicalRecords';
import FadeIn from 'react-fade-in';
import { displayDateFormat, getValuesGenerateKeys } from '../../../utils/helpers';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { dynamicWords } from '../../../translation/dynamicWords';

const PREFIX = 'HealthDataTable';
const classes = {
  table: `${PREFIX}-table`,
  tableContainer: `${PREFIX}-tableContainer`,
  mainTableHead: `${PREFIX}-mainTableHead`,
  extraTableHead: `${PREFIX}-extraTableHead`,
  background: `${PREFIX}-background`,
  rightEye: `${PREFIX}-rightEye`,
  leftEye: `${PREFIX}-leftEye`,
  recordDate: `${PREFIX}-recordDate`,
  type: `${PREFIX}-type`,
};

const StyledFadeIn = styled(FadeIn, {
  shouldForwardProp: (prop) => prop !== 'tableHeight',
})(({ theme, tableHeight }) => ({
  [`& .${classes.table}`]: {
    minWidth: 300,
  },

  [`& .${classes.tableContainer}`]: {
    maxHeight: tableHeight,
  },

  [`& .${classes.mainTableHead}`]: {
    zIndex: '5',
  },

  [`& .${classes.extraTableHead}`]: {
    backgroundColor: theme.palette.background.default,
    position: 'sticky',
    top: '0',
    zIndex: '10',
    fontSize: '11pt',
  },

  [`& .${classes.background}`]: {
    backgroundColor: theme.palette.background.default,
  },
  [`& .${classes.rightEye}`]: {
    width: '20%',
    paddingRight: 0,
  },
  [`& .${classes.leftEye}`]: {
    width: '20%',
    paddingRight: 0,
  },
  [`& .${classes.recordDate}`]: {
    width: '20%',
    paddingRight: 0,
    paddingLeft: '5px',
  },
  [`& .${classes.type}`]: {
    paddingRight: 0,
  },
}));

const HealthDataTable = (props) => {
  const { healthData, extraHeaderHeight, tableHeight } = props;

  const staticWords = useSelector((state) => state.handlingTranslation.words);
  const { t, i18n } = useTranslation();
  const translateText = (text) => {
    // Check if the input is a number or an object
    // If so, return it directly without processing
    if (typeof text === 'number' || typeof text === 'object') return text;
    // Split the string by commas, trim each word, translate, and join back with commas
    return text
      .split(',')
      .map((word) => {
        const trimmedWord = word.trim();
        const key = getKeyFromValue(trimmedWord); // Get the translation key for the trimmed word
        return t(key); // Translate the key using the translation function
      })
      .join(', '); // Join the translated words back into a single comma-separated string
  };
  const getKeyFromValue = (value) => {
    // Check in the English object first
    const enKey = Object.keys(dynamicWords.en).find((key) => dynamicWords.en[key] === value);
    // If the value is not found in the English object, check in the German object
    if (enKey) {
      return enKey;
    }
    const deKey = Object.keys(dynamicWords.de).find((key) => dynamicWords.de[key] === value);
    // Return the German key if found, else return the value itself
    return deKey || value;
  };

  return (
    <StyledFadeIn tableHeight={tableHeight}>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table className={classes.table} stickyHeader aria-label="health data table">
          <TableHead>
            <TableRow>
              <TableCell className={(classes.mainTableHead, classes.type)}>{staticWords.Type}</TableCell>
              <TableCell align="center" className={classes.rightEye}>
                {staticWords.Right_Eye}
              </TableCell>
              <TableCell align="center" className={classes.leftEye}>
                {staticWords.Left_Eye}
              </TableCell>
              <TableCell align="center" className={(classes.mainTableHead, classes.recordDate)}>
                {staticWords.Recorded_Date}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {healthData.map((row, i) => {
              if (parseInt(row[0]) === VISUAL_ACUITY) return null; // ignore deprecated VISUAL_ACUITY
              const healthDataType = mapHealthDataTypeNumToStr(row[0]);
              const recordedDate = row[1].recorded_date && row[1].recorded_date.slice(0, 10);
              const hasLeftValues = row[1].values?.Left != undefined && row[1].values?.Right != undefined;
              const renderValues = () => {
                if (hasLeftValues) {
                  const rightValue = row[1].values?.Right ?? '';
                  const leftValue = row[1].values?.Left ?? '';
                  return (
                    <>
                      <TableCell align="center">
                        {row[0] === '9'
                          ? rightValue === 0
                            ? '-'
                            : rightValue
                          : rightValue === '' || rightValue === '-'
                            ? '-'
                            : t(VISUAL_ACUITY_TRANSLATION_STR[rightValue])}
                      </TableCell>
                      <TableCell align="center">
                        {row[0] === '9'
                          ? leftValue === 0
                            ? '-'
                            : leftValue
                          : leftValue === '' || leftValue === '-'
                            ? '-'
                            : t(VISUAL_ACUITY_TRANSLATION_STR[leftValue])}
                      </TableCell>
                    </>
                  );
                } else {
                  return (
                    <TableCell align="center" colSpan={2}>
                      {/* {translateText(row[1].values)} */}
                      {row[0] === '4'
                        ? row[1].values === '-' || row[1].values === 0
                          ? '-'
                          : row[1].values.map((x) => `${t(DIABETES_THERAPY_TYPE_STR[x])}`).join(', ')
                        : row[1].values === 0 || row[1].values === '0/0'
                          ? '-'
                          : row[1].values}
                    </TableCell>
                  );
                }
              };
              const renderTableSubheader = () => {
                if (parseInt(row[0]) === IOP) {
                  return (
                    <>
                      <TableRow style={{ height: extraHeaderHeight + 'px' }}>
                        <TableCell className={classes.background}></TableCell>
                        <TableCell align="center" className={classes.extraTableHead} colSpan={2}>
                          {staticWords.Data}
                        </TableCell>
                        <TableCell className={classes.background}></TableCell>
                      </TableRow>
                    </>
                  );
                }
                return null;
              };

              return (
                <React.Fragment key={healthDataType + i}>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {staticWords[getValuesGenerateKeys(healthDataType)]}
                    </TableCell>
                    {renderValues()}
                    {/* for ticket https://telemedc-au.atlassian.net/browse/TEL-1412 date format changed */}
                    <TableCell align="center">{displayDateFormat(recordedDate)}</TableCell>
                  </TableRow>
                  {renderTableSubheader()}
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </StyledFadeIn>
  );
};

HealthDataTable.propTypes = {
  healthData: PropTypes.array.isRequired,
};

export default HealthDataTable;
