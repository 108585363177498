import { trackPromise } from 'react-promise-tracker';
import { t } from 'i18next';
import axiosInstance from '../axios';

import {
  MOST_RECENT_HEALTH_DATA,
  UPDATE_HEALTH_DATA,
  SET_SNACKBAR_ERROR,
  SET_SNACKBAR_SUCCESS,
  GET_PATIENT_MED_HISTORY,
  UPDATE_PATIENT_MED_HISTORY,
} from './types';
import { mapHealthDataTypeNumToVarName, mapHealthDataTypeNumToStr } from '../utils/conversions';
import { transferValueGetKey } from '../utils/helpers';
import {
  HBA1C,
  ALBUMINURIA,
  POSTPRANDIAL_BLOOD_GLUCOSE,
  TRIGLYCERIDE,
  LOW_DENSITY_LIPOPROTEIN,
  DIABETES_TYPE,
} from '../constants/medicalRecords';
import { MEDICAL_HISTORY_KEYS } from '../constants/constants';

export const getMostRecentHealthData = (patient_id) => (dispatch) => {
  return trackPromise(
    axiosInstance
      .get(`medical_records/list/latest/${patient_id}/`)
      .then((result) => {
        let healthData = result.data;
        let medHistory = {};
        if (typeof healthData == 'object' && healthData !== null && Object.keys(healthData).length < 50) {
          let healthDataEntries = Object.entries(healthData);

          for (const [key, values] of healthDataEntries) {
            if (MEDICAL_HISTORY_KEYS.has(parseInt(key))) {
              medHistory[key] = values;
              delete healthData[key];
            }
          }
          dispatch({
            type: MOST_RECENT_HEALTH_DATA,
            payload: { ...healthData },
          });
          dispatch({
            type: GET_PATIENT_MED_HISTORY,
            payload: { ...medHistory },
          });
        } else {
          throw new Error('Invalid health data format');
        }
      })
      .catch((error) => {
        dispatch({
          type: SET_SNACKBAR_ERROR,
          payload:
            error.response?.data?.errors?.[0]?.message ??
            t('There_was_an_error_retrieving_health_data__Please_refresh_the_page_to_try_again_'),
        });
      }),
    'health-data-area'
  );
};

// helper function to format health data
const formatHealthData = (health_data) => {
  const copiedHealthData = { ...health_data };
  const dataType = copiedHealthData.healthDataType;
  let newHealthData = {};
  const varName = mapHealthDataTypeNumToVarName(copiedHealthData.healthDataType);
  newHealthData[`${varName}_recorded_date`] = copiedHealthData.recorded_date;

  switch (dataType) {
    case HBA1C:
    case ALBUMINURIA:
    case POSTPRANDIAL_BLOOD_GLUCOSE:
    case TRIGLYCERIDE:
    case LOW_DENSITY_LIPOPROTEIN:
      newHealthData[varName] = copiedHealthData.value;
      break;
    default:
      delete copiedHealthData['healthDataType'];
      delete copiedHealthData['recorded_date'];
      newHealthData = Object.assign(newHealthData, copiedHealthData);
      break;
  }
  return newHealthData;
};

export const addNewHealthData = (patient_id, health_data) => (dispatch) => {
  const newHealthData = formatHealthData(health_data);

  axiosInstance
    .post(`medical_records/create/${patient_id}/`, newHealthData)
    .then((result) => {
      dispatch({
        type: UPDATE_HEALTH_DATA,
        payload: 'success',
      });

      dispatch({
        type: SET_SNACKBAR_SUCCESS,
        payload: `${t('Successfully_added_new_data_of')} ${t(mapHealthDataTypeNumToStr(health_data.healthDataType))}.`,
      });
    })
    .catch((error) => {
      dispatch({
        type: SET_SNACKBAR_ERROR,
        payload:
          error.response?.data?.errors?.[0]?.message ??
          t('There_is_a_problem_when_adding_new_health_data__Please_try_again_later_'),
      });
    });
};

export const updatePatientMedicalHistory = (patient_id, medicalHistory) => async (dispatch) => {
  try {
    const result = await axiosInstance.post(`medical_records/create/${patient_id}/`, medicalHistory);
    dispatch({
      type: UPDATE_PATIENT_MED_HISTORY,
      payload: 'success',
    });
    dispatch({
      type: SET_SNACKBAR_SUCCESS,
      payload: t('Successfully_updated_medical_history_'),
    });
  } catch (error) {
    dispatch({
      type: SET_SNACKBAR_ERROR,
      payload:
        error.response?.data?.errors?.[0]?.message ??
        t('There_is_a_problem_when_updating_medical_history__Please_try_again_later_'),
    });
  }
};
