import { createReducer } from '@reduxjs/toolkit';
import { GET_EMAIL_PREFERENCES, UPDATE_EMAIL_PREFERENCES } from '../actions/types';

const initialState = {
  preferences: {
    email: {},
  },
};

const accountReducer = createReducer(initialState, (builder) => {
  const updateEmailPreferences = (state, action) => {
    if (action.payload) {
      state.preferences.email = { ...state.preferences.email, ...action.payload };
    }
  };

  builder.addCase(GET_EMAIL_PREFERENCES, updateEmailPreferences);
  builder.addCase(UPDATE_EMAIL_PREFERENCES, updateEmailPreferences);
});

export default accountReducer;
