import i18n, { t } from 'i18next';

import axiosInstance from '../axios';

import { ROLES_STR, SUPPORTED_LANGUAGES, TELEEYE_MD_WEB } from '../constants/constants';
import {
  AUTH_ERROR,
  CHECK_OTP_USAGE,
  INVITATION_CHECK,
  INVITATION_RESPONSE,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  SET_CREDENTIALS,
  SET_SNACKBAR_ERROR,
  SET_SNACKBAR_INFO,
  SET_SNACKBAR_SUCCESS,
  SIGNUP_SUCCESS,
  TERMS_RECEIVED,
  UNAGREED_TERMS_RECEIVED,
  USER_LOADED,
  USER_LOADING,
} from './types';
import { VALIDATION_ERROR_CODES } from '../constants/validations';
import { checkCookie, transferValueGetKey } from '../utils/helpers';
import { Cookie } from '@mui/icons-material';
import Cookies from 'js-cookie';
import { postLanguageCode } from './handlingTranslation';

// CHECK TOKEN & LOAD USER
export const loadUser = () => async (dispatch) => {
  dispatch({ type: USER_LOADING });
  try {
    let result = await axiosInstance.get('/users/accounts/login/status/');

    if (result.data.login_status) {
      result = await axiosInstance.get('/users/');

      dispatch({
        type: USER_LOADED,
        payload: result.data,
      });
    } else {
      dispatch({
        type: AUTH_ERROR,
      });
    }
  } catch {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// LOGIN USER
export const login = (credentials) => async (dispatch) => {
  const { username, password, otp } = credentials;
  const loginData = JSON.stringify({ username: username, password: password, otp: otp });
  const loginUrl = otp ? '/login/verify-otp/' : '/login/';
  try {
    if (!checkCookie('csrftoken')) {
      await axiosInstance.get('/get_csrf/');
    }

    // login functionality
    await axiosInstance.post(loginUrl, loginData);

    try {
      // need token for updating the language
      // if the language is changes it will be updated by the login function
      // get current language from the il8n and update it to the BE
      const preferred_language = i18n.resolvedLanguage;
      await axiosInstance
        .post('locale/set_language', {
          language_code: preferred_language,
        })
        .then((result) => {
          dispatch({
            type: 'langCode',
            payload: result.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: SET_SNACKBAR_ERROR,
            payload: t('Unable_to_set_Django_language'),
          });
        });
      if (SUPPORTED_LANGUAGES.includes(preferred_language) && preferred_language !== i18n.resolvedLanguage) {
        await i18n.changeLanguage(preferred_language);
        dispatch({ type: preferred_language });
      }
    } catch (error) {
      console.error(t('Unable_to_fetch_user_preferred_language_'), error);
    }

    // this functionality is used when multiple user use the same account or user using the account in different platform then getting the language from BE is Required
    // try {
    //   const {
    //     data: { preferred_language },
    //   } = await axiosInstance.get('locale/set_language');

    //   if (SUPPORTED_LANGUAGES.includes(preferred_language) && preferred_language !== i18n.resolvedLanguage) {
    //     await i18n.changeLanguage(preferred_language);
    //     dispatch({ type: preferred_language });
    //   }
    // } catch (error) {
    //   console.error('Unable to fetch user preferred language:', error);
    // }

    const result = await axiosInstance.get('/users/');

    dispatch({
      type: LOGIN_SUCCESS,
      payload: result.data,
    });
    dispatch({
      type: SET_SNACKBAR_SUCCESS,
      payload: t('Successfully_logged_in_'),
    });
    return result.data;
  } catch (error) {
    dispatch({
      type: LOGIN_FAIL,
    });
    dispatch({
      type: SET_CREDENTIALS,
      payload: credentials,
    });
    const message =
      error.response?.data?.errors?.[0]?.message ?? t('There_was_a_problem_logging_in__Please_try_again_later_');
    const code = error.response?.data?.errors?.[0]?.code ?? null;
    const status = error.response?.status;
    if (
      [
        VALIDATION_ERROR_CODES.EMAIL_NOT_VERIFIED,
        VALIDATION_ERROR_CODES.PHONE_NUMBER_NOT_VERIFIED,
        VALIDATION_ERROR_CODES.MFA_REQUIRED,
        VALIDATION_ERROR_CODES.INVALID_OTP,
      ].includes(code)
    ) {
      return Promise.reject({ status, message, code });
    }
    dispatch({
      type: SET_SNACKBAR_ERROR,
      payload: message,
    });
    return Promise.reject({ status, message, code });
  }
};

// LOGOUT USER
export const logOut = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post('/logout/')
      .then((result) => {
        dispatch({
          type: LOGOUT_SUCCESS,
        });
        Cookies.remove('csrftoken');
        resolve();
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          dispatch({
            type: SET_SNACKBAR_ERROR,
            payload: t('The_session_is_already_ended_'),
          });
          window.location.replace('/login/');
        } else {
          dispatch({
            type: SET_SNACKBAR_ERROR,
            payload: t('Sorry_we_couldn_t_log_you_out_at_the_moment__Please_try_again_later_'),
          });
        }
        reject(error);
      });
  });
};
// USER
export const getUserDetails = async () => {
  return await axiosInstance
    .get('/users/')
    .then((response) => {
      const result = response.data;
      const allKeysExist =
        result.hasOwnProperty('cookie_preferences') &&
        result.cookie_preferences.hasOwnProperty('necessary') &&
        result.cookie_preferences.hasOwnProperty('all_accepted');
      if (
        allKeysExist &&
        Object.keys(result.cookie_preferences).every((cookie) => !result.cookie_preferences[cookie])
      ) {
        return true;
      }
      return false;
    })
    .catch((error) => {
      return false;
    });
};
// COOKIE PREFERENCES

export const cookiePreferences = (preferences) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post('/users/accounts/cookie-preferences/', preferences)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
// REGISTER USER
export const signUp = (user) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post('/users/accounts/signup/', {
        ...user,
        signup_application: TELEEYE_MD_WEB,
      })
      .then((result) => {
        dispatch({
          type: SIGNUP_SUCCESS,
          payload: result.data,
        });
        dispatch({
          type: SET_SNACKBAR_SUCCESS,
          payload: t('You_have_successfully_signed_up_'),
        });
        dispatch({
          type: SET_CREDENTIALS,
          payload: user,
        });
        resolve(result.data);
      })
      .catch((error) => {
        reject(error);
        dispatch({
          type: SET_SNACKBAR_ERROR,
          payload:
            error.response?.data?.errors?.[0]?.message ?? t('There_was_a_problem_signing_up__Please_try_again_later_'),
        });
      });
  });
};

export const checkAccountExists =
  (emailOrPhoneNumber, silent = true) =>
  (dispatch) => {
    return new Promise((resolve, reject) => {
      if (!emailOrPhoneNumber) return reject(t('No_email_or_phone_number_provided'));
      axiosInstance
        .get(`/users/accounts/check_exists/${emailOrPhoneNumber}/`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
          if (!silent) {
            dispatch({
              type: SET_SNACKBAR_ERROR,
              payload: t('There_was_a_problem_checking_the_account__Please_try_again_later_'),
            });
          }
        });
    });
  };

// ACTIVATE USER
export const activateUser =
  (uid, token, password1 = null, password2 = null, termsOfUseIds = null) =>
  (dispatch) => {
    return new Promise((resolve, reject) => {
      if (!password1)
        axiosInstance
          .get(`/users/accounts/activate/${uid}/${token}/`)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      else {
        axiosInstance
          .post(`/users/accounts/activate/set_password/${uid}/${token}/`, {
            password1,
            password2,
            terms_and_conditions_id: termsOfUseIds,
          })
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      }
    });
  };

// FORGOT PASSWORD
export const forgotPassword = (email) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(`/users/accounts/password/reset/`, {
        email,
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getPasswordResetLink = (username, otp, silent) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(`users/accounts/password/reset/get_link`, { username, otp })
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        const message = error.response?.data?.errors?.[0]?.message || t('Something_wrong__Please_try_later_');
        const code = error.response?.data?.errors?.[0]?.code || null;
        if (!(silent && code === VALIDATION_ERROR_CODES.INVALID_CREDENTIALS)) {
          dispatch({
            type: SET_SNACKBAR_ERROR,
            payload: message,
          });
        }
        reject({ message, code });
      });
  });
};

// FORGOT PASSWORD CONFIRM
export const forgotPasswordConfirm =
  (uid, token, password1 = null, password2 = null) =>
  (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(`/users/accounts/password/reset/${uid}/${token}/`, {
          password1,
          password2,
        })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

// CREATE USER
export const createUser = (user) => (dispatch) => {
  return new axiosInstance.post('/users/accounts/create/', {
    ...user,
    signup_application: TELEEYE_MD_WEB,
  })
    .then((result) => {
      dispatch({
        type: SET_SNACKBAR_SUCCESS,
        payload: t('Successfully_created_'),
      });
      return result.data;
    })
    .catch((error) => {
      const role = ROLES_STR[user.role];
      dispatch({
        type: SET_SNACKBAR_ERROR,
        payload:
          error.response?.data?.errors?.[0]?.message ??
          t('There was a problem creating a new {{role}}. Please try again.', { role }),
      });
      throw error;
    });
};

// TERMS OF USE LIST
export const listTermsOfUse = () => async (dispatch) => {
  try {
    const result = await axiosInstance.get('/users/terms/list/active/');

    dispatch({
      type: TERMS_RECEIVED,
      payload: result.data,
    });
  } catch (error) {
    dispatch({
      type: SET_SNACKBAR_ERROR,
      payload: t('There_was_a_problem_when_retrieving_Terms_and_Conditions_data__Please_refresh_to_try_again_'),
    });
  }
};

export const listUnagreedTermsOfUse = () => (dispatch) => {
  axiosInstance
    .get('/users/terms/latest_unagreed/')
    .then((result) => {
      dispatch({
        type: UNAGREED_TERMS_RECEIVED,
        payload: result.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: SET_SNACKBAR_ERROR,
        payload: t('There_was_a_problem_when_retrieving_Terms_and_Conditions_data__Please_refresh_to_try_again_'),
      });
    });
};

export const acceptTermsOfUse = (ids) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post('/users/terms/accept/', ids)
      .then((result) => {
        resolve(result.data);
        dispatch({
          type: SET_SNACKBAR_SUCCESS,
          payload: t('Thank_you_for_accepting_the_updated_terms_of_use_'),
        });
      })
      .catch((error) => {
        reject(error);
        dispatch({
          type: SET_SNACKBAR_ERROR,
          payload: t('There_was_a_problem_when_accepting_Terms_and_Conditions__Please_contact_us_if_it_persists_'),
        });
      });
  });
};

// INVITE USER VIA LINK
export const getInvitationLink = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get('/users/referrals/generate-invitation/')
      .then((result) => {
        resolve(result.data);
        dispatch({
          type: SET_SNACKBAR_SUCCESS,
          payload: t('Invitation_Link_Copied__Valid_for_24_hours_'),
        });
      })
      .catch((error) => {
        dispatch({
          type: SET_SNACKBAR_ERROR,
          payload:
            error.response?.data?.errors?.[0]?.message ??
            t('There_was_a_problem_getting_the_invite_link__Please_try_again_'),
        });
      });
  });
};

// INVITE USER
export const sendInvitation = (emails) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post('/users/referrals/email-invitation/', emails)
      .then((result) => {
        resolve(result.data);
        dispatch({
          type: SET_SNACKBAR_SUCCESS,
          payload: t('Successfully_Invited_'),
        });
      })
      .catch((error) => {
        dispatch({
          type: SET_SNACKBAR_ERROR,
          payload:
            error.response?.data?.errors?.[0]?.message ??
            t('There was a problem inviting {{emails}}. Please try again.', { emails }),
        });
      });
  });
};

// Check if it's coming from invitation link
export const checkForInvitation = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get('/users/referrals/check-for-invitation/')
      .then((result) => {
        resolve(result.data);
        dispatch({
          type: INVITATION_CHECK,
          payload: result.data,
        });
      })
      .catch((error) => {
        //reject(error)
        dispatch({
          type: SET_SNACKBAR_ERROR,
          payload:
            error.response?.data?.errors?.[0]?.message ??
            t('There_was_a_problem_retrieving_invitation_referrals__Please_try_again'),
        });
      });
  });
};

// takes body param 'type' with 'ACCEPT' and 'DECLINE' choices :both string
export const respondToInvitation = (invitationResponse, newGroup) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post('/users/referrals/respond-to-invitation/', invitationResponse)
      .then((result) => {
        resolve(result.data);
        dispatch({
          type: INVITATION_RESPONSE,
          payload: { response: invitationResponse, newGroup: newGroup },
        });
        if (invitationResponse.type === 'ACCEPT') {
          dispatch({
            type: SET_SNACKBAR_SUCCESS,
            payload: t('Accepted Invitation. Welcome to {{newGroup}}!', { newGroup }),
          });
        } else {
          dispatch({
            type: SET_SNACKBAR_INFO,
            payload: t('Invitation to join {{newGroup}} has been dismissed.', { newGroup }),
          });
        }
      })
      .catch((error) => {
        reject(error);
        dispatch({
          type: SET_SNACKBAR_ERROR,
          payload: t('There_was_a_problem_joining_this_organisation__please_contact_your_organisation_admin_'),
        });
      });
  });
};

export const verifyEmailOrMobile = (emailOrMobileNumber, token, silent) => (dispatch) => {
  let type = emailOrMobileNumber?.includes('@') ? 'email' : 'phone_number';
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(`/users/accounts/verify/${type}/${emailOrMobileNumber}/${token}`)
      .then((result) => {
        resolve(result.data);
        if (!silent) {
          dispatch({
            type: SET_SNACKBAR_SUCCESS,
            payload: t('Email_Verified_'),
          });
        }
      })
      .catch((error) => {
        const message =
          error.response?.data?.errors?.[0]?.message ||
          (type == 'email'
            ? t('There_was_a_problem_verifying_your_email__Please_try_again_later_')
            : t('There_was_a_problem_verifying_your_mobile_number__Please_try_again_later_'));
        const code = error.response?.data?.errors?.[0]?.code || null;
        if (!silent) {
          dispatch({
            type: SET_SNACKBAR_ERROR,
            payload: message,
          });
        }
        reject({ message, code });
      });
  });
};

export const checkOTPUsage = (emailOrMobileNumber, silent) => (dispatch) => {
  let type = emailOrMobileNumber?.includes('@') ? 'email' : 'phone_number';
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(`/otp/check_usage`, { [type]: emailOrMobileNumber })
      .then((result) => {
        resolve(result.data);
        dispatch({
          type: CHECK_OTP_USAGE,
          payload: result.data,
        });
      })
      .catch((error) => {
        const message =
          error.response?.data?.errors?.[0]?.message || t('There_was_a_problem_checking_OTP_usage__Please_try_later_');
        const code = error.response?.data?.errors?.[0]?.code || null;
        if (!silent) {
          dispatch({
            type: SET_SNACKBAR_ERROR,
            payload: message,
          });
        }
        reject({ message, code });
      });
  });
};

export const sendOTP = (emailOrMobileNumber, purpose, silent) => async (dispatch) => {
  let type = emailOrMobileNumber?.includes('@') ? 'email' : 'phone_number';
  let data = { purpose: purpose };
  type === 'email' ? (data['email'] = emailOrMobileNumber) : (data['phone_number'] = emailOrMobileNumber);
  return new Promise((resolve, reject) => {
    if (!emailOrMobileNumber)
      reject({
        message: t('Email_or_Mobile_Number_is_required'),
      });
    axiosInstance
      .post(`/otp/${type === 'email' ? 'email' : 'sms'}`, data)
      .then((result) => {
        resolve(result.data);
        if (!silent) {
          dispatch({
            type: SET_SNACKBAR_SUCCESS,
            payload: t('OTP sent to {{emailOrMobileNumber}}', { emailOrMobileNumber }),
          });
        }
      })
      .catch((error) => {
        const message =
          error.response?.data?.errors?.[0]?.message || t('There_was_a_problem_sending_OTP__Please_try_later_');
        const code = error.response?.data?.errors?.[0]?.code || null;
        // Display error alert if not silent or if error code is 117
        if (!silent) {
          dispatch({
            type: SET_SNACKBAR_ERROR,
            payload: message,
          });
        }
        reject({ message, code });
      });
  });
};
