import {
  LIST_USERS,
  LIST_ORGANISATIONS,
  USER_APPROVED,
  ORGANISATION_ADDED,
  GET_GROUP_PROFILE,
  GET_GROUP_LOGO,
} from '../actions/types.js';

const initialState = {
  users: [],
  organisations: [],
  groupProfile: {},
  groupLogo: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LIST_USERS:
      return {
        ...state,
        users: action.payload,
      };

    case LIST_ORGANISATIONS:
      return {
        ...state,
        organisations: action.payload,
      };

    case USER_APPROVED:
      const newUsers = [...state.users];
      // remove approved user in list
      const userToDeleteIndex = newUsers.findIndex((user) => user.id === action.payload);
      if (userToDeleteIndex > -1) {
        newUsers.splice(userToDeleteIndex, 1);
      }
      return {
        ...state,
        users: newUsers,
      };

    case ORGANISATION_ADDED:
      return {
        ...state,
        organisations: [...state.organisations, action.payload],
      };
    case GET_GROUP_PROFILE: 
      return {
        ...state,
        groupProfile: {
          ...state.groupProfile,
          ...action.payload,
        }
      }
    case GET_GROUP_LOGO: {
      return {
        ...state,
        groupLogo: action.payload
      }
    }
    default:
      return state;
  }
}
