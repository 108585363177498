import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import FadeIn from 'react-fade-in';
import { connect, useSelector } from 'react-redux';

import { Grid } from '@mui/material';

import Card from '../../../components/layout/atoms/Card';
import ProfileDetails from '../../accounts/profiles/Profile/ProfileDetails';
import PatientExams from './PatientExams';
import PatientRiskServices from './PatientRiskServices';
import MedicalHistory from '../../healthData/MedicalHistory';
import HealthData from '../../healthData/HealthData/HealthData';

import { getProfileLayoutByRegion } from '../../../utils/configurationHelpers';
import { handlePatientLookUpOpen, handlePatientLookUpClose } from '../../../actions/controls';
import { getPatientDetails, getPatientExams, getPatientRiskServices } from '../../../actions/patients';
import { getMostRecentHealthData } from '../../../actions/healthData';

const PatientDetails = (props) => {
  const { patientDetails, staticWords } = props;

  const { cardHeight } = getProfileLayoutByRegion();

  return (
    <Grid container spacing={2} mt={1} sx={{ overflow: 'hidden' }}>
      <Grid item xs={12}>
        <PatientRiskServices />
      </Grid>
      <Grid container item xs={12} spacing={2}>
        <Grid container item gap={2} xs={12} lg={5.5}>
          <Grid item xs={12}>
            <FadeIn>
              <Card size="fill" sx={{ height: cardHeight, padding: (theme) => theme.spacing(3) }}>
                <ProfileDetails title={'Patient Details'} details={patientDetails} />
              </Card>
            </FadeIn>
          </Grid>
          <Grid item xs={12}>
            <FadeIn>
              <Card size="fill" sx={{ height: cardHeight, padding: (theme) => theme.spacing(3) }}>
                <MedicalHistory saveBtn={staticWords.Save} cancelBtn={staticWords.Cancel} customHeight={420} />
              </Card>
            </FadeIn>
          </Grid>
        </Grid>

        <Grid container item gap={2} xs={12} lg={6.5}>
          <Grid item xs={12}>
            <FadeIn>
              <Card size="fill" sx={{ height: cardHeight, paddingY: (theme) => theme.spacing(3) }}>
                <PatientExams />
              </Card>
            </FadeIn>
          </Grid>
          <Grid item xs={12}>
            <FadeIn>
              <Card size="fill" sx={{ height: cardHeight, padding: (theme) => theme.spacing(3) }}>
                <HealthData />
              </Card>
            </FadeIn>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

// FUNCTIONAL COMPONENT
const Patient = (props) => {
  const {
    patientDetails,
    getPatientExams,
    getPatientRiskServices,
    getPatientDetails,
    getMostRecentHealthData,
    healthDataUpdated,
  } = props;

  const staticWords = useSelector((state) => state.handlingTranslation.words);
  let { account_number } = props.match.params;

  // DO GET PATIENT BASED ON HYPERLINK
  useEffect(() => {
    if (!patientDetails || account_number !== patientDetails.account_number) {
      getPatientDetails(account_number).then((result) => {
        getPatientExams(result.id);
        getMostRecentHealthData(result.id);
      });
    }
    //route back from PatientRiskAnalysis has patientDetails but missing exams
    else if ((patientDetails && !patientDetails.exams) || patientDetails) {
      getPatientDetails(account_number).then((result) => {
        getPatientExams(result.id);
        getMostRecentHealthData(result.id);
      });
    }
  }, [healthDataUpdated]);

  return (
    <div>
      {patientDetails === null || account_number !== patientDetails.account_number ? null : (
        <PatientDetails patientDetails={patientDetails} staticWords={staticWords} />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  patientDetails: state.patients.patientDetails,
  healthDataUpdated: state.healthData.healthDataUpdated,
});

export default connect(mapStateToProps, {
  handlePatientLookUpOpen,
  handlePatientLookUpClose,
  getPatientDetails,
  getPatientExams,
  getPatientRiskServices,
  getMostRecentHealthData,
})(withRouter(Patient));
