import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

import Loading from '../layout/organisms/Loading/Loading';

const PrivateRoute = ({ component: Component, auth, ...rest }) => {
  const queryParams = new URLSearchParams(window.location.search);

  const setRedirectUrl = () => {
    queryParams.append('redirect', window.location.href); // redirect url that users accessed before auth
    const nextUrl = queryParams.toString();
    return '/login/' + (nextUrl ? `?${nextUrl}` : '');
  };

  return (
    <Route
      {...rest}
      render={(props) => {
        if (auth.isLoading) {
          return <Loading isFullScreen />;
        } else if (!auth.isAuthenticated || !auth.user) {
          if (!auth.logoutSuccess) {
            const loginUrl = setRedirectUrl();
            return <Redirect to={loginUrl} />;
          } else {
            return <Redirect to="/login/" />;
          }
        } else {
          return <Component {...props} />;
        }
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(PrivateRoute);
